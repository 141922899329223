<template>
    <div>
        <div class="bg-overlay light-bg"></div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="card border" style="border: 5px solid #f1f5f7; !important">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="text-center">
                                        <img src="images/forgot-password.png" style="width:30%"/>
                                    </div>
                                    <h4 class="font-size-18 text-muted mt-3 text-center">Lupa Password</h4>
                                    <p class="mb-2 text-center">Kami akan mengirimkan email konfirmasi untuk mengubah password kamu</p>
                                    <form class="form-horizontal" v-on:submit.prevent="onSubmit">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-2">
                                                    <label class="col-form-label col-form-label-thick">Email </label>
                                                    <div class="input-icon input-icon-left input-icon-left-form-naked">
                                                        <i class="ph-user-bold"></i>
                                                        <input autocomplete="email" type="text"
                                                            class="form-control form-control-naked fcn-w-bb w-100 d-block"
                                                            :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username" placeholder="Masukan Email...">
                                                            <div v-if="submitted && !$v.username.required" class="invalid-feedback">Email wajib diisi!</div>    
                                                    </div>
                                                </div>
                                                <div class="d-grid mt-3">
                                                    <button :disabled="waiting" type="submit" class="btn btn-primary waves-effect waves-light">
                                                        {{ waiting ? 'Sedang mengirim ...' : 'Kirim Link Lupa Password' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="alert" class="alert alert-info text-center mt-2"> 
                                            Harap masukan alamat email yang <b>sudah terdaftar</b>
                                        </div>                                        

                                        <div class="row text-center mt-3">
                                            <div class="col font-weight-bold">
                                                <router-link :to="{name : 'signin'}">Kembali ke halaman login</router-link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'ForgetPassword',
        metaInfo: {
            title: 'Lupa Password',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                username: "",
                password: "",
                submitted: false,
                waiting: false,
            }
        },
        mounted () {
            document.body.classList.add('bg-pattern')
        },
        validations: {
            username: {
                required
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'auth/forgot-password', {
                            username: this.username
                        })
                        .then(response => {
                            this.waiting = false;

                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    html: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'signin' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            }
        }
    }
</script>