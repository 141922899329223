<template>
    <div class="page-content">
        <div class="container-fluid">
            <div v-if="dataFetch" class="row">
                <div class="col-xl-7 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-5 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch" class="row">
                <div class="col-xl-7">
                    <div class="card border">
                        <object type="application/pdf" :data="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+dataDetail.attachment" width="100%" height="500" style="height: 85vh;">No Support</object>

                        <div class="card-body">
                            Feedback :
                            <div v-html="dataDetail.feedback"></div>
                        </div>
                    </div>
                </div> 

                <div class="col-xl-5">
                    <div class="card border">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Tanggal Mengirim</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.text_upload}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-0">
                                <div class="mr-3">Status</div>
                                <div class="font-weight-600 text-right">
                                    <span class="badge bg-warning" v-if="dataDetail.status == 'rejected'">Direvisi</span>
                                    <span class="badge bg-success" v-if="dataDetail.status == 'accepted'">Dikonfirmasi</span>
                                    <span class="badge bg-info" v-if="dataDetail.status == 'waiting'">Menunggu Konfirmasi</span>
                                </div>
                            </div>

                            <div class="d-grid mt-3">
                                <button class="btn btn-outline-primary btn-block" type="button" v-on:click="goBack()">Kembali</button>
                            </div>
                        </div>
                    </div>
                </div> <!-- end col -->
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'MemberUsersPapersHistoryDetail',
        metaInfo: {
            title: 'Detail Riwayat Pengumpulan File',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getData();
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                dataFetch       : true,
                dataDetail      : [],
                code            : this.$route.params.code,
                id              : this.$route.params.id,
                detail_id       : this.$route.params.detail_id
            }
        },
        methods: {
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'users/papers/history/detail';

                this.dataFetch  = true;
                this.$http.get(uri,{
                    params : {
                        code        : this.code,
                        id          : this.id,
                        detail_id   : this.detail_id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse         = res.data.data;
                        this.dataDetail         = getResponse
                        this.dataFetch          = false;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_lkti_package_history',
                    params : {
                        code    : this.code,
                        id      : this.id
                    }
                });
            }
        }
    }
</script>