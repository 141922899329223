<template>
    <div>
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div v-if="!emptyState" class="row">
                    <div class="col-md-8 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Keranjang ({{dataDetail.list.length}})</h4>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="table-light">
                                                <th>Nama Item</th>
                                                <th class="text-right" style="width: 15%;">Harga</th>
                                                <th class="text-center" style="width: 5%;">Hapus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataDetail.list" :key="index">
                                                <td>
                                                    <h6>{{item.title}}</h6>
                                                    <p class="m-0">Program : {{item.type}}</p>
                                                </td>
                                                <td class="text-right">
                                                    <s v-if="item.price_out" class="text-muted">{{item.text_price_out}}<br></s>
                                                    <b>{{item.text_price}}</b>
                                                </td>
                                                <td class="text-center"><button v-on:click="deleteCart(item.entity_id, item.type)" type="button" class="btn btn-sm btn-danger rounded btn-block"><i class="mdi mdi-trash-can-outline"></i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Informasi</h4>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                    <div class="mr-3 font-weight-600">Sub total</div>
                                    <div class="font-weight-bold text-right">
                                        {{dataDetail.info.text_sub_total}}
                                    </div>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mt-3">
                                    <div class="mr-3 font-weight-600">
                                        Voucher
                                    </div>
                                    <a v-if="!dataDetail.voucher.code" href="javascript:void(0)" class="font-weight-bold text-right" v-on:click="addVoucher">
                                        <small>Masukan kode untuk mendapatkan potongan harga</small>
                                    </a>
                                    <div v-if="dataDetail.voucher.code" class="font-weight-bold text-right">
                                        <span :class="dataDetail.voucher.status ? 'text-blue':'text-muted'">{{dataDetail.voucher.code}}</span><br/>
                                        <a href="javascript:void(0)" class="text-primary" v-on:click="addVoucher"><small>Ganti Kode</small><br/></a>
                                        <a href="javascript:void(0)" class="text-danger" v-on:click="deleteVoucher"><small>Hapus Kode</small></a>
                                    </div>
                                </div>

                                <div v-if="dataDetail.info.discount" class="d-flex align-items-center justify-content-between pb-2 border-bottom mt-3">
                                    <div class="mr-3 font-weight-600">Potongan harga</div>
                                    <div class="font-weight-bold text-right text-primary">
                                        {{dataDetail.info.text_discount}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                    <div class="mr-3 font-weight-600">Total keseluruhan</div>
                                    <div class="font-weight-bold text-right">
                                        <s class="text-muted" v-if="dataDetail.info.discount">{{dataDetail.info.text_sub_total}}<br/></s>
                                        <span :class="dataDetail.info.discount ? 'text-primary':''">{{dataDetail.info.text_total_overall_price}}</span>
                                    </div>
                                </div>
                                <hr/>

                                <div v-if="dataDetail.voucher.show">
                                    <div v-if="dataDetail.voucher.status" role="alert" class="alert alert-success text-center mt-3"> Selamat! kamu mendapatkan<br/>potongan harga sebesar <b>{{dataDetail.info.text_discount}}</b> dengan menggunakan kode : <b>{{dataDetail.voucher.code}}</b></div>
                                    <div v-if="!dataDetail.voucher.status" role="alert" class="alert alert-danger text-center mt-3">
                                        {{dataDetail.voucher.message}}
                                    </div>
                                </div>

                                <div class="d-grid">
                                    <button :disabled="disabledSubmit" v-on:click="checkOut" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-1" ><i class="mdi mdi-cart-check"></i> 
                                    {{fetchSubmit ? 'Sedang memproses...':'Proses pembayaran'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="emptyState" class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Keranjang</h4>
                                </div>

                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberCartList',
        metaInfo: {
            title: 'Data Keranjang',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : true,
                dataDetail  : [],
                voucher     : '',
                disabledSubmit   : false,
                fetchSubmit      : false,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/cart/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        voucher : this.voucher,
                    }
                }).then(res => {
                    this.dataFetch  = false;
                    if(res.data.status) {
                        this.dataDetail = res.data.data;

                        if(this.dataDetail.voucher.status) {
                            this.disabledSubmit = false;
                        } else {
                            this.disabledSubmit = true;
                        }

                        if(this.dataDetail.voucher.show) {
                            this.$swal({
                                icon    : this.dataDetail.voucher.icon,
                                title   : this.dataDetail.voucher.title,
                                html    : this.dataDetail.voucher.message,
                            });
                        }
                    } else {
                        this.emptyState = true;
                    }
                });
            },
            deleteCart: function(id="", type="") {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus dari keranjang',
                    text: 'Apakah kamu yakin ingin menghapus item ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.fetchCart  = true;
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/tryout/delete-cart', {
                        id: id,
                        type: type
                    })
                    .then(response => {
                        this.fetchCart  = false;
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })}
                });
            },
            addVoucher: function() {
                this.$swal({
                    icon: 'info',
                    input : 'text',
                    title: 'Masukan kode voucher',
                    confirmButtonText: 'Konfirmasi',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.voucher = result.value;
                    this.getData();
                }
                })
            },
            deleteVoucher: function() {
                this.voucher = '';
                this.getData();
            },
            checkOut: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Pembayaran',
                    text: 'Apakah kamu yakin ingin melakukan proses pembayaran?',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.fetchSubmit    = true;
                    this.disabledSubmit = true;
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/cart/checkout', {
                        voucher: this.voucher
                    })
                    .then(response => {
                        this.fetchSubmit  = false;
                        this.disabledSubmit  = false;
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'member_invoice_detail' , params: {
                                        invoice : response.data.data.invoice
                                    }});
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })}
                });
            }
        }
    }
</script>