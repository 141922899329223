<template>
    <div class="start-quiz">
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Preview Soal - {{this.dataTryout.tryout.title}}</h4>
                        </div>
                    </div>
                </div>

                <div v-if="dataFetch" class="row">
                    <div class="col-xl-7 card">                
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar">
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-4"></div>

                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>

                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-5 card">                
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar">
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-4"></div>

                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>

                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch" class="row">
                    {{dataFetchQuestions}}
                    <div class="col-xl-8 nocopy">
                        <div class="card border">
                            <div v-if="form.hots" class="ribbon ribbon-top-right"><span>Soal Hots</span></div>
                            <div class="card-body">
                                <h4 class="card-title mb-3">Soal Nomor #{{number_questions}} - {{form.name}}</h4>
                                <hr/>
                                <div v-html="form.question"></div>

                                <div v-if="form.options_a"
                                    class="d-flex mt-4 mb-2"
                                    :class="form.answer == 'a' ? 'font-weight-bold text-success': ''">
                                    <span class="m-r-10">A.</span>
                                    <span v-html="form.options_a"></span>
                                </div>

                                <div v-if="form.options_b"
                                    class="d-flex mb-2"
                                    :class="form.answer == 'b' ? 'font-weight-bold text-success': ''">
                                    <span class="m-r-10">B.</span>
                                    <span v-html="form.options_b"></span>
                                </div>

                                <div v-if="form.options_c"
                                    class="d-flex mb-2"
                                    :class="form.answer == 'c' ? 'font-weight-bold text-success': ''">
                                    <span class="m-r-10">C.</span>
                                    <span v-html="form.options_c"></span>
                                </div>

                                <div v-if="form.options_d"
                                    class="d-flex mb-2"
                                    :class="form.answer == 'd' ? 'font-weight-bold text-success': ''">
                                    <span class="m-r-10">D.</span>
                                    <span v-html="form.options_d"></span>
                                </div>

                                <div v-if="form.options_e"
                                    class="d-flex mb-2"
                                    :class="form.answer == 'e' ? 'font-weight-bold text-success': ''">
                                    <span class="m-r-10">D.</span>
                                    <span v-html="form.options_e"></span>
                                </div>

                                <div v-if="form.answer" class="d-flex align-items-center justify-content-between pb-2 mt-4 border-bottom">
                                    <div class="mr-3 font-weight-bold">Kunci Jawaban</div>
                                    <div class="font-weight-600">
                                        {{form.answer.toUpperCase()}}
                                    </div>
                                </div>

                                <div v-if="form.discussion">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                        <div class="mr-3 font-weight-bold">Pembahasan</div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between pb-2 mt-1 border-bottom">
                                        <div class="mr-3 font-weight-300" v-html="form.discussion"></div>
                                    </div>
                                </div>

                                <div v-if="form.discussion_video">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                        <div class="mr-3 font-weight-bold">Video Pembahasan</div>
                                    </div>
                                    <vue-plyr>
                                        <div class="plyr__video-embed">
                                            <iframe
                                            :src="form.discussion_video"
                                            allowfullscreen
                                            allowtransparency
                                            allow="autoplay"
                                            ></iframe>
                                        </div>
                                    </vue-plyr>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-md-12 d-flex justify-content-center">
                                        <button v-if="number_questions > 1" class="text-left btn m-t-10 btn-outline-primary rounded mr-2" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Sebelumnya</button>
                                        <button v-if="number_questions < dataTryout.list_questions.length" class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Nomor Soal</h4>
                                <hr/>
                                <div v-if="dataTryout.list_questions.length > 0"  class="text-center" style="padding-top:0px !important;overflow: auto;max-height: 230px;padding-bottom:5px;scrollbar-width: thin;">
                                    <button style="width:100%;max-width: 60px;"  type="button" v-for="(item, index) in dataTryout.list_questions" :key="index" class="text-center btn rounded ml-1 m-1" :class="current_questions == item.id ? 'btn-primary':'btn-outline-primary'" v-on:click="setQuestions(item.id, index)"> {{++index}}</button>
                                </div>
                                <hr/>
                                <div class="d-grid mt-3">
                                    <router-link :to="{name : 'olimpiade_tryout_question', params: {id:$route.params.id}}" type="button" class="btn btn-outline-primary rounded"><i class="mdi mdi-arrow-left"></i> Kembali</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #22a843 !important;
        border-color: #000 !important;
    }
</style>

<script>    
    export default {
        name: 'OlimpiadeTryout',
        metaInfo: {
            title: 'Preview Soal Olimpiade Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                olimpiade_tryout_id  : this.$route.params.id,
                tryout_name     : "",
                form : {
                    id                  : "",
                    olimpiade_tryout_id      : "",
                    olimpiade_category_id         : "",
                    point               : "",
                    question            : "",
                    options_a           : "",
                    options_b           : "",
                    options_c           : "",
                    options_d           : "",
                    options_e           : "",
                    answer              : "",
                    discussion          : "",
                    discussion_video    : "",
                    point_a           : "",
                    point_b           : "",
                    point_c           : "",
                    point_d           : "",
                    point_e           : "",
                    hots              : 0,
                    name              : "",
                    point_options     : ""
                },
                number_questions        : 0,
                current_questions       : "",
                submitted       : false,
                waiting         : false,
                dataFetch       : true,
                dataTryout      : {
                    tryout : [],
                    list_questions : []
                }
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/olimpiade/tryout/detail';

                    this.dataFetch  = true;
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse         = res.data.data;
                            this.dataTryout.tryout  = getResponse
                            this.dataFetch          = false;
                            this.getQuestion();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'olimpiade_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            getQuestion: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/olimpiade/tryout/list-questions';
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    let getResponse                 = res.data.data;
                    this.dataTryout.list_questions  = getResponse
                    this.setQuestions(this.dataTryout.list_questions[0].id, 1);
                });
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataTryout.list_questions.length;

                if(next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id              = this.dataTryout.list_questions[number].id;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id              = this.dataTryout.list_questions[next_number-1].id;
                    this.setQuestions(id, next_number);
                }
            },
            setQuestions: function(id, index) {
                this.current_questions      = id;
                this.number_questions       = index;
                var item                    = this.dataTryout.list_questions.find(item => item.id === id);

                this.form.id                     = item.id;
                this.form.name                   = item.name;
                this.form.olimpiade_category_id  = item.olimpiade_category_id;                
                this.form.point                  = item.point;
                this.form.question               = item.question;                    
                this.form.options_a              = item.options_a;
                this.form.options_b              = item.options_b;
                this.form.options_c              = item.options_c;
                this.form.options_d              = item.options_d;
                this.form.options_e              = item.options_e;
                this.form.answer                 = item.answer;
                this.form.discussion             = item.discussion;
                this.form.discussion_video       = '';

                setTimeout(() => {
                    this.form.discussion_video   = item.discussion_video;                    
                }, 100);

                this.form.point_a           = item.point_a;
                this.form.point_b           = item.point_b;
                this.form.point_c           = item.point_c;
                this.form.point_d           = item.point_d;
                this.form.point_e           = item.point_e;
                this.form.point_options     = item.point_options;

                this.form.id    = id;
                this.form.hots  = item.hots;

                this.scrollToTop();
            },
            scrollToTop() {
                window.scrollTo({top: 10, behavior: 'smooth'});
            },
        }
    }
</script>