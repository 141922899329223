<template>
    <div class="theory-content">
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">{{dataDetail.title}}</h4>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:-10px !important">
                    <div class="col-md-8 col-sm-12 nocopy">
                        <div class="card border" v-if="dataDetail.type == 'text'">
                            <div class="card-body mb-0">
                                <div v-html="dataDetail.content"></div>
                            </div>
                        </div>

                        <div class="card border" v-if="dataDetail.type == 'youtube'">
                            <div class="card-body mb-0">
                                <vue-plyr>
                                    <div class="plyr__video-embed">
                                        <iframe
                                        :src="dataDetail.content"
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                        ></iframe>
                                    </div>
                                </vue-plyr>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                    <div class="mr-3 font-weight-bold">Kategori</div>
                                    <div class="font-weight-600 text-right">
                                        {{dataDetail.category}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                                    <div class="mr-3 font-weight-bold">Tipe Materi</div>
                                    <div class="font-weight-600">
                                        <span v-if="dataDetail.type == 'youtube'" class="badge bg-info">Video</span>
                                        <span v-if="dataDetail.type == 'text'" class="badge bg-warning">Text</span>
                                    </div>
                                </div>

                                <div v-if="dataDetail.attachment" class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                                    <div class="mr-3 font-weight-bold">Lampiran Materi</div>
                                    <div class="font-weight-600">
                                        <a href="#" data-toggle="modal" data-target="#modalAttachment" onclick="javascript:void(0)">Lihat</a>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                    <div class="mr-3 font-weight-bold">Terakhir Update</div>
                                    <div class="font-weight-600">
                                        {{dataDetail.text_updated}}
                                    </div>
                                </div>

                                <div class="d-grid">
                                    <button v-on:click="goBack" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-3" ><i class="mdi mdi-arrow-left"></i> Kembali</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modalAttachment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <object type="application/pdf" :data="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+dataDetail.attachment" width="100%" height="500" style="height: 85vh;">No
                        Support</object>
                    <button type="button" class="btn btn-outline-light btn-block" data-dismiss="modal">Tutup Lampiran</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .theory-content ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .theory-content ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }
</style>
<script>
    export default {
        name: 'MemberCpnsTheoryDetail',
        metaInfo: {
            title: 'Lihat Materi CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                dataFetch       : true,
                dataDetail      : [],
                code            : this.$route.params.code
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/theory/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code    : this.code,
                    }
                }).then(res => {
                    if(res.data.premium) {
                        if(res.data.status) {
                            this.dataFetch  = false;                
                            this.dataDetail = res.data.data;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goBack();
                                }
                            });
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Menu ini hanya bisa diakses oleh pengguna premium',
                            text: 'Ayo upgrade akun kamu untuk mendapatkan benefit yang lebih baik',
                            confirmButtonText: 'Upgrade Akun'
                        }).then((result) => {
                            if (result.value) {
                                this.goUpgrade();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_cpns_theory'});
            },
            goUpgrade: function() {
                this.$router.push({ name: 'member_cpns_package'});
            }
        }
    }
</script>