<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data CPNS Tryout</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'cpns_tryout'}">Data CPNS Tryout</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Nama / Judul Tryout</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                            <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul Wajib diisi!</div>    
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Tipe Tryout</label>
                                            <select class="form-select" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                                <option value="">Pilih Tipe Tryout</option>
                                                <option value="free">Gratis</option>
                                                <option value="premium">Berbayar</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe Tryout Wajib diisi!</div>
                                        </div>

                                        <div v-if="form.type == 'premium'" class="form-group col-md-6 mt-3">
                                            <label>Harga Coret / Diskon</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.price_out">
                                        </div>

                                        <div v-if="form.type == 'premium'" class="form-group col-md-6 mt-3">
                                            <label>Harga</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.price">
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Durasi (Dalam Menit)</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.timer" :class="{ 'is-invalid': submitted && $v.form.timer.$error }">
                                            <div v-if="submitted && !$v.form.timer.required" class="invalid-feedback">Durasi Wajib diisi!</div>
                                            <div v-if="submitted && !$v.form.timer.numeric" class="invalid-feedback">Durasi Wajib menggunakan angka!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Kunci Hasil Tryout</label>
                                            <select class="form-select" v-model="form.result_lock">
                                                <option value="0">Tidak</option>
                                                <option value="1">Iya</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="form.result_lock == '1'">
                                            <label>Tanggal Hasil Tryout</label>
                                            <datepicker v-model="form.result_date" :input-class="{'form-control':true}" :language="language" placeholder="Pilih Tanggal"></datepicker>
                                        </div>

                                        <div class="form-group col-md-12 mt-3" v-if="form.result_lock == '1'">
                                            <div class="alert alert-info" role="alert">
                                                <b>Keterangan : </b>
                                                <ol class="mt-2">
                                                    <li>Jika <b>Kunci Hasil Tryout</b> dipilih <b>iya</b>, maka hasil tryout, pembahasan & grafik nilai tidak akan muncul di halaman member.</li>
                                                    <li>Jika <b>Kunci Hasil Tryout</b> dipilih <b>iya</b>, maka hasil tryout, pembahasan & grafik nilai akan muncul di halaman member sesuai dengan <b>Tanggal Hasil Tryout</b>.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'cpns_tryout'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
     import Datepicker from 'vuejs-datepicker';   
    import {id} from 'vuejs-datepicker/dist/locale';

    import {
        required, numeric
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'CpnsTryout',
        metaInfo: {
            title: 'Form CPNS Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components : {
            Datepicker
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                form : {
                    id              : "",
                    title           : "",
                    type            : "",
                    timer           : "",
                    price           : "",
                    price_out       : "",
                    status          : "draft",
                    result_lock     : 0,
                    result_date     : moment().format('YYYY-MM-DD'),
                },
                submitted       : false,
                waiting         : false,
                language        : id
            }
        },
        validations: {
            form: {
                type: {
                    required
                },
                title: {
                    required
                },
                timer: {
                    required,
                    numeric
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('type', this.form.type);
                    formData.append('timer', this.form.timer);
                    formData.append('price', this.form.price);
                    formData.append('price_out', this.form.price_out);
                    formData.append('status', this.form.status);
                    formData.append('result_lock', this.form.result_lock);
                    formData.append('result_date', this.convertDate(this.form.result_date));

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cpns/tryout/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'cpns_tryout' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/cpns/tryout/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id            = getResponse.id;
                            this.form.title         = getResponse.title;
                            this.form.type          = getResponse.type;
                            this.form.timer         = getResponse.timer;
                            this.form.price         = getResponse.price;
                            this.form.price_out     = getResponse.price_out;
                            this.form.status        = getResponse.status;
                            this.form.result_lock   = getResponse.result_lock;

                            if(getResponse.result_date != null) {
                                this.form.result_date    = getResponse.result_date;
                            }

                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'cpns_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            }
        }
    }
</script>