<template>
    <div class="page-content">
        <div class="container-fluid">
            <div v-if="dataFetch" class="row">
                <div class="col-xl-7 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-5 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch" class="row">
                <div class="col-xl-7">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0">Kelola Data Soal</h4>

                                <div class="page-title-right">
                                    <router-link class="btn btn-outline-primary rounded btn-sm"
                                        :to="{name : 'kedinasan_tryout'}"><i class="fa fa-arrow-left"></i> Kembali
                                    </router-link>
                                </div>
                            </div>

                            <hr style="margin-top:-10px" />

                            <div class="mb-1 row">
                                <label for="example-text-input" class="col-md-4 col-form-label">Judul Tryout</label>
                                <label for="example-text-input" class="col-md-8 col-form-label text-right">{{dataTryout.tryout.title}}</label>
                            </div>

                            <div class="mb-1 row">
                                <label for="example-text-input" class="col-md-4 col-form-label">Tipe</label>
                                <label for="example-text-input" class="col-md-8 col-form-label text-right">
                                    <span v-if="dataTryout.tryout.type == 'premium'" class="badge bg-info">Berbayar</span>
                                    <span v-if="dataTryout.tryout.type == 'free'" class="badge bg-warning">Gratis</span>
                                </label>
                            </div>

                            <div class="mb-1 row">
                                <label for="example-text-input" class="col-md-4 col-form-label">Durasi</label>
                                <label for="example-text-input" class="col-md-8 col-form-label text-right">
                                    {{dataTryout.tryout.timer}} Menit
                                </label>
                            </div>

                            <div class="mb-1 row">
                                <label for="example-text-input" class="col-md-4 col-form-label">Harga</label>
                                <label for="example-text-input" class="col-md-8 col-form-label text-right">
                                    <span class="text-muted" v-if="dataTryout.tryout.price_out"><s>{{dataTryout.tryout.text_price_out}}</s></span> {{dataTryout.tryout.text_price}}
                                </label>
                            </div>

                            <div class="mb-1 row">
                                <label for="example-text-input" class="col-md-4 col-form-label">Status</label>
                                <label for="example-text-input" class="col-md-8 col-form-label text-right">
                                    <span class="badge bg-primary">Publish</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div> <!-- end col -->

                <div class="col-xl-5">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0">Nomor Soal</h4>
                            </div>

                            <hr style="margin-top:-5px" />

                            <div v-if="dataFetchQuestions" class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar">
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!dataFetchQuestions">
                                <div v-if="dataTryout.list_questions.length <= 0" class="alert alert-warning text-center" role="alert">
                                    Kamu belum membuat soal pada tryout ini
                                </div>

                                <div v-if="dataTryout.list_questions.length > 0"  class="text-center" style="padding-top:0px !important;overflow: auto;max-height: 230px;padding-bottom:5px;scrollbar-width: thin;">
                                    <button style="width:100%;max-width: 60px;"  type="button" v-for="(item, index) in dataTryout.list_questions" :key="index" class="text-center btn rounded ml-1 m-1" :class="currentQuestions == item.id ? 'btn-primary':'btn-outline-primary'" v-on:click="setQuestions(item.id, index)"> {{++index}}</button>
                                </div>
                            </div>
                            <hr/>
                            <div class="d-grid mt-3">
                                <button v-on:click="addQuestions" type="button" class="btn btn-primary btn-block"><i class="fa fa-plus"></i> Tambah Soal</button>
                            </div>
                            <div class="d-grid mt-2">
                                <router-link :to="{name : 'kedinasan_tryout_import', params: {id:this.dataTryout.tryout.id}}" type="button" class="btn btn-outline-primary btn-block"><i class="fa fa-file-import"></i> Import Soal</router-link>
                            </div>
                            <div class="d-grid mt-2" v-if="form.id">
                                <button v-on:click="deleteQuestions" type="button" class="btn btn-danger btn-block"><i class="fa fa-trash"></i> Hapus Soal</button>
                            </div>
                            <div class="d-grid mt-2" v-if="dataTryout.list_questions.length > 0">
                                <button v-on:click="resetQuestions" type="button" class="btn btn-warning btn-block"><i class="fa fa-undo"></i> Reset Soal</button>
                            </div>
                        </div>
                    </div>
                </div> <!-- end col -->

            <div class="col-sm-12" v-if="showQuestions">
                <div class="card border">
                    <div class="card-body">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">{{form_title}}</h4>
                        </div>

                        <form v-on:submit.prevent="onSubmit">
                            <input type="hidden" class="form-control" id="exampleInputEmail1" placeholder="Masukan poin..." v-model="form.id">
                            <div class="row">
                                <div class="form-group col-md-6 mt-3">
                                    <label for="exampleFormControlSelect1">Kategori</label>
                                    <select class="form-select" id="exampleFormControlSelect1" v-model="form.category_id" :class="{ 'is-invalid': submitted && $v.form.category_id.$error }" v-on:change="getSubCategory">
                                        <option value="">Pilih Kategori</option>
                                        <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.category_id.required" class="invalid-feedback">Kategori wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6 mt-3">
                                    <label for="exampleFormControlSelect1">Sub Kategori Soal</label>
                                    <select class="form-select" id="exampleFormControlSelect1" v-model="form.sub_category_id">
                                        <option value="">Pilih Sub Kategori</option>
                                        <option v-for="(item, index) in dataSubCategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12 mt-3" v-if="form.category_id != '3'">
                                    <label>Poin</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan poin..." v-model="form.point">
                                </div>

                                <div class="col-md-12 mt-3 row" v-if="form.category_id == '3'">
                                    <div class="form-group col-md-4 mt-3" >
                                        <label>Poin A</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_a">
                                    </div>
                                    <div class="form-group col-md-4 mt-3" >
                                        <label>Poin B</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_b">
                                    </div>
                                    <div class="form-group col-md-4 mt-3" >
                                        <label>Poin C</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_c">
                                    </div>
                                    <div class="form-group col-md-4 mt-3" >
                                        <label>Poin D</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_d">
                                    </div>
                                    <div class="form-group col-md-4 mt-3" >
                                        <label>Poin E</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_e">
                                    </div>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Pertanyaan</label>
                                    <ckeditor v-model="form.question" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Opsi A</label>
                                    <ckeditor v-model="form.options_a" :config="editorConfigOptionA"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Opsi B</label>
                                   <ckeditor v-model="form.options_b" :config="editorConfigOptionB"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Opsi C</label>
                                    <ckeditor v-model="form.options_c" :config="editorConfigOptionC"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Opsi D</label>
                                   <ckeditor v-model="form.options_d" :config="editorConfigOptionD"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Opsi E</label>
                                   <ckeditor v-model="form.options_e" :config="editorConfigOptionE"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3" v-if="form.category_id != '3'">
                                    <label for="exampleFormControlSelect1">Jawaban Benar</label>
                                    <select class="form-select" id="exampleFormControlSelect1" v-model="form.answer">
                                        <option value="">Pilih Jawaban</option>
                                        <option value="a">A</option>
                                        <option value="b">B</option>
                                        <option value="c">C</option>
                                        <option value="d">D</option>
                                        <option value="e">E</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Pembahasan</label>
                                    <ckeditor v-model="form.discussion" :config="editorConfigDiscussion"></ckeditor>
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label>Video Pembahasan</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url youtube..." v-model="form.discussion_video">
                                </div>

                                <div class="form-group col-md-12 mt-3">
                                    <label for="exampleFormControlSelect1">Apakah soal ini hots ?</label>
                                    <select class="form-select" id="exampleFormControlSelect1" v-model="form.hots">
                                        <option value="0">Tidak</option>
                                        <option value="1">Iya</option>
                                    </select>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary mr-1 mt-3" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Soal' }}</button>
                        </form>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'KedinasanTryout',
        metaInfo: {
            title: 'Form Kedinasan Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getCategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                dataFetch           : true,
                dataFetchQuestions  : true,
                label               : 'Tambah',
                form_title          : 'Tambah Soal',
                currentQuestions    : '',
                showQuestions       : false,
                dataTryout          : {
                    tryout : [],
                    list_questions : []
                },
                dataCategory        : [],
                dataSubCategory     : [],
                form : {
                    id                  : "",
                    kedinasan_tryout_id      : "",
                    category_id         : "",
                    sub_category_id     : "",
                    point               : "",
                    question            : "",
                    options_a           : "",
                    options_b           : "",
                    options_c           : "",
                    options_d           : "",
                    options_e           : "",
                    answer              : "",
                    discussion          : "",
                    discussion_video    : "",
                    point_a           : "",
                    point_b           : "",
                    point_c           : "",
                    point_d           : "",
                    point_e           : "",
                    hots              : ""
                },
                submitted       : false,
                waiting         : false,
                editorConfig: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigOptionA: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigOptionB: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigOptionC: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigOptionD: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigOptionE: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigDiscussion: {
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                }
            }
        },
        validations: {
            form: {
                category_id: {
                    required
                }
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/kedinasan/tryout/detail';

                    this.dataFetch  = true;
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse         = res.data.data;
                            this.dataTryout.tryout  = getResponse
                            this.dataFetch          = false;
                            this.getQuestion();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'kedinasan_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            getQuestion: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/kedinasan/tryout/list-questions';

                this.dataFetchQuestions  = true;
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    let getResponse                 = res.data.data;
                    this.dataTryout.list_questions  = getResponse
                    this.dataFetchQuestions         = false;
                });
            },
            addQuestions : function() {
                this.form_title = "Tambah Soal";
                this.showQuestions = true;
                this.currentQuestions         = "";
                this.form.id                  = "";
                this.form.category_id         = "";
                this.form.sub_category_id     = "";
                this.form.point               = "";
                this.form.question            = "";
                this.form.options_a           = "";
                this.form.options_b           = "";
                this.form.options_c           = "";
                this.form.options_d           = "";
                this.form.options_e           = "";
                this.form.answer              = "";
                this.form.discussion          = "";
                this.form.discussion_video    = "";
                this.form.point_a             = "";
                this.form.point_b             = "";
                this.form.point_c             = "";
                this.form.point_d             = "";
                this.form.point_e             = "";
                this.form.hots                = 0;

            },
            setQuestions: function(id, index) {
                this.form_title         = "Edit Soal - Nomor "+index;
                this.currentQuestions   = id;

                var item = this.dataTryout.list_questions.find(item => item.id === id);

                this.form.id                  = item.id;
                this.form.category_id         = item.category_id;

                this.getSubCategory(item.category_id);

                setTimeout(() => {
                    if (typeof (item.sub_category_id) !== 'undefined' && item.sub_category_id !== null) {
                        this.form.sub_category_id     = item.sub_category_id;                    
                    } else {
                        this.form.sub_category_id     = '';
                    }
                }, 100);
                
                this.form.point             = item.point;
                this.form.question          = item.question;                    
                this.form.options_a         = item.options_a;
                this.form.options_b         = item.options_b;
                this.form.options_c         = item.options_c;
                this.form.options_d         = item.options_d;
                this.form.options_e         = item.options_e;
                this.form.answer            = item.answer;
                this.form.discussion        = item.discussion;
                this.form.discussion_video  = item.discussion_video;
                this.form.point_a           = item.point_a;
                this.form.point_b           = item.point_b;
                this.form.point_c           = item.point_c;
                this.form.point_d           = item.point_d;
                this.form.point_e           = item.point_e;

                this.form.id = id;
                this.form.hots = item.hots;
                this.showQuestions = true;
            },
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'general/list/category';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            getSubCategory: function() {
                if(this.form.category_id != '') {
                    let uri         = process.env.VUE_APP_APIHOST+'general/list/sub-category';
                    
                    this.$http.get(uri,{
                        params : {
                            category_id : this.form.category_id
                        }
                    }).then(res => {
                        this.dataSubCategory   = res.data.data;
                    });
                } else {
                    this.dataSubCategory   = [];
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();
                    
                    formData.append('id', this.form.id);
                    formData.append('kedinasan_tryout_id', this.dataTryout.tryout.id);
                    formData.append('category_id', this.form.category_id);
                    formData.append('sub_category_id', this.form.sub_category_id);
                    formData.append('point', this.form.point);
                    formData.append('question', this.form.question);
                    formData.append('options_a', this.form.options_a);
                    formData.append('options_b', this.form.options_b);
                    formData.append('options_c', this.form.options_c);
                    formData.append('options_d', this.form.options_d);
                    formData.append('options_e', this.form.options_e);
                    formData.append('answer', this.form.answer);
                    formData.append('discussion', this.form.discussion);
                    formData.append('discussion_video', this.form.discussion_video);
                    formData.append('point_a', this.form.point_a);
                    formData.append('point_b', this.form.point_b);
                    formData.append('point_c', this.form.point_c);
                    formData.append('point_d', this.form.point_d);
                    formData.append('point_e', this.form.point_e);
                    formData.append('hots', this.form.hots);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/kedinasan/tryout/save-questions', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getQuestion();
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            deleteQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus soal ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/kedinasan/tryout/delete-questions', {
                            id: this.form.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getQuestion();
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
            resetQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Reset Soal',
                    html: 'Apakah kamu yakin ingin melakukan reset soal ?<br/>Semua data yang sudah di reset tidak dapat dikembalikan',
                    confirmButtonText: 'Reset Soal',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/kedinasan/tryout/reset-questions', {
                            id: this.dataTryout.tryout.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getQuestion();
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            }
        }
    }
</script>