<template>
    <div class="page-content">
        <div class="container-fluid">
            <div v-if="dataFetch" class="row">
                <div class="col-xl-7 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-5 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch" class="row">
                <div class="col-xl-7">
                    <div class="card border">
                        <object type="application/pdf" :data="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+dataDetail.attachment" width="100%" height="500" style="height: 85vh;">No Support</object>
                        <div class="card-body mb-0 mt-0">
                            <p><b>Catatan Peserta :</b></p>
                            <div class="mt-0 mb-0" v-html="dataDetail.description"></div>
                        </div>
                    </div>
                </div> 

                <div class="col-xl-5">
                    <div class="card border">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Berikut ini adalah naskah yang peserta kirim</h4>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Nama Pengirim</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.name}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Judul Naskah</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.title}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Sub Tema</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.users_papers_category_value}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Asal Sekolah</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.school_origin}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Tanggal Mengirim</div>
                                <div class="font-weight-600 text-right">
                                    {{dataDetail.text_upload}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-0">
                                <div class="mr-3">Status</div>
                                <div class="font-weight-600 text-right">
                                    <span class="badge bg-warning" v-if="dataDetail.status == 'rejected'">Direvisi</span>
                                    <span class="badge bg-success" v-if="dataDetail.status == 'accepted'">Diterima</span>
                                    <span class="badge bg-info" v-if="dataDetail.status == 'waiting'">Menunggu Konfirmasi</span>
                                </div>
                            </div>
                            <hr/>
                            <div class="d-grid mt-3">
                                <button v-if="dataDetail.status != 'rejected'" class="btn btn-primary btn-block" type="button" data-toggle="modal" data-target="#modalPapers" href="javascript:void(0)">
                                    <i class="fa fa-edit"></i> Ubah Status & Penilaian
                                </button>
                                <button class="btn btn-outline-primary btn-block mt-2" type="button" v-on:click="goBack()">Kembali</button>
                            </div>
                            <div v-if="dataDetail.status == 'rejected'" class="alert alert-warning mt-3 mb-0" role="alert">
                                <b>Keterangan : </b>
                                <ol class="mt-2">
                                    <li>Admin tidak dapat merubah status jika sudah <b>direvisi</b></li>
                                    <li>Jika status <b>direvisi</b>, admin bisa melakukan perubahan status & nilai jika peserta mengirimkan kembali file naskah terbaru.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div> <!-- end col -->
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalPapers" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Status & Penilaian</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    
                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-1">
                                        <label>Status</label>
                                        <select class="form-select" id="exampleFormControlSelect1" v-model="form.status" :class="{ 'is-invalid': submitted && $v.form.status.$error }" v-on:change="changeStatus" :disabled="dataDetail.lock_scored == 1">
                                            <option value="">Pilih Status</option>
                                            <option value="rejected">Direvisi</option>
                                            <option value="accepted">Diterima</option>
                                        </select>

                                        <div v-if="submitted && !$v.form.status.required" class="invalid-feedback d-block">
                                        Status Wajib Dipilih!</div>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'rejected'">
                                        <label>Catatan untuk peserta</label>
                                        <ckeditor v-model="form.feedback" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'accepted'">
                                        <label>Nilai Orisinalitas <i>(Original)</i></label>
                                        <input type="number" class="form-control"
                                                placeholder="Masukan nilai..." v-model="form.original" :class="{ 'is-invalid': submitted && $v.form.original.$error }" :disabled="dataDetail.lock_scored == 1">
                                            <div v-if="submitted && !$v.form.original.required" class="invalid-feedback">Nilai Orisinalitas Wajib diisi</div>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'accepted'">
                                        <label>Nilai Relevansi <i>(Relevance)</i></label>
                                        <input type="number" class="form-control"
                                                placeholder="Masukan nilai..." v-model="form.relevance" :class="{ 'is-invalid': submitted && $v.form.relevance.$error }" :disabled="dataDetail.lock_scored == 1">
                                            <div v-if="submitted && !$v.form.relevance.required" class="invalid-feedback">Nilai Relevansi Wajib diisi</div>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'accepted'">
                                        <label>Nilai Kreatifitas <i>(Creativity)</i></label>
                                        <input type="number" class="form-control"
                                                placeholder="Masukan nilai..." v-model="form.creativity" :class="{ 'is-invalid': submitted && $v.form.creativity.$error }" :disabled="dataDetail.lock_scored == 1">
                                            <div v-if="submitted && !$v.form.creativity.required" class="invalid-feedback">Nilai Kreatifitas Wajib diisi</div>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'accepted'">
                                        <label>Nilai Analisis <i>(Analysis)</i> </label>
                                        <input type="number" class="form-control"
                                                placeholder="Masukan nilai..." v-model="form.novelty" :class="{ 'is-invalid': submitted && $v.form.novelty.$error }" :disabled="dataDetail.lock_scored == 1">
                                            <div v-if="submitted && !$v.form.novelty.required" class="invalid-feedback">Nilai Novelty Wajib diisi</div>
                                    </div>

                                    <div class="mt-3" v-if="form.status == 'accepted'">
                                        <label>Nilai Pemecahan Masalah <i>(Solution)</i> </label>
                                        <input type="number" class="form-control"
                                                placeholder="Masukan nilai..." v-model="form.solution" :class="{ 'is-invalid': submitted && $v.form.solution.$error }" :disabled="dataDetail.lock_scored == 1">
                                            <div v-if="submitted && !$v.form.solution.required" class="invalid-feedback">Nilai solution Wajib diisi</div>
                                    </div>

                                    <hr/>
                                    <div class="mt-3" v-if="dataDetail.lock_scored == 0">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                                    </div>

                                    <div v-if="dataDetail.lock_scored == 1" class="alert alert-warning mt-3 mb-0" role="alert">
                                        <b>Keterangan : </b>
                                        <ol class="mt-2 mb-0">
                                            <li>Admin tidak dapat merubah nilai jika nilai <b>sudah dipublish</b></li>
                                            <li>Jika inginmerubah data, admin harus melakukan perubahan status nilai menjadi unpublish terlebih dahulu.</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>    
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'MemberUsersPapersHistoryDetail',
        metaInfo: {
            title: 'Detail Riwayat Pengumpulan File',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getData();
        },
        data() {
            return {
                form : {
                    status : '',
                    feedback : '',
                    original : '',
                    creativity : '',
                    relevance : '',
                    novelty : '',
                    solution : '',
                },
                submitted       : false,
                waiting         : false,
                dataFetch       : true,
                dataDetail      : [],
                id              : this.$route.params.id,
                users_papers_id : this.$route.params.users_papers_id,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                }
            }
        },
        validations: {
            form: {
                status: {
                    required
                },
                original: {
                    required
                },
                creativity: {
                    required
                },
                relevance: {
                    required
                },
                novelty: {
                    required
                },
                solution: {
                    required
                }
            }
        },
        methods: {
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/package/users-papers-detail';

                this.dataFetch  = true;
                this.$http.get(uri,{
                    params : {
                        id                : this.id,
                        users_papers_id   : this.users_papers_id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse         = res.data.data;
                        this.dataDetail         = getResponse
                        this.dataFetch          = false;

                        if(this.dataDetail.status != 'waiting') {
                            this.form.status        = this.dataDetail.status;
                        }
                        
                        this.form.original      = this.dataDetail.original;
                        this.form.relevance     = this.dataDetail.relevance;
                        this.form.creativity    = this.dataDetail.creativity;
                        this.form.novelty       = this.dataDetail.novelty;
                        this.form.solution      = this.dataDetail.solution;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'esai_package_result',
                    params : {
                        id      : this.id
                    }
                });
            },
            onSubmit: function () {
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Simpan Data',
                        text: 'Apakah kamu yakin ingin menyimpan data ini?',
                        confirmButtonText: 'Simpan',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                    }).then((result) => {
                        this.waiting    = true;
                        if (result.value) {
                            this.$http.post(process.env.VUE_APP_APIHOST + 'admin/package/save-users-papers', {
                            id              : this.dataDetail.id,
                            title           : this.dataDetail.title,
                            name            : this.dataDetail.name,
                            text_result_date : this.dataDetail.text_result_date,
                            package_name    : this.dataDetail.package_name,
                            email           : this.dataDetail.username,
                            status        : this.form.status,
                            feedback : this.form.feedback,
                            original : this.form.original,
                            creativity : this.form.creativity,
                            relevance : this.form.relevance,
                            novelty : this.form.novelty,
                            solution : this.form.solution
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.waiting    = false;
                                        this.submitted  = false;
                                        document.getElementById("btnCloseModal").click();
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                                this.waiting = false;
                            }
                        })
                        }
                    });
                }
            },
            changeStatus: function() {
                if(this.form.status == 'rejected') {
                    this.form.original = 0;
                    this.form.relevance = 0;
                    this.form.creativity = 0;
                    this.form.novelty = 0;
                    this.form.solution = 0;
                } else {
                    this.form.original      = '';
                    this.form.relevance     = '';
                    this.form.creativity    = '';
                    this.form.novelty       = '';
                    this.form.solution      = '';
                }
            }
        }
    }
</script>