<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Data Tryout CPNS</h4>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Tipe Tryout</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="type" v-on:change="getData">
                                                <option value="">Lihat Semua</option>
                                                <option value="free">Gratis</option>
                                                <option value="premium">Premium</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="status" v-on:change="getData">
                                                <option value="">Lihat Semua</option>
                                                <option value="owned">Sudah dimiliki</option>
                                                <option value="notyet">Belum dimiliki</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kata kunci</label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                                                <div class="input-group-append">
                                                    <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                            class="fa fa-search"></i> Cari </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top:-10px !important" class="row" v-if="dataFetch">
                    <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                        <div class="card d-block border rounded-lg p-3 m-t-10">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar text-center">
                                                <div class="sb-sm mb-2"></div>
                                                <hr/>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                                <div class="sb-lg"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top:-10px !important" class="row" v-if="!dataFetch">
                    <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                        <div class="card border">
                            <div class="card-body">
                                <h5 class="mb-sm-0"><b>{{item.title}}</b></h5>
                                <hr/>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Harga</div>
                                    <div class="font-weight-600">
                                        <s v-if="item.price_out" class="text-danger mr-2">{{item.text_price_out}}</s>
                                        <b>{{item.text_price}}</b>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Durasi Soal</div>
                                    <div class="font-weight-600">{{item.timer}} Menit</div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Tipe Tryout</div>
                                    <div class="font-weight-600">
                                        <span class="badge bg-warning" v-if="item.type == 'free'">Gratis</span>
                                        <span class="badge bg-info" v-if="item.type == 'premium'">Premium</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Status</div>
                                    <div class="font-weight-600">
                                        <span v-if="!item.owned" class="badge bg-danger">Belum dimiliki</span>
                                        <span v-if="item.owned" class="badge bg-success">Sudah dimiliki</span>
                                    </div>
                                </div>

                                <div v-if="!item.owned">
                                    <div class="d-grid mt-3" v-if="item.type == 'premium'">
                                        <button v-if="!item.cart" type="button" :disabled="fetchCart" class="btn btn-primary btn-block" v-on:click="addToCart(item.id)">
                                            <i class="mdi mdi-cart-variant"></i> {{fetchCart ? 'Loading...':'Tambahkan ke keranjang'}}
                                        </button>

                                        <button v-if="item.cart" type="button" :disabled="fetchCart" class="btn btn-danger btn-block" v-on:click="deleteCart(item.id)">
                                            <i class="mdi mdi-cart-minus"></i> {{fetchCart ? 'Loading...':'Hapus di keranjang'}}
                                        </button>

                                        <button v-if="item.cart" type="button" class="btn btn-outline-primary btn-block mt-2" v-on:click="goToCart">
                                            <i class="mdi mdi-cart-check"></i> Lihat keranjang
                                        </button>
                                    </div>

                                    <div class="d-grid mt-3" v-if="item.type == 'free'">
                                        <div role="alert" class="alert alert-info text-center"> Upload <b>persyaratan</b> untuk mendapatkan tryout gratis. </div>
                                        <button data-toggle="modal" data-target="#modalUpload" v-on:click="showItem(item.id)" type="button" class="btn btn-outline-primary btn-block"><i class="mdi mdi-file-upload-outline"></i> Upload Persyaratan</button>
                                    </div>
                                </div>

                                <div v-if="item.owned" class="d-grid mt-3">
                                    <button v-on:click="goToOverview(item.code)" type="button" class="btn btn-primary btn-block mt-2"><i class="mdi mdi-book-edit-outline"></i> Kerjakan Soal</button>
                                    <button v-on:click="goToRank(item.code)" type="button" class="btn btn-outline-primary btn-block mt-2"><i class="mdi mdi-trophy-variant-outline"></i> Rangking Peserta</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                        <hr/>
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>

                    <div v-if="emptyState">
                        <div class="card border p-3">
                            <empty-state></empty-state>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalUpload" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="mdi mdi-file-upload-outline"></i> Upload Bukti Persyaratan</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="fetchRequirement" class="d-block p-3">
                        <div class="d-block rounded-lg p-3 mt-3">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar text-center">
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg mb-4"></div>

                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg mb-4"></div>

                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!fetchRequirement" class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                            <div>
                                <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Tryout</div>
                                <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{form.title}}</div>
                            </div>
                        </div>
                        <div v-if="dataRequirement.status != 'waiting'">
                            <div class="d-block mb-1 form-group">
                                <label for="exampleInputEmail1">Follow Instagram & Tag 5 teman kamu di kolom komentar postingan Try Out</label>
                                <div class="input-group mb-3">
                                    <input :value="dataRequirement.attachment_one" v-on:click="viewImage(dataRequirement.attachment_one)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Follow Instagram & Tag 5 teman kamu di kolom komentar postingan Try Out..." style="cursor:pointer">
                                    <input class="d-none" type="file" name="file_attachment_1" id="file_attachment_1" @change="previewFilesOne" accept="image/x-png,image/gif,image/jpeg">
                                    <div class="input-group-append" v-if="!dataRequirement.attachment_one">
                                        <button :disabled="uploadFileOne" v-on:click="uploadOne()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileOne ? 'Uploading...' : 'Upload Gambar' }}
                                        </button>
                                    </div>

                                    <div class="input-group-append" v-if="dataRequirement.attachment_one">
                                        <button :disabled="uploadFileOne" v-on:click="uploadOne()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileOne ? 'Uploading...' : 'Upload Ulang' }}
                                        </button>
                                    </div>

                                    <progress v-if="uploadFileOne" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>
                            </div>

                            <div class="d-block mb-1 form-group">
                            <label for="exampleInputEmail1">Share postingan/gambar ke instagram story/grup WA/grup Telegram</label>
                                <div class="input-group mb-3">
                                    <input :value="dataRequirement.attachment_two" v-on:click="viewImage(dataRequirement.attachment_two)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Share postingan/gambar ke instagram story/grup WA/grup Telegram..." style="cursor:pointer">
                                    <input class="d-none" type="file" name="file_attachment_2" id="file_attachment_2" @change="previewFilesTwo" accept="image/x-png,image/gif,image/jpeg">
                                    <div class="input-group-append" v-if="!dataRequirement.attachment_two">
                                        <button :disabled="uploadFileTwo" v-on:click="uploadTwo()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileTwo ? 'Uploading...' : 'Upload Gambar' }}
                                        </button>
                                    </div>

                                    <div class="input-group-append" v-if="dataRequirement.attachment_two">
                                        <button :disabled="uploadFileTwo" v-on:click="uploadTwo()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileTwo ? 'Uploading...' : 'Upload Ulang' }}
                                        </button>
                                    </div>

                                    <progress v-if="uploadFileTwo" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>
                            </div>

                            <div class="d-block mb-1 form-group">
                                <label for="exampleInputEmail1">Bukti Subscribe Youtube Gubuk Sains Prima</label>
                                <div class="input-group mb-3">
                                    <input :value="dataRequirement.attachment_three" v-on:click="viewImage(dataRequirement.attachment_three)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Bukti Subscribe Youtube Gubuk Sains Prima" style="cursor:pointer">
                                    <input class="d-none" type="file" name="file_attachment_3" id="file_attachment_3" @change="previewFilesThree" accept="image/x-png,image/gif,image/jpeg">
                                    <div class="input-group-append" v-if="!dataRequirement.attachment_three">
                                        <button :disabled="uploadFileThree" v-on:click="uploadThree()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileThree ? 'Uploading...' : 'Upload Gambar' }}
                                        </button>
                                    </div>

                                    <div class="input-group-append" v-if="dataRequirement.attachment_three">
                                        <button :disabled="uploadFileThree" v-on:click="uploadThree()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                            <i class="fa fa-upload"></i> {{ uploadFileThree ? 'Uploading...' : 'Upload Ulang' }}
                                        </button>
                                    </div>

                                    <progress v-if="uploadFileThree" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                </div>
                            </div>

                            <div v-if="dataRequirement.status == 'rejected'" class="alert alert-danger text-center" role="alert">
                                Persyaratan yg kamu krim sebelumnya <b>ditolak</b> oleh admin.<br/>
                                Harap upload <b>persyaratan sesuai dengan catatan yang tertera</b>!
                            </div>

                            <div class="d-grid">
                                <button :disabled="!requirement_id" type="button" v-on:click="sendRequirements" class="btn btn-primary rounded btn-block"><i class="mdi mdi-send-outline"></i> Kirim Bukti Persyaratan</button>
                                <button id="btnCloseModal" type="button" data-dismiss="modal" class="btn btn-outline-primary rounded btn-block mt-1"><i class="mdi mdi-cancel"></i> Batal</button>
                            </div>
                        </div>

                        <div v-if="dataRequirement.status == 'waiting'">
                            <div class="alert alert-warning text-center" role="alert">
                                Menunggu verifikasi persyaratan dari admin
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    
    export default {
        name: 'MemberCpnsTryout',
        metaInfo: {
            title: 'Data Tryout CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                type        : "",
                status      : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 6,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                files               : [],
                preview_image_1     : "",
                file_attachment_1   : "",
                uploadPercentage    : 0,
                uploadFileOne       : false,
                uploadFileTwo       : false,
                uploadFileThree     : false,
                form                : {
                    id : '',
                    title : ''
                },
                dataRequirement : [],
                requirement_id  : "",
                fetchRequirement : true,
                fetchCart : false
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        type        : this.type,
                        status      : this.status,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goToOverview: function(code="") {
                this.$router.push({ name: 'member_cpns_tryout_overview' , params: {
                    code : code
                }});
            },
            goToRank: function(code="") {
                this.$router.push({ name: 'member_cpns_tryout_rank' , params: {
                    code : code
                }});
            },
            showItem: function(id) {
                this.fetchRequirement = true;
                var item    = this.dataList.find(item => item.id === id);

                this.form.id                = item.id;
                this.form.title             = item.title;

                let uri         = process.env.VUE_APP_APIHOST+'member/tryout/check/requirement';
      
                this.$http.get(uri,{
                    params : {
                        entity_id   : this.form.id,
                        type : 'cpns'
                    }
                }).then(res => {
                    this.fetchRequirement = false;
                    if(res.data.status) {
                        this.dataRequirement = res.data.data;
                        this.requirement_id  = this.dataRequirement.id;
                    }
                });
            },
            uploadOne: function () {
                document.getElementById("file_attachment_1").click()
            },
            previewFilesOne(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileOne      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_one', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cpns');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/tryout/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileOne = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadTwo: function () {
                document.getElementById("file_attachment_2").click()
            },
            previewFilesTwo(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileTwo      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_two', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cpns');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/tryout/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileTwo = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadThree: function () {
                document.getElementById("file_attachment_3").click()
            },
            previewFilesThree(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileThree      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_three', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cpns');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/tryout/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileThree = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            viewImage: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            sendRequirements: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kirim bukti ?',
                    text: 'Apakah kamu yakin akan mengirim bukti persyaratan ? ',
                    confirmButtonText: 'Kirim',
                    showCancelButton: true,
                    cancelButtonText: 'Cek kembali',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/tryout/submit/requirement', {
                            id: this.requirement_id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.showItem(this.form.id);
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });                
            },
            addToCart: function(id="") {
                this.fetchCart  = true;
                this.$http.post(process.env.VUE_APP_APIHOST + 'user/tryout/add-to-cart', {
                    id: id,
                    type: 'cpns'
                })
                .then(response => {
                    this.fetchCart  = false;
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            },
            deleteCart: function(id="") {
                this.fetchCart  = true;
                this.$http.post(process.env.VUE_APP_APIHOST + 'user/tryout/delete-cart', {
                    id: id,
                    type: 'cpns'
                })
                .then(response => {
                    this.fetchCart  = false;
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            },
            goToCart: function() {
                this.$router.push({ name: 'member_cart_list'});
            }
        }
    }
</script>