<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data Paket Olimpiade</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'olimpiade_package'}">Data Paket Olimpiade</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Tipe Paket</label>
                                            <select class="form-select" v-model="form.type_package" :class="{ 'is-invalid': submitted && $v.form.type_package.$error }">
                                                <option value="">Pilih Tipe Paket</option>
                                                <option value="free">Gratis</option>
                                                <option value="premium">Berbayar</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.type_package.required" class="invalid-feedback">Tipe Tryout Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Nama</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }">
                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Nama Wajib diisi!</div>    
                                        </div>

                                        <div v-if="form.type_package == 'premium'" class="form-group col-md-6 mt-3">
                                            <label>Harga Coret / Diskon</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.price_out">
                                        </div>

                                        <div v-if="form.type_package == 'premium'" class="form-group col-md-6 mt-3">
                                            <label>Harga</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.price">
                                        </div>

                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label>Durasi Paket (Dalam Hari)</label>
                                            <input type="number" class="form-control"
                                                placeholder="Masukan data..." v-model="form.active_days" :class="{ 'is-invalid': submitted && $v.form.active_days.$error }">
                                            <div v-if="submitted && !$v.form.active_days.required" class="invalid-feedback">Durasi Wajib diisi!</div>
                                            <div v-if="submitted && !$v.form.active_days.numeric" class="invalid-feedback">Durasi Wajib menggunakan angka!</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label for="exampleFormControlSelect1">Akses Materi</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="form.access_theory">
                                                <option value="1">Diperbolehkan</option>
                                                <option value="0">Tidak Diperbolehkan</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label for="exampleFormControlSelect1">Akses Latihan</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="form.access_exercises">
                                                <option value="1">Diperbolehkan</option>
                                                <option value="0">Tidak Diperbolehkan</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Favorit</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="form.favorite">
                                                <option value="1">Iya</option>
                                                <option value="0">Tidak</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Tryout</label>
                                            <multiselect
                                                v-model="form.tryout_id" tag-placeholder="Add this as new tag"
                                                placeholder="Pilih Tryout" label="title" track-by="id" :options="list_tryout"
                                                :multiple="true" :taggable="true"></multiselect>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleInputEmail1">Cover</label>
                                            <input type="file" class="form-control" name="cover" id="cover" @change="previewFiles" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_image">
                                            <label for="exampleInputEmail1">Preview Cover</label>
                                            <div class="thumbnail mb-4">
                                                <div class="thumb">
                                                    <a :href="preview_image" data-lightbox="1" target="blank">
                                                        <img :src="preview_image" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Attachment</label>
                                            <input type="file" class="form-control" name="attachment" id="attachment" @change="previewAttachment" accept="application/pdf">
                                            <small v-if="form.previousAttachment" >File sebelumnya : <a :href="form.previousAttachment" target="blank">Lihat</a> </small>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Deskripsi / Syarat & Ketentuan</label>
                                            <ckeditor v-model="form.description" :config="editorConfig"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1 mr-3">Benefit Paket</label>
                                            <div v-for="(row, index) in form.description_other" :key="index">
                                                <div class="d-flex mb-3">
                                                    <input type="text" class="form-control mb-0" v-model="form.description_other[index]" rows="5" placeholder="Masukan benefit"/>
                                                    <div class="flex-shrink-0 ml-2">
                                                        <button type="button" class="btn btn-danger" v-on:click="deleteFormDescOther(index)"><i class="fa fa-trash" data-toggle="tooltip" data-placement="top" data-title="Hapus"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <button type="button" class="btn btn-outline-primary rounded" v-on:click="addFormDescOther()"><i class="fa fa-plus mr-1"></i>Tambah Benefit</button>
                                        </div>
                                        
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'olimpiade_package'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'OlimpiadePackage',
        metaInfo: {
            title: 'Form Paket Olimpiade',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getDataTryout();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label   : 'Tambah',
                form    : {
                    id                  : "",
                    name                : "",
                    type_package        : "",
                    status              : "draft",
                    type                : "olimpiade",
                    price               : "",
                    price_out           : "",
                    active_days         : 365,
                    access_theory       : 1,
                    favorite            : 0,
                    access_exercises    : 1,
                    description         : "",
                    tryout_id           : "",
                    description_other   : [],
                    cover               : "",
                    previousCover       : "",
                    attachment          : "",
                    previousAttachment  : ""
                },
                preview_image   : '',
                list_tryout     : [],
                submitted       : false,
                waiting         : false,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                }
            }
        },
        validations: {
            form: {
                name: {
                    required
                },
                type_package: {
                    required
                },
                active_days: {
                    required,
                    numeric
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('name', this.form.name);
                    formData.append('type_package', this.form.type_package);
                    formData.append('status', this.form.status);
                    formData.append('type', this.form.type);
                    formData.append('price', this.form.price);
                    formData.append('price_out', this.form.price_out);
                    formData.append('active_days', this.form.active_days);
                    formData.append('access_theory', this.form.access_theory);
                    formData.append('access_exercises', this.form.access_exercises);
                    formData.append('description', this.form.description);
                    formData.append('favorite', this.form.favorite);
                    formData.append('description_other', JSON.stringify(this.form.description_other));
                    formData.append('tryout_id', JSON.stringify(this.form.tryout_id));
                    formData.append('cover', this.form.cover);
                    formData.append('attachment', this.form.attachment);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/package/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'olimpiade_package' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/package/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type : 'olimpiade'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse                 = res.data.data;
                            
                            this.form.id                    = getResponse.id;
                            this.form.status                = getResponse.status;
                            this.form.name                  = getResponse.name;
                            this.form.type_package          = getResponse.type_package;
                            this.form.type                  = getResponse.type;
                            this.form.price                 = getResponse.price;
                            this.form.price_out             = getResponse.price_out;
                            this.form.active_days           = getResponse.active_days;
                            this.form.description           = getResponse.description;
                            this.form.description_other     = getResponse.description_other;
                            this.form.access_theory         = getResponse.access_theory;
                            this.form.access_exercises      = getResponse.access_exercises;
                            this.form.tryout_id             = getResponse.list_tryout;                            
                            this.form.favorite              = getResponse.favorite;
                            this.form.cover                 = getResponse.cover;
                            this.preview_image              = getResponse.cover;
                            this.form.attachment            = getResponse.attachment;
                            this.form.previousAttachment    = getResponse.attachment;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'olimpiade_package' });
                                }
                            });
                        }
                    });
                }
            },
            addFormDescOther() {
                this.form.description_other.push('');
            },
            deleteFormDescOther(index) {
                this.form.description_other.splice(index, 1);
            },
            getDataTryout: function () {
                let uri = process.env.VUE_APP_APIHOST + 'admin/package/tryout';

                this.$http.get(uri, {
                    params : {
                        type_tryout : this.form.type
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.list_tryout = getResponse.data;
                    } else {
                        this.list_tryout = [];
                    }
                });
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.cover = file;
                }
            },
            previewAttachment(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            }
        }
    }
</script>