<template>
    <div class="theory-content">
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Ranking Peserta - {{dataOverview.title}}</h4>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Provinsi</label>
                                            <v-select
                                                placeholder="Pilih Provinsi"
                                                label="name"
                                                :options="listProvince"
                                                v-model="province"
                                                @input="getCity"
                                                >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kota</label>
                                            <v-select
                                                placeholder="Pilih Kota"
                                                label="name"
                                                :options="listCity"
                                                v-model="city"
                                            >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-12   mt-3 col-md-3">
                                        <div class="form-group d-grid" style="margin-top:27px">
                                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i class="fa fa-search"></i> Update Ranking Peserta </button>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div v-if="dataFetchRank">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetchRank">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th rowspan="2" style="width:5%" class="text-center">No</th>
                                                    <th rowspan="2">Nama Peserta</th>
                                                    <th colspan="7" class="text-center">
                                                        Skor
                                                    </th>
                                                    <th rowspan="2" class="text-center" style="width:10%">Status</th>
                                                    <th rowspan="2" style="width:20%">Asal Kota</th>
                                                </tr>
                                                <tr>
                                                    <th class="text-center" style="width:6%">TWK</th>
                                                    <th class="text-center" style="width:6%">PG</th>
                                                    <th class="text-center" style="width:6%">TIU</th>
                                                    <th class="text-center" style="width:6%">PG</th>
                                                    <th class="text-center" style="width:6%">TKP</th>
                                                    <th class="text-center" style="width:6%">PG</th>
                                                    <th class="text-center" style="width:6%">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.participant_name}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center" :class="item.twk_scored >= item.twk_pg ? 'text-primary':'text-danger'">{{item.twk_scored}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center text-blue">{{item.twk_pg}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center" :class="item.tiu_scored >= item.tiu_pg ? 'text-primary':'text-danger'">{{item.tiu_scored}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center text-blue">{{item.tiu_pg}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center" :class="item.tkp_scored >= item.tkp_pg ? 'text-primary':'text-danger'">{{item.tkp_scored}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center text-blue">{{item.tkp_pg}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center">{{item.total_scored}}</td>
                                                    <td v-if="item.status == 'open'" class="text-center">
                                                        <span v-if="!item.graduated" class="badge bg-danger">Tidak Lulus</span>
                                                        <span v-if="item.graduated" class="badge bg-primary">Lulus</span>
                                                    </td>
                                                    <td v-if="item.status == 'open'">
                                                        <span v-if="item.province_name">{{item.province_name}}, </span>{{item.city_name}}
                                                    </td>
                                                    <td v-if="item.status == 'lock'" colspan="6" class="text-center">
                                                        Hasil bisa dilihat pada <b>{{item.text_result_date}}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    <strong>Informasi</strong>
                    <p>Ranking Peserta Terakhir di update pada tanggal : <strong>{{last_updated}}</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'MemberCpnsTryoutRank',
        metaInfo: {
            title: 'Ranking Peserta Tryout CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components : {
            vPagination,
            'v-select' : vSelect,
        },
        data() {
            return {
                dataFetch       : true,
                dataFetchRank   : true,
                dataOverview    : [],
                dataList        : [],
                code            : this.$route.params.code,
                listProvince    : [],
                listCity        : [],
                province        : "",
                city            : "",
                keywords        : "",
                pagination      : {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 500,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                last_updated    : ""
            }
        },
        created() {
            this.getOverview();
        },
        methods: {
            getOverview: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/tryout/overview';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code    : this.code,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;                
                        this.dataOverview = res.data.data;
                        this.getProvince();
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            getProvince: function () {
                let uri = 'https://passchendaele.oeltimacreation.com/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.province) {
                    if(this.province.id) {
                        let uri = 'https://passchendaele.oeltimacreation.com/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.province.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/tryout/rank';
                let province_id = "";
                let city_id     = "";

                if (typeof (this.province) !== 'undefined' && this.province !== null) {
                    province_id = this.province.id;
                }

                if (typeof (this.city) !== 'undefined' && this.city !== null) {
                    city_id = this.city.id;
                }

                this.dataFetchRank  = true;                
                this.$http.get(uri,{
                    params : {
                        code        : this.code,
                        province    : province_id,
                        city        : city_id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    if(res.data.owned) {
                        this.emptyState             = false;
                        this.dataFetchRank          = false;                
                        this.dataList               = res.data.data;
                        this.last_updated           = res.data.last_updated;
                        this.pagination.totalPages  = res.data.total_page;

                        if(this.dataList.length < 1) {
                            this.emptyState = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }                    
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_cpns_tryout'});
            },
            goUpgrade: function() {
                this.$router.push({ name: 'member_cpns_package'});
            }
        }
    }
</script>