<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Statistik & Grafik hasil tryout</h4>
                </div>
            </div>
        </div>

        <div v-if="!this.$parent.$parent.dataDetail.premium" class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card border" style="border: 1px solid #f1f5f7; !important">
                    <div class="card-body p-4">
                        <div class="">
                            <div class="text-center mt-1">
                                <img class="image-responsive" src="images/upgrade.svg" style="width:40%"/>
                            </div>
                            <h4 class="font-size-18  mt-3 text-center">
                                Statistik hasil latihan hanya bisa di akses <br/>
                                <b>oleh pengguna premium</b>
                                <br />
                                <button type="button" class="btn btn-outline-primary mt-3" v-on:click="upgrade('olimpiade')">
                                    <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i> Ayo upgrade akun kamu
                                </button>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.$parent.$parent.dataDetail.premium" class="row mb-4" style="margin-top:-10px">            
            <!-- Statistik bukan TKP -->
            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik Nilai
                        </h4>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                            <div class="font-weight-600">
                                {{this.statistic.correct}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                            <div class="font-weight-600">
                                {{this.statistic.wrong}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                            <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                            <div class="font-weight-600">
                                {{this.statistic.empty}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Grafik Nilai
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'MemberOlimpiadeTryoutStatistic',
        metaInfo: {
            title: 'Statistik Dan Hasil Tryout Olimpiade',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#22a843', '#f3113a', '#74788d'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                series      : [],
                statistic   : {
                    correct     : "-",
                    wrong       : "-",
                    empty       : "-",
                    total_data  : "-"
                }
            }
        },
        created() {
            this.getStatistic();
        },
        methods: {
            getStatistic: function() {
                if(this.$parent.$parent.dataDetail.premium == '1') {
                    this.statistic  = this.$parent.$parent.dataDetail.statistic_answer;
                    this.series     = [this.statistic.correct, this.statistic.wrong, this.statistic.empty]
                }
            },
            upgrade: function(type="") {
                if(type == 'olimpiade') {
                    this.$router.push({ name: 'member_olimpiade_package'});
                }
            }
        }
    }
</script>