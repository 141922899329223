<template>
    <div>
        <div class="card border">
            <div class="card-body">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Rangking Peserta</h4>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari Hasil Lomba Peserta berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>
                <div v-if="dataFetch">
                    <p>Sedang mengambil data...</p>
                </div>

                <div v-if="!dataFetch">
                    <div v-if="dataList.length > 0" class="table-responsive mt-3 mb-0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width:5%" class="text-center">Peringkat</th>
                                    <th>Nama Lengkap</th>
                                    <th>Sub Tema</th>
                                    <th class="text-center" style="width:15%">Total Skor</th>
                                    <th style="width:25%">Asal Kota</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataList" :key="index">
                                    <td class="text-center">
                                        {{item.rank}}
                                    </td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.users_papers_category_value}}</td>
                                    <td class="text-center">{{item.total_scored}}</td>
                                    <td>
                                        <span v-if="item.province_name">{{item.province_name}}, </span>{{item.city_name}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3 mb-0">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>

                    <div v-if="emptyState">
                        <empty-state></empty-state>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'UsersMemberResult',
        metaInfo: {
            title: 'Hasil Lomba Peserta',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/package/users-papers-rank';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id       : this.$route.params.id,
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>