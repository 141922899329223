<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data PPPK Tryout</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'pppk_tryout'}">Data PPPK Tryout</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Nama / Judul Tryout</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                            <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul Wajib diisi!</div>    
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Pilih Kategori</label>
                                            <multiselect
                                                v-model="form.pppk_category" tag-placeholder="Add this as new tag"
                                                placeholder="Pilih Kategori" label="name" track-by="id" :options="dataCategory"
                                                :multiple="true" :taggable="true"
                                                :class="{ 'is-invalid': submitted && $v.form.pppk_category.$error }"
                                                ></multiselect>
                                            <div v-if="submitted && !$v.form.pppk_category.required" class="invalid-feedback" style="display:block !important">Kategori wajib diisi!</div>    
                                        </div>

                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'pppk_tryout'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'PppkTryout',
        metaInfo: {
            title: 'Form PPPK Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getCategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                form : {
                    id              : "",
                    title           : "",
                    status          : "draft",
                    pppk_category   : ""
                },
                dataCategory    : [],
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                title: {
                    required
                },
                pppk_category: {
                    required
                }
            }
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/pppk/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('status', this.form.status);
                    formData.append('pppk_category', JSON.stringify(this.form.pppk_category));

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/pppk/tryout/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'pppk_tryout' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/pppk/tryout/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id            = getResponse.id;
                            this.form.title         = getResponse.title;
                            this.form.status        = getResponse.status;
                            this.form.pppk_category = getResponse.pppk_category;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'pppk_tryout' });
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>