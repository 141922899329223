<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Rekap Transaksi</h4>

                                    <div class="page-title-right">
                                        <button class="btn btn-primary" type="button" v-on:click="exportExcel"><i class="fas fa-file-export"></i> Export Ke Excel</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Mulai</label>
                                        <datepicker v-model="start_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal Mulai"></datepicker>
                                    </div>

                                    <div class="form-group col-md-3 mt-3">
                                        <label>Tanggal Selesai</label>
                                        <datepicker v-model="end_date" @input="getData" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal Selesai"></datepicker>
                                    </div>

                                    <div class="form-group col-md-6 mt-3">
                                        <label>Kata Kunci</label>
                                        <div class="input-group">
                                            <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                            <div class="input-group-append">
                                                <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                        class="fa fa-search"></i> Cari </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <p class="text-center">
                                        <b >Periode : {{periode}}</b>
                                    </p>
                                    <hr/>
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nomor Transaksi</th>
                                                    <th>Mobil</th>
                                                    <th>Penyewa / Pelanggan</th>
                                                    <th>Tanggal Sewa</th>
                                                    <th class="text-center">Durasi</th>
                                                    <th style="width:5%" class="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.order_id}}</td>
                                                    <td>{{item.car}}</td>
                                                    <td>{{item.customer}}</td>
                                                    <td>{{item.start_date}}</td>
                                                    <td class="text-center">{{item.long_time_rent}} Hari</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'booking'" class="badge bg-secondary">Di Booking</span>
                                                        <span v-if="item.status == 'rented'" class="badge bg-warning">Sedang Disewa</span>
                                                        <span v-if="item.status == 'returned'" class="badge bg-primary">Di Kembalikan</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr/>
                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';

    export default {
        name: 'Car',
        metaInfo: {
            title: 'Data Transaksi',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            Datepicker,
            vPagination
        },
        data() {
            return {
                id: id,
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                periode     : "",
                start_date  : moment().format('YYYY-MM-01'),
                end_date  : moment().format('YYYY-MM-10'),
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/transactions/reporting';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        start_date  : this.convertDate(this.start_date),
                        end_date    : this.convertDate(this.end_date),
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.periode                = res.data.periode;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            exportExcel: function() {
                const start_date = this.convertDate(this.start_date);
                const end_date = this.convertDate(this.end_date);
                let uri = process.env.VUE_APP_APIHOST+'admin/export/transactions?keywords='+this.keywords+'&start_date='+start_date+'&end_date='+end_date;
                window.open(uri);
            }
        }
    }
</script>