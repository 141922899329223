<template>
    <div class="exercises-content">
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Persiapan Mengerjakan Soal Latihan</h4>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:-10px !important">
                    <div class="col-12">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            <strong>Perhatian !</strong>
                            <ol class="my-0 mt-2">
                                <li> Selalu Pastikan <strong> koneksi internet kamu stabil</strong> ketika sedang mengerjakan soal.</li>
                                <li> Pastikan <strong>tidak ada aktivitas login ke akun kamu (pada perangkat lain)</strong> saat sedang mengerjakan soal. </li>
                                <li> Gunakan <strong>browser versi terbaru</strong> agar website dapat diakses dengan lancar tanpa masalah. </li>
                            </ol>
                        </div>

                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Informasi Latihan</h4>
                                <p class="card-title-desc">Berikut ini adalah informasi latihan yang akan kamu kerjakan : </p>
                                <table class="table table-centered mb-0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clipboard-file-outline"></i> Nama Latihan</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.title}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clipboard-list-outline"></i> Jumlah Soal</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.total_questions}} Soal</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clock-check-outline"></i> Durasi Waktu</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.timer}} Menit</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr/>
                                <div class="button-items mt-2">
                                    <button v-on:click="confirmStartExercises" type="button" class="btn btn-primary waves-effect waves-light"><i class="mdi mdi-book-edit-outline"></i> Mulai Mengerjakan</button>
                                    <button v-on:click="goBack" type="button" class="btn btn-outline-light waves-effect">Kembali</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberCpnsExercisesDetail',
        metaInfo: {
            title: 'Lihat Latihan CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                dataFetch       : true,
                dataDetail      : [],
                code            : this.$route.params.code,
                token           : (Math.random() + 1).toString(36).substring(2)
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/exercises/overview';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code    : this.code,
                    }
                }).then(res => {
                    if(res.data.premium) {
                        if(res.data.status) {
                            this.dataFetch  = false;                
                            this.dataDetail = res.data.data;

                            if(this.dataDetail.token) {
                                this.token = this.dataDetail.token;
                                this.startTryout();                                
                            } else {
                                this.dataFetch  = false;
                            }
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goBack();
                                }
                            });
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Menu ini hanya bisa diakses oleh pengguna premium',
                            text: 'Ayo upgrade akun kamu untuk mendapatkan benefit yang lebih baik',
                            confirmButtonText: 'Upgrade Akun'
                        }).then((result) => {
                            if (result.value) {
                                this.goUpgrade();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_cpns_exercises'});
            },
            goUpgrade: function() {
                this.$router.push({ name: 'member_cpns_package'});
            },
            confirmStartExercises: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    html: 'Apakah kamu yakin akan mulai mengerjakan soal?',
                    confirmButtonText: 'Mulai',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.startTryout();
                    }
                });
            },
            startTryout: function() {
                this.$router.push({ name: 'member_cpns_exercises_start' , params: {
                    code    : this.code,
                    token   : this.token
                }});
            }
        }
    }
</script>