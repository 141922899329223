<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Data Latihan Kedinasan</h4>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kategori</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="category_id" v-on:change="getData">
                                                <option value="">Lihat Semua</option>
                                                <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-8 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kata kunci</label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                                                <div class="input-group-append">
                                                    <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                            class="fa fa-search"></i> Cari </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top:-10px !important" class="row" v-if="dataFetch">
                    <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                        <div class="card d-block border rounded-lg p-3 m-t-10">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar text-center">
                                                <div class="sb-sm mb-2"></div>
                                                <hr/>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                                <div class="sb-lg"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top:-10px !important" class="row" v-if="!dataFetch">
                    <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                        <div class="card border">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title text-short">
                                    <a href="#" v-on:click="goToView(item.code)">{{item.title}}</a>
                                </h4>
                                <hr/>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Kategori</div>
                                    <div class="font-weight-600 text-right text-short" :title="item.category">
                                        {{item.category}}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Durasi</div>
                                    <div class="font-weight-600 text-right">
                                        {{item.timer}} Menit
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                    <div class="mr-3">Jumlah Soal</div>
                                    <div class="font-weight-600 text-right">
                                        {{item.total_questions}} Soal
                                    </div>
                                </div>
                                <div class="d-grid mb-0">
                                    <button v-on:click="goToView(item.code)" type="button" class="btn btn-primary rounded align-self-end">
                                        <i class="mdi mdi-book-edit-outline"></i> Kerjakan Latihan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                        <hr/>
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>

                    <div v-if="emptyState">
                        <div class="card border p-3">
                            <empty-state></empty-state>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MemberKedinasanExercises',
        metaInfo: {
            title: 'Data Latihan Kedinasan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch    : true,
                dataCategory : [],
                dataList     : [],
                keywords     : "",
                category_id  : "",
                pagination   : {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getCategory();
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'general/list/category';

                this.$http.get(uri).then(res => {
                    this.dataCategory = res.data.data;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/kedinasan/exercises/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        category_id : this.category_id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    if(res.data.premium) {
                        this.emptyState             = false;
                        this.dataFetch              = false;                
                        this.dataList               = res.data.data;
                        this.pagination.totalPages  = res.data.total_page;

                        if(this.dataList.length < 1) {
                            this.emptyState = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Menu ini hanya bisa diakses oleh pengguna premium',
                            text: 'Ayo upgrade akun kamu untuk mendapatkan benefit yang lebih baik',
                            confirmButtonText: 'Upgrade Akun',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.goUpgrade();
                            }
                        });
                    }
                });
            },
            goToView: function(code="") {
                this.$router.push({ name: 'member_kedinasan_exercises_overview' , params: {
                    code : code
                }});
            },
            goUpgrade: function() {
                this.$router.push({ name: 'member_kedinasan_package'});
            }
        }
    }
</script>