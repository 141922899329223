<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">
                                        <a href="javascript:void(0)" v-on:click="goToMyPackage"><i class="fa fa-arrow-left mr-2"></i></a> Riwayat Pengumpulan File
                                    </h4>
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-1">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Keterangan</th>
                                                    <th>Tanggal Upload</th>
                                                    <th>Tanggal Review</th>
                                                    <th>Status</th>
                                                    <th class="text-center" style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        Upload ke {{ totalData-((index) + (pagination.currentPage - 1) * pagination.limit) }}                                                        
                                                    </td>
                                                    <td>{{item.text_upload}}</td>
                                                    <td>{{item.text_review}}</td>
                                                    <td>
                                                        <span class="badge bg-warning" v-if="item.status == 'rejected'">Direvisi</span>
                                                        <span class="badge bg-success" v-if="item.status == 'accepted'">Dikonfirmasi</span>
                                                        <span class="badge bg-info" v-if="item.status == 'waiting'">Menunggu Konfirmasi</span>
                                                    </td>
                                                    <td class="text-center"><button v-on:click="goFeedBack(item.id)" class="btn btn-primary btn-sm" type="button"> Lihat Feedback</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MemberUsersPapersHistory',
        metaInfo: {
            title: 'Riwayat Pengumpulan File',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                totalData   : 0,
                code        : this.$route.params.code,
                id          : this.$route.params.id,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 100,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'users/papers/history';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code     : this.code,
                        id       : this.id,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.totalData              = res.data.total_data;
                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goToMyPackage: function() {
                this.$router.push({ name: 'member_esai_package_overview' , params: {
                    code : this.code
                }});
            },
            goFeedBack: function(detail_id="") {
                this.$router.push({ name: 'member_esai_package_history_detail' , params: {
                    code : this.code,
                    id : this.id,
                    detail_id : detail_id
                }});
            }
        }
    }
</script>