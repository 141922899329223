<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">

                <div v-if="dataFetch" class="row ">
                    <div class="card border">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar">
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-md sb-thin mb-4"></div>

                                                <div class="sb-md sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch" class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">
                                        <a href="javascript:void(0)" onclick="window.history.go(-1); return false;" class="mdi mdi-arrow-left mr-2"></a> {{dataArticle.name}}
                                    </h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item active">{{dataArticle.time_since}}</li>
                                        </ol>
                                    </div>
                                </div>
                                <hr style="margin-top:-5px"/>
                                <div class="card-title-desc" v-html="dataArticle.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'ArticlePageDetail',
        metaInfo: {
            title: 'Halaman Berita dan Pengumuman',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getDataBySlug(this.$route.params.code);
        },
        data() {
            return {
                dataFetch   : true,
                dataArticle : []
            }
        },
        methods: {
            getDataBySlug: function(code) {
                if (typeof (code) !== 'undefined' && code !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'general/detail/article';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            code : code
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data;
                            this.dataArticle    = getResponse;
                            this.dataFetch      = false;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'dashboard' });
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>