<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Nilai & Grafik Hasil Tryout</h4>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:-10px">
            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">Nilai & Passing Grade</h4>
                        <div class="table-responsive mb-3">
                            <table class="table mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th class="text-center">Skor</th>
                                        <th class="text-center">PG</th>
                                        <th class="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Nilai TWK</th>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.twk_scored}}</td>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.twk_pg}}</td>
                                        <td class="text-center">
                                            <span v-if="this.$parent.$parent.dataDetail.twk_scored >= this.$parent.$parent.dataDetail.twk_pg" class="badge bg-success">Lulus</span>
                                            <span v-if="this.$parent.$parent.dataDetail.twk_scored < this.$parent.$parent.dataDetail.twk_pg" class="badge bg-danger">Tidak Lulus</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Nilai TIU</th>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.tiu_scored}}</td>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.tiu_pg}}</td>
                                        <td class="text-center">
                                            <span v-if="this.$parent.$parent.dataDetail.tiu_scored >= this.$parent.$parent.dataDetail.tiu_pg" class="badge bg-success">Lulus</span>
                                            <span v-if="this.$parent.$parent.dataDetail.tiu_scored < this.$parent.$parent.dataDetail.tiu_pg" class="badge bg-danger">Tidak Lulus</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Nilai TKP</th>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.tkp_scored}}</td>
                                        <td class="text-center">{{this.$parent.$parent.dataDetail.tkp_pg}}</td>
                                        <td class="text-center">
                                            <span v-if="this.$parent.$parent.dataDetail.tkp_scored >= this.$parent.$parent.dataDetail.tkp_pg" class="badge bg-success">Lulus</span>
                                            <span v-if="this.$parent.$parent.dataDetail.tkp_scored < this.$parent.$parent.dataDetail.tkp_pg" class="badge bg-danger">Tidak Lulus</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <small>Keterangan :
                            <ul class="mt-2">
                                <li>TWK <i class="mdi mdi-arrow-right"></i> Tes Wawasan Kebangsaan</li>
                                <li>TIU <i class="mdi mdi-arrow-right"></i> Tes Intelejensia Umum</li>
                                <li>TKP <i class="mdi mdi-arrow-right"></i> Tes Karakteristik Pribadi</li>
                                <li>PG <i class="mdi mdi-arrow-right"></i> Passing Grade</li>
                            </ul>
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">Grafik Hasil Tryout</h4>
                        <div>
                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Statistik Hasil Tryout</h4>
                </div>
            </div>
        </div>

        <div v-if="!this.$parent.$parent.dataDetail.premium" class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card border" style="border: 1px solid #f1f5f7; !important">
                    <div class="card-body p-4">
                        <div class="">
                            <div class="text-center mt-1">
                                <img class="image-responsive" src="images/upgrade.svg" style="width:40%"/>
                            </div>
                            <h4 class="font-size-18  mt-3 text-center">
                                Statistik hasil tryout hanya bisa di akses <br/>
                                <b>oleh pengguna premium</b>
                                <br />
                                <button type="button" class="btn btn-outline-primary mt-3" v-on:click="upgrade('cpns')">
                                    <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i> Ayo upgrade akun kamu
                                </button>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.$parent.$parent.dataDetail.premium" class="row mb-4" style="margin-top:-10px">
            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik & Grafik Nilai<br/>
                            Tes Wawasan Kebangsaan (TWK)
                        </h4>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                            <div class="font-weight-600">
                                {{this.statistic_twk.correct}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                            <div class="font-weight-600">
                                {{this.statistic_twk.wrong}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                            <div class="font-weight-600">
                                {{this.statistic_twk.empty}}
                            </div>
                        </div>

                        <h4 class="card-title mt-4 text-center">
                            Grafik Nilai<br/>
                            Tes Wawasan Kebangsaan (TWK)
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptionsPie" :series="seriesTWK"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik & Grafik Nilai<br/>
                            Tes Wawasan Kebangsaan (TIU)
                        </h4>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                            <div class="font-weight-600">
                                {{this.statistic_tiu.correct}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                            <div class="font-weight-600">
                                {{this.statistic_tiu.wrong}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                            <div class="font-weight-600">
                                {{this.statistic_tiu.empty}}
                            </div>
                        </div>

                        <h4 class="card-title mt-4 text-center">
                            Grafik Nilai<br/>
                            Tes Wawasan Kebangsaan (TIU)
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptionsPie" :series="seriesTIU"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="card border h-100">
                    <div class="card-body">
                        <h4 class="card-title text-center mb-4">
                            Statistik Nilai<br/>
                            Tes Karakteristik Pribadi (TKP)
                        </h4>

                        <div v-for="(item, index) in statistic_tkp.list_data" :key="index" class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">{{item.name}}</div>
                            <div class="font-weight-600">
                                {{item.value}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="card border h-100">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Grafik Nilai<br/>
                            Tes Karakteristik Pribadi (TKP)
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptionsTKP" :series="seriesTKP"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-md-12 mt-3">
                <div class="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    Keterangan : Statistik hasil tryout bisa di akses hingga tanggal <strong>{{this.$parent.$parent.dataDetail.text_expired_access}}</strong>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'MemberCpnsTryoutStatistic',
        metaInfo: {
            title: 'Statistik Dan Hasil Tryout CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                chartOptions: {
                    colors: ['#0b78e9', '#22a843', '#efa50a'],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                },
                series: [
                    {
                        name: ['Nilai TWK'],
                        data: [this.$parent.$parent.dataDetail.twk_scored]
                    },
                    {
                        name: 'Nilai TIU',
                        data: [this.$parent.$parent.dataDetail.tiu_scored]
                    },
                    {
                        name: 'Nilai TKP',
                        data: [this.$parent.$parent.dataDetail.tkp_scored]
                    }
                ],
                chartOptionsPie: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#22a843', '#f3113a', '#74788d'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                seriesTWK: [],
                seriesTIU: [],
                seriesTKP: [],
                statistic_twk : {
                    correct     : "-",
                    wrong       : "-",
                    empty       : "-",
                    total_data  : "-"
                },
                statistic_tiu : {
                    correct     : "-",
                    wrong       : "-",
                    empty       : "-",
                    total_data  : "-"
                },
                statistic_tkp   : {
                    labels : [],
                    list_data : [],
                    series : [],
                    total_questions : []
                },
                chartOptionsTKP: []
            }
        },
        created() {
            this.getStatistic();
        },
        methods: {
            getStatistic: function() {
                if(this.$parent.$parent.dataDetail.premium == '1') {
                    this.statistic_twk  = this.$parent.$parent.dataDetail.statistic_twk;
                    this.seriesTWK = [this.statistic_twk.correct, this.statistic_twk.wrong, this.statistic_twk.empty]

                    this.statistic_tiu  = this.$parent.$parent.dataDetail.statistic_tiu;
                    this.seriesTIU = [this.statistic_tiu.correct, this.statistic_tiu.wrong, this.statistic_tiu.empty]

                    this.statistic_tkp  = this.$parent.$parent.dataDetail.statistic_tkp;
                    this.seriesTKP      = this.statistic_tkp.series;

                    this.chartOptionsTKP =  {
                        chart: {
                            type: 'pie',
                        },
                        colors: ['#22a843', '#0b78e9', '#efa50a', '#A27B5C', '#DCD7C9', '#74788d'],
                        labels: this.statistic_tkp.labels,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    }
                }
            },
            upgrade: function(type="") {
                if(type == 'cpns') {
                    this.$router.push({ name: 'member_cpns_package'});
                }
            }
        }
    }
</script>