<template>
    <div>
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div v-if="emptyState" class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Informasi Invoice</h4>
                                </div>

                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!emptyState" class="row justify-content-center">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card border" style="border: 1px solid #f1f5f7; !important">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="text-center mt-1">
                                        <img class="image-responsive" src="images/payment-mobile.png" style="width:30%" />
                                    </div>
                                    <h4 class="font-size-18 text-center">
                                        Transaksi pembelian kamu sedang dalam proses<br/>
                                        Klik tombol dibawah untuk mendapatkan informasi lebih lanjut
                                        <hr/>
                                        <button type="button" class="btn btn-outline-primary" v-on:click="goToDetail">
                                            <i class="mdi mdi-credit-card-check-outline"></i> Lihat Detail
                                        </button>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberInvoiceInfo',
        metaInfo: {
            title: 'Informasi Tagihan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : true,
                dataDetail  : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/invoice/info';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        transaction_id : this.$route.params.transaction_id,
                    }
                }).then(res => {
                    this.dataFetch  = false;
                    if(res.data.status) {
                        this.dataDetail = res.data.data;
                    } else {
                        this.emptyState = true;
                    }
                });
            },
            goToDetail: function() {
                this.$router.push({ name: 'member_invoice_detail' , params: {
                    invoice : this.dataDetail.invoice
                }});
            }
        }
    }
</script>