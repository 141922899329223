<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Statistik & Hasil Tryout</h4>
                </div>
            </div>
        </div>

        <div class="row mb-2" style="margin-top:-10px" v-for="(item, indexOne) in this.$parent.$parent.dataDetail.list_component" :key="indexOne">
            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik Nilai<br/><b>{{item.name}}</b>
                        </h4>
                        <hr />
                        <div class="d-block mt-3">
                            <div v-if="item.status == 'Lulus'" class="alert alert-success text-center">
                                <b>Selamat nilai kamu memenuhi passing grade!</b>
                            </div>

                            <div v-if="item.status != 'Lulus'" class="alert alert-danger text-center">
                                <b>Nilai Kamu belum memenuhi passing grade</b>
                            </div>
                        </div>
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <div class="mr-3 font-weight-600" style="width:60%">Skor Kamu</div>
                                <div v-if="item.status == 'Lulus'" class="font-weight-600 text-right" style="width:10%;color:green" >
                                    {{item.scored}}
                                </div>

                                <div v-if="item.status != 'Lulus'" class="font-weight-600 text-right" style="width:10%;color:red" >
                                    {{item.scored}}
                                </div>
                            </div>
                        </div>
                        <div class="d-block mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <div class="mr-3 font-weight-600" style="width:60%">Prediksi Passing Grade</div>
                                <div class="font-weight-600 text-right" style="width:10%">
                                    {{item.passing_grade}}
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <apexchart type="bar" :options="chartOptions" :series="item.seriesPassingGrade"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik Jawaban<br/><b>{{item.name}}</b>
                        </h4>
                        <hr />
                        <div class="d-block mt-4">
                        </div>
                        <div v-for="(staticData, index) in item.statistic" :key="index" class="d-block mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2" :class="staticData.name != 'Tidak Mengisi' ? 'border-bottom':''">
                                <div class="mr-3 font-weight-600" style="width:60%">{{staticData.name}}</div>
                                <div class="font-weight-600 text-right" style="width:10%;" >
                                    {{staticData.value}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <apexchart type="pie" :options="item.grafik" :series="item.series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>        
</template>

<script>
    export default {
        name: 'MemberPppkTryoutStatistic',
        metaInfo: {
            title: 'Statistik Dan Hasil Tryout PPPK',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                chartOptions: {
                    colors: ['#22a843', '#0b78e9', '#efa50a', '#A27B5C', '#DCD7C9', '#74788d'],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                }
            }
        }
    }
</script>