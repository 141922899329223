<template>
    <div>
        <div class="page-content" v-if="dataFetch">
            <p class="text-center">Loading...</p>
        </div>
        <div class="page-content" v-if="!dataFetch">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Informasi Lomba</h4>

                                    <div class="page-title-right">
                                        <router-link class="btn btn-outline-primary rounded" :to="{name : 'esai_package'}">Kembali</router-link>
                                    </div>
                                </div>
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Nama Paket</div>
                                        <div class="font-weight-600">
                                            {{dataDetail.name}}
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-0">
                                        <div class="mr-3">Jumlah Peserta Terdaftar</div>
                                        <div class="font-weight-600">
                                            {{dataDetail.total_subscriber}} Orang
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>

                        <div class="button-items mb-2">
                            <button v-on:click="changeTab('UsersMembers')" type="button" :class="currentTabComponent == 'UsersMembers' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded"><i class="fas fa-users"></i> Data Peserta</button>
                            <button v-on:click="changeTab('ResultMembers')" type="button" :class="currentTabComponent == 'ResultMembers' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded"><i class="fas fa-file"></i> Hasil Lomba Peserta</button>
                            <button v-on:click="changeTab('RankMembers')" type="button" :class="currentTabComponent == 'RankMembers' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded"><i class="mdi mdi-trophy"></i> Ranking Peserta</button>
                        </div>

                        <vue-page-transition>
                            <keep-alive>
                                <component :is="currentTabComponent"></component>
                            </keep-alive>
                        </vue-page-transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import UsersMembers from "@/views/administrator/esai/package/UsersMembers.vue"
    import ResultMembers from "@/views/administrator/esai/package/ResultMembers.vue"
    import RankMembers from "@/views/administrator/esai/package/RankMembers.vue"

    export default {
        name: 'ResultPackage',
        metaInfo: {
            title: 'Data Peserta',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination,
            UsersMembers,
            ResultMembers,
            RankMembers
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                dataDetail  : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                submitted   : false,
                waiting     : false,
                currentTabComponent     : 'UsersMembers',                
            }
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/package/detail-v2';

                    this.dataFetch  = true;
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type : 'esai'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataFetch  = false;
                            this.dataDetail = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'esai_package' });
                                }
                            });
                        }
                    });
                }
            },
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            }
        }
    }
</script>