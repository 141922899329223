<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Beli Paket Kedinasan</h4>
                        </div>
                    </div>
                </div>

                <div v-if="dataFetch" class="row">
                    <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                        <div class="card d-block border rounded-lg p-3 m-t-10">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar text-center">
                                                <div class="sb-sm mb-2"></div>
                                                <hr/>
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                                <div class="sb-lg"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch">
                    <div class="row" v-if="dataList.length > 0">
                        <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                            <div class="card d-block border rounded-lg">
                                <img v-if="!item.cover" :src="'images/default-cover.png'" class="card-img-top" alt="...">
                                <img v-if="item.cover" :src="item.cover" class="card-img-top" alt="...">
                                <div class="card-body">
                                    <div v-if="item.favorite" class="ribbon ribbon-top-right"><span>Favorit <i class="mdi mdi-thumb-up-outline"></i></span></div>

                                    <h3 class="mb-sm-0 text-center"><b>{{item.name}}</b></h3>
                                    <hr/>

                                    <div class="text-center">
                                        <h4><s v-if="item.price_out" class="text-muted">{{item.text_price_out}}</s></h4>
                                        <h2><b>{{item.text_price}}</b></h2>
                                    </div>

                                    <div class="d-grid mt-3" v-if="!item.subscribed">
                                        <button data-toggle="modal" data-target="#modalBuyPackage" v-on:click="buyPackage(item.id)" type="button" class="btn btn-primary btn-block"><i class="mdi mdi-cart-variant"></i> Beli Paket</button>
                                    </div>

                                    <div class="d-grid mt-3" v-if="item.subscribed">
                                        <button type="button" disabled class="btn btn-outline-primary btn-block"><i class="mdi mdi-briefcase-check-outline"></i> Sedang Berlangganan</button>
                                    </div>

                                    <hr/>
                                    <div class="plan-features mt-4 mb-3">
                                        <h5 class="text-center font-size-15 mb-3">Benefit yang didapatkan</h5>
                                        <table style="width: 100%;">
                                            <tr v-for="(itemDetail, indexDetail) in item.description_other" :key="indexDetail">
                                                <td
                                                    style="vertical-align: top; width: 8%; color: rgb(34, 168, 67) !important;">
                                                    <i class="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i></td>
                                                <td v-html="itemDetail"></td>
                                            </tr>
                                        </table>                                                                        
                                    </div>

                                    <div class="alert alert-warning text-center" role="alert" v-if="!item.subscribed">
                                        Dapatkan <strong>extra potongan harga</strong> dengan kode promo / referral.
                                    </div>

                                    <div class="alert alert-primary text-center" role="alert" v-if="item.subscribed">
                                        Berlaku hingga<br/>
                                        <strong>{{item.expired_at}}</strong>.
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>

                    <div v-if="emptyState">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card d-block border rounded-lg">
                                    <div class="card-body">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalBuyPackage" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="mdi mdi-cart-variant"></i> Beli Paket</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                            <div>
                                <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Paket</div>
                                <div style="font-size:20px" class="d-block h6 font-weight-600 mb-2">{{form.name}}</div>
                                <div class="d-block h6 font-weight-600">Masa Aktif {{form.active_days}} Hari</div>
                            </div>
                        </div>
                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Harga</div>
                                <div class="font-weight-600">
                                    <s v-if="form.price_out" class="text-muted mr-2">{{form.text_price_out}}</s>
                                    <b>{{form.text_price}}</b>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Potongan Harga</div>
                                <div class="font-weight-600">
                                    <span v-if="!form.discount_voucher">Rp. 0</span>
                                    <span style="color:#43ab43" v-if="form.discount_voucher">{{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kode Voucher / Referral<br/>
                                    <small>Gunakan Kode untuk mendapatkan potongan harga</small>
                                </div>
                                <div class="font-weight-600">
                                    <a v-if="!form.fix_voucher" v-on:click="showVoucher" href="javascript:void(0)">Masukan Kode</a>
                                    <span class="badge badge-info" v-if="form.fix_voucher" href="javascript:void(0)">{{form.fix_voucher}} - {{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="input-group mb-3 m-t-10" v-if="showInputVoucher">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Kode Referral / Voucher ..." v-model="form.voucher" v-on:keyup.enter="checkVoucher">
                                <div class="input-group-append">
                                    <button v-on:click="checkVoucher" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">Gunakan Kode</button>
                                </div>
                            </div>

                            <h5>Total Harga :
                                <span class="float-right">
                                    <s class="mr-2" style="color:red" v-if="form.total_price_out">
                                        {{form.total_price_out}}</s>
                                    <b>{{form.total_price}}</b>
                                </span>
                            </h5>
                            <div class="d-grid mt-3">                            
                                <button :disabled="processFetch" v-on:click="checkout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="mdi mdi-cart-variant"></i>
                                    {{ processFetch ? 'Sedang membuat invoice ...' : 'Beli Paket' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberKedinasanPackage',
        metaInfo: {
            title: 'Beli Paket Kedinasan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                dataFetch           : true,
                emptyState          : true,
                dataList            : [],
                showInputVoucher    : false,
                processFetch        : false,
                form                : {
                    id : '',
                    name : '',
                    text_price_out : '',
                    text_price : '',
                    active_days : '',
                    voucher : '',
                    price : 0,
                    fix_voucher : '',
                    discount_voucher : 0,
                    total_price : 0,
                    total_price_out : 0,
                    type : '',
                }
            }
        },
        created() {
            this.getPackage();
        },
        methods: {
            getPackage: function() {
                this.dataFetch = true;
                let uri = process.env.VUE_APP_APIHOST+'member/package/list';
                this.$http.get(uri, {
                    params : {
                        type : 'kedinasan'
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            showVoucher: function() {
                this.showInputVoucher = true;
            },
            buyPackage: function(id) {
                var item    = this.dataList.find(item => item.id === id);

                this.form.id                = item.id;
                this.form.name              =  item.name;
                this.form.text_price_out    = item.text_price_out;
                this.form.text_price        = item.text_price;
                this.form.price             = item.price;
                this.form.total_price       = item.text_price;
                this.form.active_days       = item.active_days;
                this.form.fix_voucher       = '';
                this.form.discount_voucher  = 0;
                this.form.total_price_out   = 0;
                this.form.type              = item.type;
            },
            checkVoucher: function() {
                let formData    = new FormData();
                formData.append('category', this.form.type);
                formData.append('voucher', this.form.voucher);
                formData.append('id', this.form.id);
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/check-voucher', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    let getResponse             = response.data.data;
                                    let total_price             = getResponse.text_total_price;
                                    let total_price_out         = getResponse.text_before_price;

                                    this.form.fix_voucher       = this.form.voucher;
                                    this.form.discount_voucher  = getResponse.text_discount;
                                    this.form.total_price       = total_price;
                                    this.form.total_price_out   = total_price_out;
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    return;
                });
            },
            rupiahFormat: function(bilangan) {                        
                var	reverse = bilangan.toString().split('').reverse().join(''),
                ribuan 	= reverse.match(/\d{1,3}/g);
                ribuan	= ribuan.join('.').split('').reverse().join('');
                return ribuan;
            },
            checkout: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Pembelian',
                    text: 'Apakah kamu yakin akan melanjutkan proses pembelian ?',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if(result.value) {
                        this.processFetch = true;
                        let formData    = new FormData();
        
                        formData.append('category', this.form.type);
                        formData.append('voucher', this.form.voucher);
                        formData.append('id', this.form.id);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/checkout', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            document.getElementById("btnCloseModal").click();
                                            this.$router.push({
                                                name: 'member_invoice_detail',
                                                params: {
                                                    invoice : response.data.data.invoice
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                    
                                    let total_price             = response.data.data.text_total_price;
                                    this.form.total_price       = total_price;
                                    this.form.total_price_out   = 0;
                                    this.form.discount_voucher  = 0;
                                }

                                this.processFetch = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.processFetch = false;
                            return;
                        });
                    }
                });
            }
        }
    }
</script>
