<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Dashboard</h4>
                        </div>

                        <div v-if="dataFetch && fetchDashboard" class="card border">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="mr-3 w-100">
                                        <div class="d-flex align-items-center">
                                            <div class="w-100">
                                                <div class="skeleton-bar">
                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-md sb-thin mb-4"></div>

                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-4"></div>

                                                    <div class="sb-md sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-4"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!dataFetch && !fetchDashboard">
                            <div v-if="users.role == 'administrator'">
                                <div class="row">
                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-green border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Member</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_members}} Orang</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-users fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-blue border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Pendapatan</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_incomes}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-coins fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-yellow border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Transaksi</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_transactions}} Data</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-database fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-pink border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total Berita & Pengumuman</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_news}} Data</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-newspaper fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8 mb-2">
                                        <div class="card border h-100">
                                            <div class="card-body">
                                                <div
                                                    class="page-title-box d-sm-flex align-items-center justify-content-between">
                                                    <h4 class="mb-sm-0">Statistik Transaksi Per Tahun - {{this.years.id}}</h4>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label for="exampleFormControlSelect1">Cari data berdasarkan tahun</label>
                                                    <v-select
                                                        placeholder="Pilih Tahun"
                                                        label="name"
                                                        :options="dataYear"
                                                        v-model="years"
                                                        @input="getStatistic"
                                                        >                                    
                                                    </v-select>
                                                </div>
                                                <hr/>
                                                <div v-if="fetchStatistic" class="mt-3">
                                                    <p>Sedang mengambil data...</p>
                                                </div>

                                                <div  v-if="!fetchStatistic" id="chart" class="mt-3">
                                                    <div v-if="statistic.length <= 0">
                                                        <empty-state></empty-state>
                                                    </div>
                                                    
                                                    <div v-if="statistic.length > 0">
                                                        <apexchart type="line" height="350" :options="chartOptions"
                                                            :series="statistic"></apexchart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-2">
                                        <div class="card border">
                                            <div class="card-body">
                                                <h4 class="card-title">Berita dan Pengumuman</h4>

                                                <div v-if="dataArticle.length > 0" class="pe-3" data-simplebar="init" style="max-height: 287px;">
                                                    <div class="simplebar-wrapper" style="margin: 0px -16px 0px 0px;">
                                                        <div class="simplebar-height-auto-observer-wrapper">
                                                            <div class="simplebar-height-auto-observer"></div>
                                                        </div>
                                                        <div class="simplebar-mask">
                                                            <div class="simplebar-offset" style="right: -17px; bottom: 0px;">
                                                                <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                                                    <div class="simplebar-content" style="padding: 0px 16px 0px 0px;">
                                                                        <router-link v-for="(item, index) in dataArticle" :key="index" :to=" {name : 'page_article_detail', params: {code : item.code}}" class="text-body d-block">
                                                                            <div class="d-flex py-3">
                                                                                <div class="flex-shrink-0 me-3 align-self-center">
                                                                                    <div class="avatar-xs">
                                                                                        <span class="avatar-title rounded-circle bg-soft-primary text-success">
                                                                                            <i :class="item.type == 'news' ? 'mdi mdi-newspaper':'mdi mdi-bullhorn-outline'"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="flex-grow-1 overflow-hidden">
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'news'">
                                                                                        Berita
                                                                                    </h5>
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'announcement'">
                                                                                        Pengumuman
                                                                                    </h5>
                                                                                    <p class="text-truncate mb-0">
                                                                                        {{item.name}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="flex-shrink-0 font-size-13">
                                                                                    {{item.time_since}}
                                                                                </div>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="simplebar-placeholder" style="width: auto; height: 369px;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                                                        <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                                                        <div class="simplebar-scrollbar"
                                                            style="height: 223px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                                                    </div>
                                                </div>

                                                <div  v-if="dataArticle.length <= 0">
                                                    <empty-state></empty-state>
                                                </div>
                                            </div>
                                            <!-- end card-body -->
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="users.role == 'member'">
                                <div v-if="fetchSubscriber" class="alert alert-success alert-dismissible fade show" role="alert">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!fetchSubscriber" class="alert alert-success alert-dismissible fade show" role="alert">
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    Hallo <strong>{{users.name}} !</strong> 👋<br/>
                                    <div v-if="dataSubscriber.length > 0">
                                        Saat ini kamu sedang berlangganan :<br/>

                                        <ul class="mb-0 mt-2">
                                            <li v-for="(item, index) in dataSubscriber" :key="index">
                                                <b>Paket {{item.type}} <i class="mdi mdi-arrow-right"></i> {{item.package}}</b><br/>
                                                Berlaku hingga <b>{{item.expired_at}}</b>
                                            </li>
                                        </ul>
                                    </div>

                                    <div v-if="dataSubscriber.length <= 0">
                                        Selamat datang di aplikasi <b>Gubuk Sains Prima.</b> 
                                        <br/>Saat ini kamu belum berlangganan apapun di aplikasi <b>Gubuk Sains Prima.</b><br/>Ayo <b>Upgrade Akun</b> kamu ke <b>Premium</b> untuk mendapatkan benefit yang lebih baik!
                                        <ul class="mb-0 mt-1">
                                            <li><router-link :to="{ name : 'member_cpns_package'}">Upgrade Akun CPNS</router-link></li>
                                            <li><router-link :to="{ name : 'member_kedinasan_package'}">Upgrade Akun Kedinasan</router-link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div v-if="dataWorkingTryout.length > 0" class="alert alert-warning alert-dismissible fade show" role="alert">
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    Hallo <strong>{{users.name}} !</strong> ⚠️<br/>
                                    Ada tryout yang belum kamu selesaikan : <br/>
                                    <ul class="mb-0 mt-2">
                                        <li v-for="(item, index) in dataWorkingTryout" :key="index">
                                            <b>Tryout {{item.type}} <i class="mdi mdi-arrow-right"></i> {{item.title}}</b> dengan sisa waktu : <b>{{item.remaining_time}}</b> lagi.
                                            <a href="javascript:void(0)" v-on:click="continueTryout(item.type, item.code)"> Lanjut mengerjakan</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="row">
                                    <div class="col-xl-4 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-green border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Paket Tersedia</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_package}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-briefcase fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-blue border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Tryout CPNS</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_tryout_cpns}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-file-contract fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-yellow border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Tryout Kedinasan</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_tryout_kedinasan}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-file-contract fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8 mb-2">
                                        <div class="card border h-100">
                                            <div class="card-body">
                                                <div
                                                    class="page-title-box d-sm-flex align-items-center justify-content-between">
                                                    <h4 class="mb-sm-0">Statistik Nilai Tryout - {{this.category.name}}</h4>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label for="exampleFormControlSelect1">Cari data berdasarkan kategori </label>
                                                    <v-select
                                                        placeholder="Pilih Kategori"
                                                        label="name"
                                                        :options="dataCategory"
                                                        v-model="category"
                                                        @input="getStatisticTryout"
                                                        >                                    
                                                    </v-select>
                                                </div>
                                                <hr/>
                                                <div v-if="fetchStatistic" class="mt-3">
                                                    <p>Sedang mengambil data...</p>
                                                </div>

                                                <div  v-if="!fetchStatistic" id="chart" class="mt-3">
                                                    <div v-if="!statisticTryout">
                                                        <empty-state></empty-state>
                                                    </div>
                                                    
                                                    <div v-if="statisticTryout">
                                                        <apexchart v-if="series[0].data.length > 1" type="line" height="350" :options="chartOptions"
                                                            :series="series"></apexchart>
                                                            <p v-if="series[0].data.length <= 1" class="text-center">
                                                                Untuk menampilkan <b>statistik nilai tryout {{this.category.name}}</b><br/>
                                                                minimal kamu harus <b>mengerjakan 2x</b> tryout terlebih dahulu
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-2">
                                        <div class="card border">
                                            <div class="card-body">
                                                <h4 class="card-title">Berita dan Pengumuman</h4>

                                                <div v-if="dataArticle.length > 0" class="pe-3" data-simplebar="init" style="max-height: 287px;">
                                                    <div class="simplebar-wrapper" style="margin: 0px -16px 0px 0px;">
                                                        <div class="simplebar-height-auto-observer-wrapper">
                                                            <div class="simplebar-height-auto-observer"></div>
                                                        </div>
                                                        <div class="simplebar-mask">
                                                            <div class="simplebar-offset" style="right: -17px; bottom: 0px;">
                                                                <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                                                    <div class="simplebar-content" style="padding: 0px 16px 0px 0px;">
                                                                        <router-link v-for="(item, index) in dataArticle" :key="index" :to=" {name : 'page_article_detail', params: {code : item.code}}" class="text-body d-block">
                                                                            <div class="d-flex py-3">
                                                                                <div class="flex-shrink-0 me-3 align-self-center">
                                                                                    <div class="avatar-xs">
                                                                                        <span class="avatar-title rounded-circle bg-soft-primary text-success">
                                                                                            <i :class="item.type == 'news' ? 'mdi mdi-newspaper':'mdi mdi-bullhorn-outline'"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="flex-grow-1 overflow-hidden">
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'news'">
                                                                                        Berita
                                                                                    </h5>
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'announcement'">
                                                                                        Pengumuman
                                                                                    </h5>
                                                                                    <p class="text-truncate mb-0">
                                                                                        {{item.name}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="flex-shrink-0 font-size-13">
                                                                                    {{item.time_since}}
                                                                                </div>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="simplebar-placeholder" style="width: auto; height: 369px;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                                                        <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                                                        <div class="simplebar-scrollbar"
                                                            style="height: 223px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                                                    </div>
                                                </div>

                                                <div  v-if="dataArticle.length <= 0">
                                                    <empty-state></empty-state>
                                                </div>
                                            </div>
                                            <!-- end card-body -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="users.role == 'operator'">
                                <div class="row">
                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-pink border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Member</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_members}} Orang</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-users fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-green border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Paket Tersedia</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_package}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-briefcase fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-blue border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Tryout CPNS</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_tryout_cpns}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-file-contract fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 mb-2">
                                        <div class="card-dashboard bg-c-yellow border-left-primary shadow h-100 py-2">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <div
                                                            class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                            Total<br/>Tryout Kedinasan</div>
                                                        <div class="h5 mb-0 order-card">{{dashboard.total_tryout_kedinasan}}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-file-contract fa-2x text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-2 mt-2">
                                        <div class="card border">
                                            <div class="card-body">
                                                <h4 class="card-title">Berita dan Pengumuman</h4>

                                                <div v-if="dataArticle.length > 0" class="pe-3" data-simplebar="init" style="max-height: 287px;">
                                                    <div class="simplebar-wrapper" style="margin: 0px -16px 0px 0px;">
                                                        <div class="simplebar-height-auto-observer-wrapper">
                                                            <div class="simplebar-height-auto-observer"></div>
                                                        </div>
                                                        <div class="simplebar-mask">
                                                            <div class="simplebar-offset" style="right: -17px; bottom: 0px;">
                                                                <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                                                    <div class="simplebar-content" style="padding: 0px 16px 0px 0px;">
                                                                        <router-link v-for="(item, index) in dataArticle" :key="index" :to=" {name : 'page_article_detail', params: {code : item.code}}" class="text-body d-block">
                                                                            <div class="d-flex py-3">
                                                                                <div class="flex-shrink-0 me-3 align-self-center">
                                                                                    <div class="avatar-xs">
                                                                                        <span class="avatar-title rounded-circle bg-soft-primary text-success">
                                                                                            <i :class="item.type == 'news' ? 'mdi mdi-newspaper':'mdi mdi-bullhorn-outline'"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="flex-grow-1 overflow-hidden">
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'news'">
                                                                                        Berita
                                                                                    </h5>
                                                                                    <h5 class="font-size-14 mb-1" v-if="item.type == 'announcement'">
                                                                                        Pengumuman
                                                                                    </h5>
                                                                                    <p class="text-truncate mb-0">
                                                                                        {{item.name}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="flex-shrink-0 font-size-13">
                                                                                    {{item.time_since}}
                                                                                </div>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="simplebar-placeholder" style="width: auto; height: 369px;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                                                        <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div>
                                                    </div>
                                                    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                                                        <div class="simplebar-scrollbar"
                                                            style="height: 223px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                                                    </div>
                                                </div>

                                                <div  v-if="dataArticle.length <= 0">
                                                    <empty-state></empty-state>
                                                </div>
                                            </div>
                                            <!-- end card-body -->
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'Dashboard',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components : {
            'v-select' : vSelect
        },
        data() {
            return {
                dataFetch           : true,
                fetchDashboard      : true,
                fetchStatistic      : true,
                fetchArticle        : true,
                fetchSubscriber     : true,
                dashboard           : {
                    top_points              : [],
                    total_members           : 0,
                    total_incomes           : "Rp -",
                    total_transactions      : 0,
                    total_news              : 0,
                    total_package           : 0,
                    total_tryout_cpns       : 0,
                    total_tryout_kedinasan  : 0,
                },
                statistic           : [],
                users               : [],
                years               : [],
                category            : [],
                series: [{
                    name: "Total Transaksi",
                    data: [530, 49, 262, 424, 356, 349, 374, 371, 491]
                }],
                chartOptions: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#1a8134'],
                    markers: {
                        colors: ['#11f1d0']
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov',
                            'Des'],
                    }
                },
                dataYear     : [],
                dataArticle  : [],
                dataCategory : [
                    {
                        id : 'cpns',
                        name : 'CPNS'
                    },
                    {
                        id : 'kedinasan',
                        name : 'Kedinasan'
                    }
                ],
                dataSubscriber      : [],
                statisticTryout     : false,
                dataWorkingTryout   : [],
                fetchWorkingTryout  : false
            }
        },
        created() {
            this.getUsers();
        },
        methods: {
            getUsers: function () {
                this.dataFetch = true;
                let uri = process.env.VUE_APP_APIHOST + 'user/info';

                this.$http.get(uri).then(res => {
                    this.dataFetch = false;
                    this.users = res.data;

                    if(this.users.role == 'administrator') {
                        this.getYear();
                    } else if(this.users.role == 'member') {
                        this.fetchDashboard = false;    
                        this.category       = {
                            id : 'cpns', name: "CPNS"
                        };
                        this.getStatisticTryout();
                        this.getSubscriber();
                        this.checkWorkingTryout();
                    }

                    this.getArticle();
                    this.getTotalData(this.users.role);
                });
            },
            getYear: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/dashboard/year_list';
                this.$http.get(uri).then(res => {
                    this.dataYear   = res.data.data;
                    this.years      = res.data.active_year;
                    this.getStatistic();
                });
            },
            getTotalData: function(role="") {
                this.fetchDashboard = true;

                if(role == 'administrator') {
                    let uri = process.env.VUE_APP_APIHOST+'admin/dashboard/admin_dashboard_total';
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dashboard      = res.data.data;
                            this.fetchDashboard = false;
                        }
                    });
                }

                if(role == 'member') {
                    let uri = process.env.VUE_APP_APIHOST+'member/dashboard/dashboard_total';
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dashboard      = res.data.data;
                            this.fetchDashboard = false;
                        }
                    });                    
                }

                if(role == 'operator') {
                    let uri = process.env.VUE_APP_APIHOST+'operator/dashboard/dashboard_total';
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dashboard      = res.data.data;
                            this.fetchDashboard = false;
                        }
                    });
                }
            },
            getStatistic: function() {
                this.fetchStatistic = true;

                let uri = process.env.VUE_APP_APIHOST+'admin/dashboard/admin_statistic_transactions';
                this.$http.get(uri, {
                    params : {
                        years : this.years.id
                    }
                }).then(res => {
                    this.fetchStatistic = false;
                    this.statistic      = res.data.data;
                });
            },
            getStatisticTryout: function() {
                this.fetchStatistic = true;

                let uri = process.env.VUE_APP_APIHOST+'member/dashboard/statistic_tryout';
                this.$http.get(uri, {
                    params : {
                        category : this.category.id
                    }
                }).then(res => {
                    this.fetchStatistic = false;
                    let getResponse     = res.data.data;
                    this.statisticTryout    = res.data.status;
                    
                    if(res.data.status) {
                        this.series         = getResponse.series;

                        this.chartOptions   = {
                            chart: {
                                type: 'line',
                                zoom: {
                                    enabled: false
                                }
                            },
                            colors: ['#1a8134'],
                            markers: {
                                colors: ['#11f1d0']
                            },
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'],
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: getResponse.label,
                            }
                        }
                    }
                });
            },
            getArticle: function() {
                this.fetchArticle = true;
                let uri = process.env.VUE_APP_APIHOST+'general/list/article';
                this.$http.get(uri,{
                    params : {
                        page     : 1,
                        limit    : 5
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataArticle      = res.data.data;
                    }

                    this.fetchArticle = false;
                });
            },
            getSubscriber: function() {
                this.fetchSubscriber = true;
                let uri = process.env.VUE_APP_APIHOST+'user/subscriber';
                this.$http.get(uri).then(res => {
                    
                    if(res.data.status) {
                        this.dataSubscriber      = res.data.data;
                    }

                    this.fetchSubscriber = false;
                });
            },
            checkWorkingTryout: function() {
                this.fetchWorkingTryout = true;
                let uri = process.env.VUE_APP_APIHOST+'member/dashboard/check_status_tryout';
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataWorkingTryout      = res.data.data;
                    }

                    this.fetchWorkingTryout = false;
                });
            },
            continueTryout: function(type="", code="") {
                if(type == 'CPNS') {
                    this.$router.push({ name: 'member_cpns_tryout_start' , params: {
                        code : code
                    }});
                }

                if(type == 'KEDINASAN') {
                    this.$router.push({ name: 'member_kedinasan_tryout_start' , params: {
                        code : code
                    }});
                }
            }
        }
    }
</script>