<template>
    <div>
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div v-if="dataDetail.status == 'lock'" class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Hasil - {{dataDetail.title}}</h4>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12" style="margin-top:-10px">
                        <div class="card border" style="border: 1px solid #f1f5f7; !important">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="text-center mt-3">
                                        <img class="image-responsive" src="images/notification.png" style="width:25%"/>
                                    </div>
                                    <h4 class="font-size-18  mt-3 text-center">
                                        Statistik dan hasil tryout saat ini masih terkunci!<br/>
                                        Hasil dapat diakses pada tanggal <b>{{dataDetail.text_result_date}}</b>
                                        <br />
                                        <button type="button" class="btn btn-outline-primary mt-3" v-on:click="goBack">
                                            <i class="mdi mdi-arrow-left"></i> Kembali
                                        </button>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="dataDetail.status == 'open'" class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Hasil - {{dataDetail.title}}</h4>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:-10px !important">
                    <div class="col-md-8 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Informasi Tryout</h4>
                                <p class="card-title-desc">Berikut ini adalah informasi tryout yang telah kamu kerjakan :</p>
                                <table class="table table-centered mb-3">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4"><i class="mdi mdi-clipboard-file-outline"></i> Nama Tryout</td>
                                            <td class="col-sm-8">
                                                <b>{{dataDetail.title}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4"><i class="mdi mdi-clock-check-outline"></i> Durasi Soal</td>
                                            <td class="col-sm-8">
                                                <b>{{dataDetail.timer}} Menit</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4"><i class="mdi mdi-clipboard-list-outline"></i> Tanggal Mengerjakan</td>
                                            <td class="col-sm-8">
                                                <b>{{dataDetail.working_date}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4"><i class="mdi mdi-account-check-outline"></i> Nama Peserta</td>
                                            <td class="col-sm-8">
                                                <b>{{dataDetail.participant_name}}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="dataDetail.graduated == 0" class="alert alert-danger fade show" role="alert">
                                    Hallo <b>{{dataDetail.participant_name}}!</b><br/>
                                    <b>Kamu belum lulus pada tryout ini.</b><br/>
                                    Tetap semangat dan coba lagi kembali di tryout berikutnya yaaa...<br/>
                                </div>

                                <div v-if="dataDetail.graduated == 1" class="alert alert-success fade show" role="alert">
                                    Hallo <b>{{dataDetail.participant_name}}!</b><br/>
                                    <b>Selamat kamu lulus pada tryout ini!</b> Jangan sampai berpuas diri ya!<br/>
                                    Tetap konsisten & coba lagi kembali tryout berikutnya...<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="card border">
                            <div class="card-body text-center">
                                <h4 class="card-title">Total Skor Kamu</h4>
                                <h2 class="mt-3"><b>{{dataDetail.total_scored}}</b></h2>
                                <h3><b>Dari {{dataDetail.total_points}}</b></h3>
                                <hr/>
                                <h4 class="card-title">Keterangan</h4>
                                <h4 v-if="dataDetail.graduated == 0"><span class="badge bg-danger">Tidak Lulus</span></h4>
                                <h4 v-if="dataDetail.graduated == 1"><span class="badge bg-success">Lulus</span></h4>

                                <small class="card-title-desc text-center">Klik tombol dibawah untuk melihat hasil dan statistik tryout kamu</small>
                                
                                <div class="d-grid">
                                    <button v-on:click="changeTab('Statistic')" type="button"  :class="currentTabComponent == 'Statistic' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block mt-2" ><i class="mdi mdi-chart-bar"></i> Lihat Statistik</button>
                                    <button v-on:click="changeTab('Discussion')" type="button" :class="currentTabComponent == 'Discussion' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block mt-2" ><i class="mdi mdi-clipboard-file-outline"></i> Lihat Pembahasan</button>
                                    <button v-on:click="goToRank(dataDetail.code)" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-2" ><i class="mdi mdi-trophy-variant-outline"></i> Rangking Peserta</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <vue-page-transition>
                    <keep-alive>
                        <component :is="currentTabComponent"></component>
                    </keep-alive>
                </vue-page-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import Statistic from "@/views/member/cpns/tryout/Statistic.vue"
    import Discussion from "@/views/member/cpns/tryout/Discussion.vue"

    export default {
        name: 'MemberCpnsTryoutResultDetail',
        metaInfo: {
            title: 'Statistik & Hasil Tryout CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            Statistic,
            Discussion,
        },
        data() {
            return {
                emptyState              : false,
                dataFetch               : false,
                dataDetail              : [],
                currentTabComponent     : 'Statistic',
                cpns_tryout_answer_id   : this.$route.params.cpns_tryout_answer_id
            }
        },
        created() {
            this.getData();
        },
        methods: {
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cpns/tryout/result/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        cpns_tryout_answer_id : this.cpns_tryout_answer_id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        this.dataDetail = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            goToRank: function(code="") {
                this.$router.push({ name: 'member_cpns_tryout_rank' , params: {
                    code : code
                }});
            },
            goBack: function() {
                this.$router.push({ name: 'member_cpns_tryout_result'});
            }
        }
    }
</script>