<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Progress belajar hasil tryout</h4>
                </div>
            </div>
        </div>

        <div class="row mb-4" style="margin-top:-10px">
            <div class="col-md-4 mb-2 col-sm-12 order-md-4">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">Keterangan</h4>

                        <div v-if="dataProgress.length > 0" class="pe-3" data-simplebar="init" style="max-height: 365px;">
                            <div class="simplebar-wrapper" style="margin: 0px -16px 0px 0px;">
                                <div class="simplebar-height-auto-observer-wrapper">
                                    <div class="simplebar-height-auto-observer"></div>
                                </div>
                                <div class="simplebar-mask">
                                    <div class="simplebar-offset" style="right: -17px; bottom: 0px;">
                                        <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                            <div class="simplebar-content" style="padding: 0px 16px 0px 0px;">
                                                <a href="javascript:void(0)" v-for="(item, index) in dataProgress" :key="index" class="text-body d-block">
                                                    <div class="d-flex py-3">
                                                        <div class="flex-shrink-0 me-3 align-self-center">
                                                            <div class="avatar-xs">
                                                                <span class="avatar-title rounded-circle bg-soft-primary text-success">
                                                                    <i :class="'mdi mdi-clipboard-file-outline'"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="flex-grow-1 overflow-hidden">
                                                            <h5 class="font-size-14 mb-1">
                                                                {{item.description}}
                                                            </h5>
                                                            <p class="text-truncate mb-0">
                                                                {{item.time}}
                                                            </p>
                                                        </div>
                                                        <div class="flex-shrink-0 font-size-13">
                                                            Nilai : <b>{{item.total_scored}}</b>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="simplebar-placeholder" style="width: auto; height: 369px;"></div>
                            </div>
                            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                                <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div>
                            </div>
                            <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                                <div class="simplebar-scrollbar"
                                    style="height: 223px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                            </div>
                        </div>

                        <div  v-if="dataProgress.length <= 0">
                            <empty-state></empty-state>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>

            <div class="col-md-8 col-sm-12 order-md-8">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">
                            Grafik Perkembangan Nilai - {{this.title}}
                        </h4>
                        <hr/>
                        <div v-if="fetchStatistic" class="mt-3">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <div  v-if="!fetchStatistic" id="chart" class="mt-3">
                            <div v-if="!statisticProgress">
                                <empty-state></empty-state>
                            </div>
                            
                            <div v-if="statisticProgress">
                                <apexchart v-if="series[0].data.length > 1" type="line" height="350" :options="chartOptions"
                                    :series="series"></apexchart>
                                    <p v-if="series[0].data.length <= 1" class="text-center">
                                        Untuk menampilkan <b>statistik nilai {{this.title}} </b><br/>
                                        minimal kamu harus <b>mengerjakan 2x</b> tryout terlebih dahulu
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberPppkTryoutProgress',
        metaInfo: {
            title: 'Progress Belajar Tryout PPPK',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                title : this.$parent.$parent.dataDetail.tryout.title,
                chartOptions: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#1a8134'],
                    markers: {
                        colors: ['#11f1d0']
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov',
                            'Des'],
                    }
                },
                series: [{
                    name: "Total Transaksi",
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 6]
                }],
                dataProgress    : [],
                fetchStatistic  : true,
                statisticProgress : false,
                code              : this.$route.params.code,
                tryout_code       : this.$route.params.tryout_code,
            }
        },
        created() {
            this.getProgress();
        },
        methods: {
            getProgress: function() {
                this.fetchArticle = true;
                let uri = process.env.VUE_APP_APIHOST+'member/pppk/tryout/result/progress';
                this.$http.get(uri,{
                    params : {
                        tryout_code : this.tryout_code
                    }
                }).then(res => {
                    this.fetchStatistic = false;
                    let getResponse             = res.data.data;
                    this.statisticProgress      = res.data.status;
                    this.dataProgress           = getResponse.list;

                    if(res.data.status) {
                        this.series         = getResponse.series;

                        this.chartOptions   = {
                            chart: {
                                type: 'line',
                                zoom: {
                                    enabled: false
                                }
                            },
                            colors: ['#1a8134'],
                            markers: {
                                colors: ['#11f1d0']
                            },
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'],
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: getResponse.label,
                            }
                        }
                    }
                });
            }
        }
    }
</script>