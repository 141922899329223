<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">
                                        <a href="javascript:void(0)" v-on:click="goToMyPackage"><i class="fa fa-arrow-left mr-2"></i></a> Rangking Peserta
                                    </h4>
                                </div>

                                <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter pencarian</h4>
                                <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan pencarian data.</p>

                                <div class="row">
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Provinsi</label>
                                            <v-select
                                                placeholder="Pilih Provinsi"
                                                label="name"
                                                :options="listProvince"
                                                v-model="province"
                                                @input="getCity"
                                                >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kota</label>
                                            <v-select
                                                placeholder="Pilih Kota"
                                                label="name"
                                                :options="listCity"
                                                v-model="city"
                                            >
                                            </v-select>
                                        </div>
                                    </div>

                                    <div class="col-sm-12   mt-3 col-md-3">
                                        <div class="form-group d-grid" style="margin-top:27px">
                                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i class="fa fa-search"></i> Update Ranking Peserta </button>
                                        </div>
                                    </div>
                                </div>

                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">Peringkat</th>
                                                    <th>Nama Lengkap</th>
                                                    <th>Sub Tema</th>
                                                    <th class="text-center" style="width:15%">Total Skor</th>
                                                    <th style="width:25%">Asal Kota</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{item.rank}}
                                                    </td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.users_papers_category_value}}</td>
                                                    <td class="text-center">{{item.total_scored}}</td>
                                                    <td>
                                                        <span v-if="item.province_name">{{item.province_name}}, </span>{{item.city_name}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'MemberUsersPapersRank',
        metaInfo: {
            title: 'Rangking Peserta',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination,
            'v-select' : vSelect
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                listProvince    : [],
                listCity        : [],
                province        : "",
                city            : "",
                keywords    : "",
                totalData   : 0,
                code        : this.$route.params.code,
                id          : this.$route.params.id,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 100,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getProvince();
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'users/papers/rank';
                let province_id = "";
                let city_id     = "";

                if (typeof (this.province) !== 'undefined' && this.province !== null) {
                    province_id = this.province.id;
                }

                if (typeof (this.city) !== 'undefined' && this.city !== null) {
                    city_id = this.city.id;
                }

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code        : this.code,
                        id          : this.id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit,
                        province    : province_id,
                        city        : city_id
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.totalData              = res.data.total_data;
                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            getProvince: function () {
                let uri = 'https://passchendaele.oeltimacreation.com/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.province) {
                    if(this.province.id) {
                        let uri = 'https://passchendaele.oeltimacreation.com/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.province.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            },
            goToMyPackage: function() {
                this.$router.push({ name: 'member_esai_package_overview' , params: {
                    code : this.code
                }});
            }
        }
    }
</script>