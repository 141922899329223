<template>
    <div>
        <div class="bg-overlay light-bg"></div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="card border" style="border: 5px solid #f1f5f7; !important">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="text-center">
                                        <img src="images/logo.png" style="width:30%"/>
                                    </div>
                                    <h4 class="font-size-18 text-muted mt-3 text-center">Gubuk Sains Prima</h4>
                                    <p class="mb-2 text-center">Login untuk lanjut ke aplikasi</p>
                                    <form class="form-horizontal" v-on:submit.prevent="onSubmit">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-2">
                                                    <label class="col-form-label col-form-label-thick">Email </label>
                                                    <div class="input-icon input-icon-left input-icon-left-form-naked">
                                                        <i class="ph-user-bold"></i>
                                                        <input autocomplete="email" type="text"
                                                            class="form-control form-control-naked fcn-w-bb w-100 d-block"
                                                            :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username" placeholder="Masukan Email...">
                                                            <div v-if="submitted && !$v.username.required" class="invalid-feedback">Email wajib diisi!</div>    
                                                    </div>
                                                </div>
                                                <div class="mb-2">
                                                    <label class="col-form-label col-form-label-thick">Password </label>
                                                    <div class="input-icon input-icon-left input-icon-left-form-naked">
                                                        <i class="ph-lock-bold"></i>
                                                        <input autocomplete="current-password" type="password"
                                                            class="form-control form-control-naked fcn-w-bb w-100 d-block"
                                                            :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Masukan Password...">
                                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div>
                                                    </div>
                                                </div>
                                                <div class="d-grid mt-3">
                                                    <button :disabled="waiting" type="submit" class="btn btn-primary waves-effect waves-light">
                                                        {{ waiting ? 'Harap tunggu ...' : 'Masuk Ke Aplikasi' }}                                                
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row text-center mt-3">
                                            <div class="col font-weight-bold">
                                                Belum punya akun ? <router-link :to="{name : 'signup'}">Daftar disini</router-link>
                                            </div>
                                        </div>
                                        <div class="row text-center mt-1">
                                            <div class="col font-weight-bold">
                                                Lupa Password ? <router-link :to="{name : 'forgot_password'}">Klik disini</router-link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Signin',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                username: "",
                password: "",
                submitted: false,
                waiting: false,
            }
        },
        mounted () {
            document.body.classList.add('bg-pattern')
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            this.waiting = false;

                            if (response.data.status) {
                                const token = response.data.user.key;
                                localStorage.setItem('gsprima_token', token);
                                location.reload();
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            }
        }
    }
</script>