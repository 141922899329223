<template>
    <div class="page-content">
        <div class="container-fluid">
            <div v-if="dataFetch" class="row">
                <div class="col-xl-7 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-5 card">                
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch" class="row">
                <div class="col-xl-7">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0">Upload Data Soal - {{this.dataTryout.tryout.title}}</h4>

                                <div class="page-title-right">
                                    <router-link class="btn btn-outline-primary rounded btn-sm"
                                        :to="{name : 'olimpiade_tryout_question', params: {id:this.dataTryout.tryout.id}}"><i class="fa fa-arrow-left"></i> Kembali
                                    </router-link>
                                </div>
                            </div>

                            <hr style="margin-top:-10px" />

                            <form v-on:submit.prevent="onSubmit">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        <small><i>Max upload : 5 MB</i></small>
                                    </div>
                                </div>
                                <hr/>
                                <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                                <i class="fa fa-upload"></i> {{ waiting ? 'Harap tunggu ...' : 'Upload' }}</button>
                            </form>
                        </div>
                    </div>
                </div> <!-- end col -->

                <div class="col-xl-5">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0">Ketentuan Import</h4>
                            </div>
                            <hr/>
                            <ol>
                                <li>Pastikan koneksi internet anda aman.</li>
                                <li>Gunakan Format import yang sudah disediakan oleh sistem.</li>
                                <li>Jika anda belum memiliki format import<br/>Silahkan klik link dibawah ini </li>
                                <li><a href="assets/download/format-import-soal-tryout-olimpiade.xlsx" target="blank"><b>Download Format</b></a></li>
                                <li>Pastikan format yang anda import telah sesuai.</li>
                            </ol>
                        </div>
                    </div>
                </div> <!-- end col -->
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'OlimpiadeTryout',
        metaInfo: {
            title: 'Import Olimpiade Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                olimpiade_tryout_id  : this.$route.params.id,
                tryout_name     : "",
                form : {
                    file : ""
                },
                submitted       : false,
                waiting         : false,
                dataFetch       : true,
                dataTryout      : {
                    tryout : [],
                    list_questions : []
                }
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/olimpiade/tryout/detail';

                    this.dataFetch  = true;
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse         = res.data.data;
                            this.dataTryout.tryout  = getResponse
                            this.dataFetch          = false;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'olimpiade_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.file = file;
                }
            },
            onSubmit: function () {
                if(this.form.file == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan file import",
                    });
                } else {
                    this.waiting    = true;
                    this.submitted  = true;

                    let formData = new FormData();

                    formData.append('olimpiade_tryout_id', this.olimpiade_tryout_id);
                    formData.append('file', this.form.file);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/olimpiade/tryout/import-questions', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({name : 'olimpiade_tryout_question', params: { id: this.olimpiade_tryout_id }});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            }
        }
    }
</script>