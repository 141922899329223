<template>
    <div>
        <div class="bg-overlay light-bg"></div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="card border" style="border: 5px solid #f1f5f7; !important">
                            <div class="card-body p-4 text-center mt-3" v-if="fetchToken">
                                    <div class="d-block rounded-lg p-3 m-t-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center text-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar text-center">
                                                            <div class="sb-lg mb-2" style="height:100px !important"></div>
                                                            <hr/>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-4"></div>
                                                            <div class="sb-lg" style="height:40px !important"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            </div>

                            <div class="card-body p-4" v-if="!fetchToken">
                                <div class="" v-if="allowAccess">
                                    <div class="text-center mt-5">
                                        <img src="images/team-success.svg" style="width:100%"/>
                                    </div>
                                    <h4 class="font-size-28 mt-5 text-center">Verifikasi Akun Berhasil!</h4>
                                    <div class="row text-center mt-3">
                                        <div class="col font-weight-bold">
                                            <router-link :to="{name : 'signin'}">Kembali ke halaman login</router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="" v-if="!allowAccess">
                                    <div class="text-center mt-5">
                                        <img src="images/notification.png" style="width:70%"/>
                                    </div>
                                    <h4 class="font-size-18 text-muted mt-5 text-center">Halaman konfirmasi tidak valid</h4>
                                    <div class="row text-center mt-3">
                                        <div class="col font-weight-bold">
                                            <router-link :to="{name : 'signin'}">Kembali ke halaman login</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ConfirmPassword',
        metaInfo: {
            title: 'Konfirmasi Password Baru',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                fetchToken      : true,
                allowAccess     : false,
                token           : this.$route.params.token
            }
        },
        mounted () {
            document.body.classList.add('bg-pattern');
            localStorage.removeItem('gsprima_token');
            axios.defaults.headers.common['Authorization'] = '';
            this.getToken();
        },
        methods: {
            getToken: function() {
                let uri         = process.env.VUE_APP_APIHOST+'auth/token/verify';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        token : this.token,
                        type  : 'email'
                    }
                }).then(res => {
                    this.fetchToken     = false;
                    this.allowAccess    = res.data.status;
                });
            }
        }
    }
</script>