<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Data Tryout Kedinasan</h4>

                                    <div class="page-title-right">
                                        <router-link class="btn btn-primary rounded" :to="{name : 'kedinasan_tryout_add'}"><i class="fa fa-plus"></i> Tambah Data</router-link>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Tipe Tryout</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="type" v-on:change="getData">
                                                <option value="">Lihat Semua</option>
                                                <option value="free">Gratis</option>
                                                <option value="premium">Berbayar</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-9 mt-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Kata kunci</label>
                                            <div class="input-group">
                                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                                <div class="input-group-append">
                                                    <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                            class="fa fa-search"></i> Cari </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Judul</th>
                                                    <th style="width:10%">Tipe</th>
                                                    <th style="width:13%">Durasi</th>
                                                    <th style="width:13%">Harga</th>
                                                    <th style="width:5%" class="text-center">Status</th>
                                                    <th style="width:28%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.title}}</td>
                                                    <td>
                                                        <span v-if="item.type == 'free'" class="badge bg-warning">Gratis</span>
                                                        <span v-if="item.type == 'premium'" class="badge bg-info">Berbayar</span>
                                                    </td>
                                                    <td>{{item.timer}} Menit</td>
                                                    <td>
                                                        <s v-if="item.price_out">{{item.text_price_out}}<br/></s>
                                                        <span><b>{{item.text_price}}</b></span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'draft'" class="badge bg-secondary">Draft</span>
                                                        <span v-if="item.status == 'publish'" class="badge bg-primary">Publish</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <div class="button-items">
                                                            <router-link :to="{name : 'kedinasan_tryout_edit', params: {id:item.id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-edit"></i> Ubah</router-link>
                                                            <button v-on:click="deleteData(item.id)" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Hapus</button>
                                                            <router-link :to="{name : 'kedinasan_tryout_question', params: {id:item.id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-list"></i> Kelola Soal</router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'KedinasanTryout',
        metaInfo: {
            title: 'Data Tryout Kedinasan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                type        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/kedinasan/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        type        : this.type,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/kedinasan/tryout/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>