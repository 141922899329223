<template>
    <div>
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid" v-if="!dataDetail.subscribed">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body mb-0">
                                <div class="text-center mt-1">
                                    <img class="image-responsive" src="images/upgrade.svg" style="width:40%"/>
                                </div>
                                <h4 class="font-size-18  mt-3 text-center">
                                    Kamu tidak terdaftar sebagai peserta di<br/>
                                    <b>{{dataDetail.name}}</b>
                                    <br />
                                    <button type="button" class="btn btn-outline-primary mt-3" v-on:click="goRegistration(dataDetail.code)">
                                        <i class="mdi mdi-account-plus-outline"></i> Daftar Sebagai Peserta
                                    </button>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid" v-if="dataDetail.subscribed">
                <div class="row" v-if="!dataDetail.open_result">
                    <div class="col-xl-7" v-if="fetchPapers">
                        <div class="card border">
                            <div class="card-body text-center">
                                <p class="mb-0">Loading...</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="!fetchPapers" class="col-xl-7">
                        <div class="alert alert-warning text-center mb-3" v-if="usersPapers.status == 'rejected'">
                            <b>Naskah yang kamu kirim sebelumnya sudah di cek oleh tim reviewer dan <b>harus direvisi</b></b>
                            <p class="mb-0 ">
                                <a style="color: #363636 !important;" href="javascript:void(0)" v-on:click="goToHistory(usersPapers.id)">Klik disini untuk melihat feedback dari tim reviewer</a>
                            </p>
                        </div>

                        <div class="card border" v-if="usersPapers.status != 'waiting' && usersPapers.status != 'accepted'">
                            <div class="card-body">
                                <div
                                    class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Upload Naskah - {{this.dataDetail.name}}</h4>
                                </div>

                                <hr style="margin-top:-10px"/>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label for="exampleInputEmail1">Judul Naskah</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan judul..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                            <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul Naskah Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label>Sub Tema</label>
                                            <select class="form-select" v-model="form.category" v-on:change="addSubTitle()">
                                                <option value="">Pilih Sub Tema</option>
                                                <option v-for="(item, index) in dataCategory" :key="index" v-bind:value="{ id: item.id, text: item.name }">{{item.name}}</option>
                                            </select>

                                            <input type="text" class="form-control mt-2"
                                                placeholder="Masukan sub tema..." v-model="form.sub_title" :class="form.category.text == 'Lainnya' ? 'd-block':'d-none'">

                                            <div v-if="submitted && !$v.form.sub_title.required" class="invalid-feedback">Sub Tema Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Asal Sekolah</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan Asal Sekolah..." v-model="form.school_origin" :class="{ 'is-invalid': submitted && $v.form.school_origin.$error }">
                                            <div v-if="submitted && !$v.form.school_origin.required" class="invalid-feedback">Asal Sekolah Wajib diisi!</div>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">File Naskah</label>
                                            <input
                                                type="file"
                                                class="form-control"
                                                name="file"
                                                id="file"
                                                @change="previewFiles"
                                                accept="application/pdf">
                                                <div v-if="submitted && !$v.form.attachment.required" class="invalid-feedback d-block">File Naskah Wajib diisi!</div>
                                                <small>
                                                    <i>Max upload : 5 MB</i>
                                                </small>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Catatan</label>
                                            <ckeditor v-model="form.description" :config="editorConfig"></ckeditor>
                                            <div class="alert alert-warning mt-2">
                                                <b>Catatan diisi dengan :</b>
                                                <ul class="mb-0">
                                                    <li>Abstrak / Ringkasan Naskah.</li>
                                                    <li>Anggota Tim.</li>
                                                    <li>Persyaratan lainya seperti kartu pelajar / mahasiswa.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                        <button type="submit" class="btn btn-primary mr-2 mt-2" :disabled="waiting">
                                        <i class="mdi  mdi-file-upload-outline"></i>
                                        {{ waiting ? 'Loading...' : 'Upload' }}</button>

                                        <button v-if="usersPapers.id" class="btn btn-outline-primary mt-2" type="button" v-on:click="goToHistory(usersPapers.id)">
                                            <i class="mdi mdi-history"></i>
                                            Lihat Riwayat Pengumpulan Naskah
                                        </button>
                                </form>
                            </div>
                        </div>

                        <div class="card border" v-if="usersPapers.status == 'waiting'">
                            <div class="card-body">
                                <div class="text-center">
                                    <img style="width:300px;max-width:100%" src="images/sending.png">
                                    <h5 class="card-title m-t-10">
                                        Naskah berhasil dikirim!<br/>
                                    </h5>
                                </div>
                                <hr/>
                                <p>Berikut ini data yang telah kamu kirim :</p>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Judul Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.title}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Sub Tema</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.users_papers_category_value}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Asal Sekolah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.school_origin}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">File Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        <a :href="usersPapers.attachment" target="blank">Lihat</a>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Status</div>
                                    <div class="font-weight-600 text-right">
                                        <span class="badge bg-info">Menunggu Konfirmasi</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-0">
                                    <div class="mr-3">Catatan</div>
                                </div>

                                <div class="mt-2" v-html="usersPapers.description"></div>
                            </div>
                        </div>

                        <div class="card border" v-if="usersPapers.status == 'accepted'">
                            <div class="card-body">
                                <div class="text-center">
                                    <img style="width:300px; max-width:100%" src="images/team-success.svg">
                                    <h5 class="m-t-10">
                                        Selamat {{usersPapers.fullname}}!<br/>
                                        File Naskah kamu telah di terima oleh Tim Admin.
                                    </h5>
                                </div>
                                <hr/>
                                <p>Berikut ini data yang telah kamu kirim :</p>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Judul Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.title}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Sub Tema</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.users_papers_category_value}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Asal Sekolah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.school_origin}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">File Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        <a :href="usersPapers.attachment" target="blank">Lihat</a>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-0">
                                    <div class="mr-3">Catatan</div>
                                </div>

                                <div class="mt-2" v-html="usersPapers.description"></div>
                            </div>
                        </div>

                        <div v-if="dataDetail.description && usersPapers.status != 'waiting' && usersPapers.status != 'accepted'" class="alert alert-info" v-html="dataDetail.description"></div>
                    </div>

                    <div class="col-xl-5" v-if="usersPapers.status != 'accepted'">
                        <div class="card border" v-if="!fetchPapers && usersPapers.status != 'waiting'">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Ketentuan Upload Naskah - {{dataDetail.name}}</h4>
                                </div>
                                <hr style="margin-top:-10px"/>
                                <ol class="mb-0">
                                    <li>Pastikan koneksi internet anda aman.</li>
                                    <li>Naskah yang di upload harus berupa (.pdf)</li>
                                    <li>Naskah harus berukuran max 5 MB (Megabyte)</li>
                                    <li v-if="dataDetail.attachment">Jika anda belum memiliki file juknis atau silabus<br/>Silahkan klik link dibawah ini </li>
                                    <li v-if="dataDetail.attachment"><a :href="dataDetail.attachment" target="blank"><b>Download juknis atau silabus</b></a></li>
                                    <li>Pastikan file naskah yang di upload telah sesuai dengan syarat dan ketentuan yang berlaku.</li>
                                </ol>
                            </div>
                        </div>

                        <div class="alert alert-warning" v-if="!fetchPapers && usersPapers.status != 'waiting' && usersPapers.status != 'accepted'">
                            <b>Perhatian !</b>
                            <p class="mb-0">Batas pengumpulan naskah lomba sampai tanggal : <b>{{dataDetail.text_end_date}}</b></p>
                        </div>

                        <div v-if="!fetchPapers && dataDetail.description && usersPapers.status == 'waiting'" class="alert alert-warning">
                            <b>Informasi : </b>
                            <ul class="mb-0 pt-2">
                                <li>Naskah akan diperiksa/direview oleh tim penilai/admin Pelajar olimpiade.</li>
                                <li>Waktu review dan penilaian adalah 5 hari kerja (tidak termasuk Sabtu, Minggu, dan Hari libur).</li>
                                <li>Peserta yang <b>DIREVISI</b> diharuskan <b>memperbaiki naskah dan mengirimkan file kembali</b> pada waktu yang sudah ditentukan</li>
                                <li>Peserta harus terus memantau perkembangan naskahnya, karena setiap kelalaian menjadi tanggung jawab peserta.</li>
                                <li>Keputusan Tim Reviewer adalah mutlak dan tidak dapat diganggu gugat.</li>
                            </ul> 
                        </div>
                    </div>

                    <div class="col-xl-5" v-if="!fetchPapers && usersPapers.status == 'accepted'">
                        <div v-if="!dataDetail.open_result" class="alert alert-info">
                            <b>Informasi</b>
                            <p class="mb-0">Pengumuman hasil & rangking lomba akan diumumkan pada tanggal : <b>{{dataDetail.text_result_date}}</b>.</p>
                            <p v-if="dataDetail.study_group" class="mt-3">Untuk mendapatkan informasi lain terkait lomba, silahkan gabung dengan grup yang telah kami sediakan. Klik dibawah ini untuk bergabung dengan grup.</p>
                            <b v-if="dataDetail.study_group" class="mt-3 mb-0">
                                <a :href="dataDetail.study_group" target="_blank">Gabung dengan grup <i class="mdi mdi-cellphone-link"></i></a>
                            </b>
                        </div>
                    </div>                    
                </div>

                <div class="row" v-if="dataDetail.open_result">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Hasil Lomba & Penilaian Naskah - {{dataDetail.name}}</h4>
                        </div>
                    </div>

                    <div v-if="usersPapers.id" class="col-md-8 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Informasi Penilaian Naskah</h4>
                                <p class="card-title-desc">Berikut ini adalah informasi naskah & nilai yang telah kamu kerjakan :</p>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Judul Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.title}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Sub Tema</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.users_papers_category_value}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Asal Sekolah</div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.school_origin}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-4">
                                    <div class="mr-3">File Naskah</div>
                                    <div class="font-weight-600 text-right">
                                        <a :href="usersPapers.attachment" target="blank">Lihat</a>
                                    </div>
                                </div>

                                <h4 class="card-title mb-3">
                                    Statistik Nilai
                                </h4>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Nilai Orisinalitas <i>(Original)</i></div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.original}}
                                    </div>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Nilai Relevansi <i>(Relevance)</i></div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.relevance}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Nilai Kreatifitas <i>(Creativity)</i></div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.creativity}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                    <div class="mr-3">Nilai Analisis <i>(Analysis)</i></div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.novelty}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-2">
                                    <div class="mr-3">Nilai Pemecahan Masalah <i>(Solution)</i></div>
                                    <div class="font-weight-600 text-right">
                                        {{usersPapers.solution}}
                                    </div>
                                </div>

                                <div>
                                    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="usersPapers.id" class="col-md-4 col-sm-12">
                        <div class="card border">
                            <div class="card-body text-center">
                                <h4 class="card-title">Total Skor Kamu</h4>
                                <h2 class="mt-3"><b>{{usersPapers.total_scored}}</b></h2>
                                <h3><b>Dari 500</b></h3>
                                <hr/>

                                <small class="card-title-desc text-center">Klik tombol dibawah untuk melihat ranking peserta dan riwayat pengumpulan naskah</small>
                                <div class="d-grid mt-2">
                                    <button v-on:click="goToRank(usersPapers.id)" type="button" class="text-left btn btn-primary rounded btn-block mt-2" ><i class="mdi mdi-trophy"></i> Ranking Peserta</button>
                                    <button v-on:click="goToHistory(usersPapers.id)" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-2" ><i class="mdi mdi-history"></i> Lihat Riwayat Pengumpulan Naskah</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!fetchPapers & !usersPapers.id" class="col-md-12">
                        <div class="card border">
                            <div class="card-body text-center">
                                <div class="text-center mt-1">
                                    <img class="image-responsive" src="images/notification.png" style="width:30%"/>
                                </div>
                                <h4 class="font-size-18  mt-3 text-center">
                                    Batas pengumpulan waktu sudah berakhir.<br/>
                                    Kamu tidak diperbolehkan mengakses nilai dan ranking peserta<br/>
                                    Karena tidak mengumpulkan naskah.
                                </h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'MemberEsaiPackageOverview',
        metaInfo: {
            title: 'Overview Esai',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        validations: {
            form: {
                title: {
                    required
                },
                sub_title: {
                    required
                },
                school_origin: {
                    required
                },
                attachment: {
                    required
                }
            }
        },
        data() {
            return {
                dataFetch       : true,
                dataDetail      : [],
                dataCategory    : [],
                code            : this.$route.params.code,
                form            : {
                    title           : "",
                    category        : "",
                    sub_title       : "",
                    school_origin   : "",
                    attachment      : "",
                    description     : ""
                },
                submitted   : false,
                waiting     : false,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                fetchPapers : true,
                usersPapers : {
                    id : '',
                    status : ''
                },
                chartOptions: {
                    colors: ['#0b78e9', '#22a843', '#efa50a', '#D2001A', '#2A0944'],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                },
                series: [
                    {
                        name: ['Original'],
                        data: [10]
                    },
                    {
                        name: 'Relevance',
                        data: [20]
                    },
                    {
                        name: 'Creativity',
                        data: [30]
                    },
                    {
                        name: 'Analysis',
                        data: [30]
                    },
                    {
                        name: 'Solution',
                        data: [30]
                    }
                ]
            }
        },
        created() {
            this.getCategory();
            this.getData();
        },
        methods: {
            getData: function() {
                let uri = process.env.VUE_APP_APIHOST + 'member/package/detail';
                this.$http.get(uri, {
                    params: {
                        type    : 'esai',
                        code    : this.code
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;                
                        this.dataDetail = res.data.data;
                        this.checkUsersPapers();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            checkUsersPapers: function() {
                this.fetchPapers = true;
                let uri = process.env.VUE_APP_APIHOST + 'users/papers/check';
                this.$http.get(uri, {
                    params: {
                        package_id    : this.dataDetail.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.usersPapers = res.data.data;

                        if(this.usersPapers.id) {
                            this.series = [
                                {
                                    name: ['Original'],
                                    data: [this.usersPapers.original]
                                },
                                {
                                    name: 'Relevance',
                                    data: [this.usersPapers.relevance]
                                },
                                {
                                    name: 'Creativity',
                                    data: [this.usersPapers.creativity]
                                },
                                {
                                    name: 'Analysis',
                                    data: [this.usersPapers.novelty]
                                },
                                {
                                    name: 'Solution',
                                    data: [this.usersPapers.solution]
                                }
                            ];
                        }
                    }
                    this.fetchPapers = false;
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_esai_package'});
            },
            goRegistration: function(code="") {
                this.$router.push({
                    name: 'member_esai_package_detail',
                    params : {
                        code : code
                    }
                });
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("Naskah yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('package_id', this.dataDetail.id);
                    formData.append('title', this.form.title);
                    formData.append('category', this.form.category.id);
                    formData.append('sub_title', this.form.sub_title);
                    formData.append('school_origin', this.form.school_origin);
                    formData.append('attachment', this.form.attachment);
                    formData.append('description', this.form.description);
                    formData.append('type', 'esai');

                    this.$http.post(process.env.VUE_APP_APIHOST + 'users/papers/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.checkUsersPapers()
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            goToRank: function(id) {
                this.$router.push({
                    name: 'member_esai_package_rank',
                    params : {
                        code    : this.code,
                        id      : id
                    }
                });
            },
            goToHistory: function(id) {
                this.$router.push({
                    name: 'member_esai_package_history',
                    params : {
                        code    : this.code,
                        id      : id
                    }
                });
            },
            addSubTitle: function() {
                if(this.form.category.text != 'Lainnya') {
                    this.form.sub_title = this.form.category.text;
                } else {
                    this.form.sub_title = '';
                }
            },
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'general/list/papers-category';

                this.$http.get(uri).then(res => {
                    this.dataCategory = res.data.data;
                });
            }
        }
    }
</script>