<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <div class="page-content">       
                <div class="container-fluid">
                    <p>Sedang mengambil data soal...</p>
                </div>
            </div>
        </div>
        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    Saat ini kamu sedang mengerjakan : <strong>{{dataDetail.title}} !</strong>
                </div>

                <div class="row">
                    <div class="col-lg-8 nocopy">
                        <div class="card border">
                            <div v-if="form.hots" class="ribbon ribbon-top-right"><span>Soal Hots</span></div>
                            <div class="card-body">
                                <h4 class="card-title mb-3">Soal Nomor #{{number_questions}}</h4>
                                <hr/>
                                <div v-html="form.questions"></div>
                                <div v-if="form.category == 'multiple_choice'" class="form-check mt-2 mb-2" >
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_a" value="a" :checked="current_save_answer[current_questions]  == 'a'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('a')">
                                    <label class="form-check-label" for="options_a">
                                        <div v-html="form.options_a"></div>
                                    </label>
                                </div>

                                <div v-if="form.category == 'multiple_choice'" class="form-check mb-2">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_b" value="b" :checked="current_save_answer[current_questions]  ==  'b'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('b')">
                                    <label class="form-check-label" for="options_b">
                                        <div v-html="form.options_b"></div>
                                    </label>
                                </div>

                                <div v-if="form.category == 'multiple_choice'" class="form-check mb-2">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_c" value="c" :checked="current_save_answer[current_questions]  ==  'c'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('c')">
                                    <label class="form-check-label" for="options_c">
                                        <div v-html="form.options_c"></div>
                                    </label>
                                </div>

                                <div v-if="form.category == 'multiple_choice'" class="form-check mb-2">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_d" value="d" :checked="current_save_answer[current_questions]  ==  'd'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('d')">
                                    <label class="form-check-label" for="options_d">
                                        <div v-html="form.options_d"></div>
                                    </label>
                                </div>

                                <div v-if="form.category == 'multiple_choice'" class="form-check mb-2">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_e" value="e" :checked="current_save_answer[current_questions]  ==  'e'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('e')">
                                    <label class="form-check-label" for="options_e">
                                        <div v-html="form.options_e"></div>
                                    </label>
                                </div>

                                <div v-if="form.category == 'match'" class="mt-3">
                                    <input type="text" class="form-control" v-model="form.your_answer" placeholder="Masukan jawaban kamu disini" v-on:blur="saveAnswer(form.your_answer)">
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-center">
                                        <button v-if="number_questions > 1" class="text-left btn m-t-10 btn-outline-primary rounded mr-2" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Sebelumnya</button>
                                        <button v-if="number_questions < dataDetail.list_questions.length" class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Sisa Waktu</h4>
                                <div class="d-flex justify-content-between rounded p-3" v-if="calculateTime">
                                    <div class="d-block text-center px-3 w-100">
                                        <div class="tx-15">Menghitung sisa waktu...</div>
                                    </div>
                                </div>

                                <div v-if="!calculateTime" class="d-flex justify-content-between rounded p-3">
                                    <div class="d-block text-center px-3 w-100">
                                        <div class="tx-30">{{time_periode.hours}}</div>
                                        <div class="tx-14">Jam</div>
                                    </div>
                                    <div class="d-block border-left border-right text-center px-3 w-100">
                                        <div class="tx-30">{{time_periode.minutes}}</div>
                                        <div class="tx-14">Menit</div>
                                    </div>
                                    <div class="d-block text-center px-3 w-100">
                                        <div class="tx-30">{{time_periode.seconds}}</div>
                                        <div class="tx-14">Detik</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card border" style="margin-top:-10px !important">
                            <div class="card-body">
                                <h4 class="card-title">Nomor Soal</h4>
                                <hr/>
                                <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                    <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataDetail.list_questions" :key="index"
                                        type="button"
                                        class="text-center btn rounded ml-1 m-1" v-on:click="setQuestions(item.id, item.number)"
                                        :class="current_save_questions[item.id] == true ? 'btn-success': item.status == 'true' ? 'btn-success':item.id == current_questions ? 'btn-primary':'btn-outline-primary' "
                                        >{{item.number}} 
                                    </button>                                    
                                </div>
                                <hr/>
                                <div class="d-grid mt-3">
                                    <button v-on:click="finishQuiz" type="button" class="btn btn-primary btn-block"><i class="mdi mdi-sticker-check-outline"></i> Selesai</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #22a843 !important;
        border-color: #000 !important;
    }
</style>

<script>    
    export default {
        name: 'MemberOlimpiadeTryoutStart',
        metaInfo: {
            title: 'Kerjakan Tryout Olimpiade',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                dataFetch               : true,
                dataDetail              : [],
                code                    : this.$route.params.code,
                tryout_code             : this.$route.params.tryout_code,
                start_time              : '9999-12-12 00:00:00',
                end_time                : '9999-12-12 12:00:00',
                current_questions       : '',
                number_questions        : 1,
                current_save_questions  : [],
                current_save_answer     : [],
                change_answer           : [],
                form : {
                    category : '',
                    olimpiade_tryout_answer_id : '',
                    olimpiade_tryout_answer_detail_id : '',
                    questions     : '',
                    options_a     : '',
                    options_b     : '',
                    options_c     : '',
                    options_d     : '',
                    options_e     : '',
                    your_answer   : '',
                    olimpiade_tryout_question_id   : '',
                    hots   : ''
                },
                olimpiade_tryout_id  : "",
                count_down      : 0,
                timer           : [],
                interval        : 0,
                current_time    : '',
                current_end     : '',
                text_duration   : '',
                time_periode    : {
                    hours : '',
                    minutes : '',
                    seconds : ''
                },
                count_timer     : null,
                pause           : 0,
                stop            : 0,
                tabFocus        : false,
                calculateTime   : true,
            }
        },
        created() {
            this.startQuiz();
            this.timer[this.number_questions] = 0;
        },
        mounted() {
            this.detectFocusOut()
        },
        watch:{
            tabFocus(value) {
                if(value === true) {
                    console.log("Kamu terdeteksi melakukan pindah halaman");
                } else {
                    this.clearTimer();
                }
            },
        },
        methods: {
            detectFocusOut() {
                const onWindowFocusChange = () => {
                    this.tabFocus = document.hidden;
                };

                window.addEventListener('visibilitychange', onWindowFocusChange);
            },        
            startQuiz: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/olimpiade/tryout/start';

                let formData = new FormData();
                formData.append("code", this.code);
                formData.append("tryout_code", this.tryout_code);

                this.dataFetch  = true;                
                this.$http.post(uri, formData).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;                
                        this.dataDetail     = res.data.data;
                        this.start_time     = this.dataDetail.start_quiz;
                        this.end_time       = this.dataDetail.expired_time;
                        this.current_time   = this.dataDetail.current_time;
                        this.current_end    = this.dataDetail.current_end;
                        this.olimpiade_tryout_id = this.dataDetail.id;
                        this.form.olimpiade_tryout_answer_id = this.dataDetail.olimpiade_tryout_answer_id;

                        let position_number = localStorage.getItem('position_number');
                        let current_number  = localStorage.getItem('current_number');

                        if(position_number) {
                            this.setQuestions(this.dataDetail.list_questions[position_number].id, current_number);
                        } else {
                            this.setQuestions(this.dataDetail.list_questions[0].id, 1);
                        }

                        if(this.current_time && this.current_end) {
                            this.startTimer();
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                if(res.data.finished) {
                                    this.$router.push({ name: 'member_olimpiade_tryout_result_detail' , params: {
                                        code                       : this.code,
                                        olimpiade_tryout_answer_id : res.data.olimpiade_tryout_answer_id
                                    }});
                                } else {
                                    this.goBack();
                                }
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_olimpiade_tryout'});
            },
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            setQuestions: function(id, number) {
                var item                 = this.dataDetail.list_questions.find(item => item.id === id);
                this.current_questions   = id;
                this.number_questions    = number;
                this.number_questions    = parseInt(this.number_questions);
                
                this.form.olimpiade_tryout_question_id  = item.id;
                this.form.category                      = item.category;
                this.form.questions                     = item.question;
                this.form.options_a                     = item.options.a;
                this.form.options_b                     = item.options.b;
                this.form.options_c                     = item.options.c;
                this.form.options_d                     = item.options.d;
                this.form.options_e                     = item.options.e;                

                if(this.change_answer[item.id]) {
                    this.change_answer[item.id]  = this.current_save_answer[item.id];
                    this.form.your_answer       = this.current_save_answer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.current_save_questions[item.id]  = true;
                    this.current_save_answer[item.id]     = this.form.your_answer;
                }

                this.form.olimpiade_tryout_answer_detail_id   = item.answer_detail_id;
                this.form.hots   = item.hots;

                this.timer[this.number_questions] = item.timer;
                
                if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                    this.count_down = this.timer[this.number_questions];                
                } else {
                    this.timer[this.number_questions] = 0;
                    this.count_down = 0;
                }

                localStorage.setItem("position_number", (this.number_questions-1));
                localStorage.setItem("current_number", this.number_questions);
                this.scrollToTop();
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataDetail.list_questions.length;

                if(next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id                  = this.dataDetail.list_questions[number].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id                  = this.dataDetail.list_questions[next_number-1].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveAnswer : function(your_answer="", doubtful = 0) {
                this.form.your_answer = your_answer;

                let formData = new FormData();
                formData.append('answer', this.form.your_answer);
                formData.append('olimpiade_tryout_answer_id', this.form.olimpiade_tryout_answer_id);
                formData.append('olimpiade_tryout_question_id', this.form.olimpiade_tryout_question_id);
                formData.append('timer', this.timer[this.number_questions]);
                formData.append('doubtful', doubtful);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/olimpiade/tryout/save-answer', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.current_save_questions[this.form.olimpiade_tryout_question_id] = true;
                        this.current_save_answer[this.form.olimpiade_tryout_question_id]    = this.form.your_answer;
                        this.change_answer[this.form.olimpiade_tryout_question_id]         = true;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            stopQuiz: function() {
                let formData = new FormData();
                formData.append('olimpiade_tryout_answer_id', this.form.olimpiade_tryout_answer_id);
                formData.append('olimpiade_tryout_id', this.olimpiade_tryout_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/olimpiade/tryout/stop', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            html: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_olimpiade_tryout_result_detail' , params: {
                                    code : this.code,
                                    olimpiade_tryout_answer_id : this.form.olimpiade_tryout_answer_id
                                }});
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            finishQuiz: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Selesai mengerjakan ?',
                    html: 'Jawaban yang telah di submit tidak dapat diubah.<br/>Harap periksa kembali jawaban yang telah di simpan',
                    confirmButtonText: 'Selesai',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        this.stopQuiz();
                    }
                });
            },
            countdownTimer: function() {
                if(this.current_time <= this.current_end) {
                    var eventTime   = this.current_end;
                    var currentTime = this.current_time;
                    var diffTime    = eventTime - currentTime;
                    var duration    = this.$moment.duration(diffTime*1000, 'milliseconds');
                    var interval    = this.interval;

                    duration = this.$moment.duration(duration - interval, 'milliseconds');

                    let hours = duration.hours();
                    if(hours < 10) {
                        hours = "0"+hours;
                    }
                    
                    let minutes = duration.minutes();
                    if(minutes < 10) {
                        minutes = "0"+minutes;
                    }

                    let seconds = duration.seconds();
                    if(seconds < 10) {
                        seconds = "0"+seconds;
                    }

                    this.calculateTime = false;
                    this.text_duration = `${hours + ":" + minutes + ":" + seconds}`;

                    this.time_periode.hours = `${hours}`;
                    this.time_periode.minutes = `${minutes}`;
                    this.time_periode.seconds = `${seconds}`;
                    if(hours < 1) {
                        if(minutes < 1) {
                            if(seconds < 1) {
                                this.stopTimer();
                            }
                        }
                    }
                } else {
                    this.text_duration = "Waktu telah berakhir";
                    this.calculateTime = false;
                }
            },
            clearTimer: function() {
                this.calculateTime = true;
                this.interval = 0;
                clearInterval(this.count_timer);
                this.startQuiz();
            },
            stopTimer: function() {
                this.interval = 0;
                this.calculateTime = true;
                clearInterval(this.count_timer);
                this.text_duration = "Waktu telah berakhir";
                this.stopQuiz();
            },
            startTimer: function() {
                this.interval = 0;
                clearInterval(this.count_timer);
                this.count_timer = setInterval(() => {
                    this.countdownTimer()
                    this.interval = this.interval+1000;
                }, 1000);        
            }
        }
    }
</script>