import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import VueBodyClass from 'vue-body-class';

import Signin from '../views/authentication/Signin.vue'
import Signout from '../views/authentication/Signout.vue'
import Signup from '../views/authentication/Signup.vue'
import ForgotPassword from '../views/authentication/ForgotPassword.vue'
import ConfirmPassword from '../views/authentication/ConfirmPassword.vue'
import AccountVerify from '../views/authentication/AccountVerify.vue'
import MainMenu from '@/layouts/MainMenu.vue';

import Dashboard from '../views/static/Dashboard.vue'

// Faq Routes
import FaqMain from '../views/administrator/faq/Main.vue'
import FaqForm from '../views/administrator/faq/Form.vue'

// Transactions Routes
import TransactionsMain from '../views/administrator/transactions/Main.vue'

// Account Routes
import AccountMain from '../views/administrator/account/Main.vue'
import AccountForm from '../views/administrator/account/Form.vue'

// Static Routes
import Profile from '../views/static/Profile.vue'
import Maintenance from '../views/static/Maintenance.vue'
import ArticleDetail from '../views/static/ArticleDetail.vue'

// Article Routes
import ArticleMain from '../views/administrator/article/Main.vue'
import ArticleForm from '../views/administrator/article/Form.vue'

// Reporting Routes
import ReportingMain from '../views/administrator/reporting/Main.vue'

// SKD Category Routes
import SkdCategoryMain from '../views/administrator/category/Main.vue'
import SkdCategoryForm from '../views/administrator/category/Form.vue'

// SKD Sub Category Routes
import SkdSubCategoryMain from '../views/administrator/sub-category/Main.vue'
import SkdSubCategoryForm from '../views/administrator/sub-category/Form.vue'

// CpnsTheory Routes
import CpnsTheoryMain from '../views/administrator/cpns/theory/Main.vue'
import CpnsTheoryForm from '../views/administrator/cpns/theory/Form.vue'

// CpnsTryout Routes
import CpnsTryoutMain from '../views/administrator/cpns/tryout/Main.vue'
import CpnsTryoutForm from '../views/administrator/cpns/tryout/Form.vue'
import CpnsTryoutQuestion from '../views/administrator/cpns/tryout/Question.vue'
import CpnsTryoutImport from '../views/administrator/cpns/tryout/Import.vue'
import CpnsTryoutFreeConfirm from '../views/administrator/cpns/tryout/Free.vue'

// CpnsExercises Routes
import CpnsExercisesMain from '../views/administrator/cpns/exercises/Main.vue'
import CpnsExercisesForm from '../views/administrator/cpns/exercises/Form.vue'
import CpnsExercisesQuestion from '../views/administrator/cpns/exercises/Question.vue'
import CpnsExercisesImport from '../views/administrator/cpns/exercises/Import.vue'

// CpnsPackage Routes
import CpnsPackageMain from '../views/administrator/cpns/package/Main.vue'
import CpnsPackageForm from '../views/administrator/cpns/package/Form.vue'

// KedinasanTheory Routes
import KedinasanTheoryMain from '../views/administrator/kedinasan/theory/Main.vue'
import KedinasanTheoryForm from '../views/administrator/kedinasan/theory/Form.vue'

// KedinasanTryout Routes
import KedinasanTryoutMain from '../views/administrator/kedinasan/tryout/Main.vue'
import KedinasanTryoutForm from '../views/administrator/kedinasan/tryout/Form.vue'
import KedinasanTryoutQuestion from '../views/administrator/kedinasan/tryout/Question.vue'
import KedinasanTryoutImport from '../views/administrator/kedinasan/tryout/Import.vue'
import KedinasanTryoutFreeConfirm from '../views/administrator/kedinasan/tryout/Free.vue'

// KedinasanExercises Routes
import KedinasanExercisesMain from '../views/administrator/kedinasan/exercises/Main.vue'
import KedinasanExercisesForm from '../views/administrator/kedinasan/exercises/Form.vue'
import KedinasanExercisesQuestion from '../views/administrator/kedinasan/exercises/Question.vue'
import KedinasanExercisesImport from '../views/administrator/kedinasan/exercises/Import.vue'

// KedinasanPackage Routes
import KedinasanPackageMain from '../views/administrator/kedinasan/package/Main.vue'
import KedinasanPackageForm from '../views/administrator/kedinasan/package/Form.vue'

// Voucher Routes
import VoucherMain from '../views/administrator/voucher/Main.vue'
import VoucherForm from '../views/administrator/voucher/Form.vue'

// Member CPNS Package Routes
import MemberCpnsPackage from '../views/member/cpns/package/Main.vue'

// Member CPNS Tryout Routes
import MemberCpnsTryoutMain from '../views/member/cpns/tryout/Main.vue'
import MemberCpnsTryoutOverview from '../views/member/cpns/tryout/Overview.vue'
import MemberCpnsTryoutRank from '../views/member/cpns/tryout/Rank.vue'
import MemberCpnsTryoutStart from '../views/member/cpns/tryout/Start.vue'
import MemberCpnsTryoutResult from '../views/member/cpns/tryout/Result.vue'
import MemberCpnsTryoutResultDetail from '../views/member/cpns/tryout/ResultDetail.vue'

// Member CPNS Theory Routes
import MemberCpnsTheoryMain from '../views/member/cpns/theory/Main.vue'
import MemberCpnsTheoryDetail from '../views/member/cpns/theory/Detail.vue'

// Member Cart 
import MemberCartList from '../views/static/CartList.vue'

// Member Invoice
import MemberInvoiceList from '../views/member/invoice/Main.vue'
import MemberInvoiceDetail from '../views/member/invoice/Detail.vue'
import MemberInvoiceInfo from '../views/member/invoice/Info.vue'

// Member CPNS Exercises Routes
import MemberCpnsExercisesMain from '../views/member/cpns/exercises/Main.vue'
import MemberCpnsExercisesOverview from '../views/member/cpns/exercises/Overview.vue'
import MemberCpnsExercisesStart from '../views/member/cpns/exercises/Start.vue'
import MemberCpnsExercisesResult from '../views/member/cpns/exercises/Result.vue'
import MemberCpnsExercisesResultDetail from '../views/member/cpns/exercises/ResultDetail.vue'

// Member CPNS Package Routes
import MemberKedinasanPackage from '../views/member/kedinasan/package/Main.vue'

// Member CPNS Tryout Routes
import MemberKedinasanTryoutMain from '../views/member/kedinasan/tryout/Main.vue'
import MemberKedinasanTryoutOverview from '../views/member/kedinasan/tryout/Overview.vue'
import MemberKedinasanTryoutRank from '../views/member/kedinasan/tryout/Rank.vue'
import MemberKedinasanTryoutStart from '../views/member/kedinasan/tryout/Start.vue'
import MemberKedinasanTryoutResult from '../views/member/kedinasan/tryout/Result.vue'
import MemberKedinasanTryoutResultDetail from '../views/member/kedinasan/tryout/ResultDetail.vue'

// Member CPNS Theory Routes
import MemberKedinasanTheoryMain from '../views/member/kedinasan/theory/Main.vue'
import MemberKedinasanTheoryDetail from '../views/member/kedinasan/theory/Detail.vue'

// Member CPNS Exercises Routes
import MemberKedinasanExercisesMain from '../views/member/kedinasan/exercises/Main.vue'
import MemberKedinasanExercisesOverview from '../views/member/kedinasan/exercises/Overview.vue'
import MemberKedinasanExercisesStart from '../views/member/kedinasan/exercises/Start.vue'
import MemberKedinasanExercisesResult from '../views/member/kedinasan/exercises/Result.vue'
import MemberKedinasanExercisesResultDetail from '../views/member/kedinasan/exercises/ResultDetail.vue'

// PppkTryout Routes
import PppkTryoutMain from '../views/administrator/pppk/tryout/Main.vue'
import PppkTryoutForm from '../views/administrator/pppk/tryout/Form.vue'
import PppkTryoutQuestion from '../views/administrator/pppk/tryout/Question.vue'
import PppkTryoutImport from '../views/administrator/pppk/tryout/Import.vue'
import PppkTryoutPreview from '../views/administrator/pppk/tryout/Preview.vue'

// PppkPackage Routes
import PppkPackageMain from '../views/administrator/pppk/package/Main.vue'
import PppkPackageForm from '../views/administrator/pppk/package/Form.vue'

// Member PPPK Package Routes
import MemberPppkPackageMain from '../views/member/pppk/package/Main.vue'
import MemberPppkPackageDetail from '../views/member/pppk/package/Detail.vue'

// Member PPPK My Package Routes
import MemberPppkMyPackageMain from '../views/member/pppk/package/My.vue'

// Member PPPK Tryout Routes
import MemberPppkTryoutMain from '../views/member/pppk/tryout/Main.vue'
import MemberPppkTryoutOverview from '../views/member/pppk/tryout/Overview.vue'
import MemberPppkTryoutStart from '../views/member/pppk/tryout/Start.vue'
import MemberPppkTryoutResultDetail from '../views/member/pppk/tryout/ResultDetail.vue'

// OlimpiadeTryout Routes
import OlimpiadeTryoutMain from '../views/administrator/olimpiade/tryout/Main.vue'
import OlimpiadeTryoutForm from '../views/administrator/olimpiade/tryout/Form.vue'
import OlimpiadeTryoutQuestion from '../views/administrator/olimpiade/tryout/Question.vue'
import OlimpiadeTryoutImport from '../views/administrator/olimpiade/tryout/Import.vue'
import OlimpiadeTryoutPreview from '../views/administrator/olimpiade/tryout/Preview.vue'

// OlimpiadePackage Routes
import OlimpiadePackageMain from '../views/administrator/olimpiade/package/Main.vue'
import OlimpiadePackageForm from '../views/administrator/olimpiade/package/Form.vue'
import OlimpiadePackageResult from '../views/administrator/olimpiade/package/Result.vue'

// Member Olimpiade Package Routes
import MemberOlimpiadePackageMain from '../views/member/olimpiade/package/Main.vue'
import MemberOlimpiadePackageDetail from '../views/member/olimpiade/package/Detail.vue'

// Member Olimpiade Tryout Routes
import MemberOlimpiadeTryoutMain from '../views/member/olimpiade/tryout/Main.vue'
import MemberOlimpiadeTryoutOverview from '../views/member/olimpiade/tryout/Overview.vue'
import MemberOlimpiadeTryoutStart from '../views/member/olimpiade/tryout/Start.vue'
import MemberOlimpiadeTryoutResultDetail from '../views/member/olimpiade/tryout/ResultDetail.vue'
import MemberOlimpiadeTryoutRank from '../views/member/olimpiade/tryout/Rank.vue'

// EsaiPackage Routes
import EsaiPackageMain from '../views/administrator/esai/package/Main.vue'
import EsaiPackageForm from '../views/administrator/esai/package/Form.vue'
import EsaiPackageResult from '../views/administrator/esai/package/Result.vue'
import EsaiPackageResultDetail from '../views/administrator/esai/package/ResultDetail.vue'

// Member Esai Package Routes
import MemberEsaiPackageMain from '../views/member/esai/package/Main.vue'
import MemberEsaiPackageDetail from '../views/member/esai/package/Detail.vue'
import MemberEsaiPackageOverview from '../views/member/esai/package/Overview.vue'
import MemberEsaiPackageHistory from '../views/member/esai/package/History.vue'
import MemberEsaiPackageHistoryDetail from '../views/member/esai/package/HistoryDetail.vue'
import MemberEsaiPackageRank from '../views/member/esai/package/Rank.vue'

// LktiPackage Routes
import LktiPackageMain from '../views/administrator/lkti/package/Main.vue'
import LktiPackageForm from '../views/administrator/lkti/package/Form.vue'
import LktiPackageResult from '../views/administrator/lkti/package/Result.vue'
import LktiPackageResultDetail from '../views/administrator/lkti/package/ResultDetail.vue'

// Member LKTI Package Routes
import MemberLktiPackageMain from '../views/member/lkti/package/Main.vue'
import MemberLktiPackageDetail from '../views/member/lkti/package/Detail.vue'
import MemberLktiPackageOverview from '../views/member/lkti/package/Overview.vue'
import MemberLktiPackageHistory from '../views/member/lkti/package/History.vue'
import MemberLktiPackageHistoryDetail from '../views/member/lkti/package/HistoryDetail.vue'
import MemberLktiPackageRank from '../views/member/lkti/package/Rank.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','member','operator']
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition  : 'fade',
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      transition  : 'fade',
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/confirm/password/:token',
    name: 'confirm_password',
    component: ConfirmPassword,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/account/verify/:token',
    name: 'account_verify',
    component: AccountVerify,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','member','operator']
    }
  },
  {
    path: '/page/article/detail/:code',
    name: 'page_article_detail',
    component: ArticleDetail,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','member','operator']
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/faq/add',
    name: 'faq_add',
    component: FaqForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/faq/edit/:id',
    name: 'faq_edit',
    component: FaqForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionsMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/account/add',
    name: 'account_add',
    component: AccountForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/account/edit/:id',
    name: 'account_edit',
    component: AccountForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','member','operator']
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator','member','operator']
    }
  },
  {
    path: '/article',
    name: 'article',
    component: ArticleMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/article/add',
    name: 'article_add',
    component: ArticleForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/article/edit/:id',
    name: 'article_edit',
    component: ArticleForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: ReportingMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/skd/category',
    name: 'skd_category',
    component: SkdCategoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/skd/category/add',
    name: 'skd_category_add',
    component: SkdCategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/skd/category/edit/:id',
    name: 'skd_category_edit',
    component: SkdCategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/sdk/sub/category',
    name: 'skd_sub_category',
    component: SkdSubCategoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/sdk/sub/category/add',
    name: 'skd_sub_category_add',
    component: SkdSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/sdk/sub/category/edit/:id',
    name: 'skd_sub_category_edit',
    component: SkdSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns_theory',
    name: 'cpns_theory',
    component: CpnsTheoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/theory/add',
    name: 'cpns_theory_add',
    component: CpnsTheoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/theory/edit/:id',
    name: 'cpns_theory_edit',
    component: CpnsTheoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns_tryout',
    name: 'cpns_tryout',
    component: CpnsTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/tryout/add',
    name: 'cpns_tryout_add',
    component: CpnsTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/tryout/edit/:id',
    name: 'cpns_tryout_edit',
    component: CpnsTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/tryout/question/:id',
    name: 'cpns_tryout_question',
    component: CpnsTryoutQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/tryout/import/:id',
    name: 'cpns_tryout_import',
    component: CpnsTryoutImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns_exercises',
    name: 'cpns_exercises',
    component: CpnsExercisesMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/exercises/add',
    name: 'cpns_exercises_add',
    component: CpnsExercisesForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/exercises/edit/:id',
    name: 'cpns_exercises_edit',
    component: CpnsExercisesForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/exercises/question/:id',
    name: 'cpns_exercises_question',
    component: CpnsExercisesQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/exercises/import/:id',
    name: 'cpns_exercises_import',
    component: CpnsExercisesImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns_package',
    name: 'cpns_package',
    component: CpnsPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/package/add',
    name: 'cpns_package_add',
    component: CpnsPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/cpns/package/edit/:id',
    name: 'cpns_package_edit',
    component: CpnsPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan_theory',
    name: 'kedinasan_theory',
    component: KedinasanTheoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/theory/add',
    name: 'kedinasan_theory_add',
    component: KedinasanTheoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/theory/edit/:id',
    name: 'kedinasan_theory_edit',
    component: KedinasanTheoryForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan_tryout',
    name: 'kedinasan_tryout',
    component: KedinasanTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/tryout/add',
    name: 'kedinasan_tryout_add',
    component: KedinasanTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/tryout/edit/:id',
    name: 'kedinasan_tryout_edit',
    component: KedinasanTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/tryout/question/:id',
    name: 'kedinasan_tryout_question',
    component: KedinasanTryoutQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/tryout/import/:id',
    name: 'kedinasan_tryout_import',
    component: KedinasanTryoutImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan_exercises',
    name: 'kedinasan_exercises',
    component: KedinasanExercisesMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/exercises/add',
    name: 'kedinasan_exercises_add',
    component: KedinasanExercisesForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/exercises/edit/:id',
    name: 'kedinasan_exercises_edit',
    component: KedinasanExercisesForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/exercises/question/:id',
    name: 'kedinasan_exercises_question',
    component: KedinasanExercisesQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/exercises/import/:id',
    name: 'kedinasan_exercises_import',
    component: KedinasanExercisesImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan_package',
    name: 'kedinasan_package',
    component: KedinasanPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/package/add',
    name: 'kedinasan_package_add',
    component: KedinasanPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/package/edit/:id',
    name: 'kedinasan_package_edit',
    component: KedinasanPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: VoucherMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/voucher/add',
    name: 'voucher_add',
    component: VoucherForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/voucher/edit/:id',
    name: 'voucher_edit',
    component: VoucherForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/member/cpns/package',
    name: 'member_cpns_package',
    component: MemberCpnsPackage,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout',
    name: 'member_cpns_tryout',
    component: MemberCpnsTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout/overview/:code',
    name: 'member_cpns_tryout_overview',
    component: MemberCpnsTryoutOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout/rank/:code',
    name: 'member_cpns_tryout_rank',
    component: MemberCpnsTryoutRank,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout/start/:code',
    name: 'member_cpns_tryout_start',
    component: MemberCpnsTryoutStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout/result',
    name: 'member_cpns_tryout_result',
    component: MemberCpnsTryoutResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/tryout/result/detail/:cpns_tryout_answer_id',
    name: 'member_cpns_tryout_result_detail',
    component: MemberCpnsTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/theory',
    name: 'member_cpns_theory',
    component: MemberCpnsTheoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/theory/detail/:code',
    name: 'member_cpns_theory_detail',
    component: MemberCpnsTheoryDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/cpns/tryout/confirm/free',
    name: 'cpns_tryout_confirm_free',
    component: CpnsTryoutFreeConfirm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/kedinasan/tryout/confirm/free',
    name: 'kedinasan_tryout_confirm_free',
    component: KedinasanTryoutFreeConfirm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/member/cart/list',
    name: 'member_cart_list',
    component: MemberCartList,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/invoice/list',
    name: 'member_invoice_list',
    component: MemberInvoiceList,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/invoice/detail/:invoice',
    name: 'member_invoice_detail',
    component: MemberInvoiceDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/invoice/info/:transaction_id',
    name: 'member_invoice_info',
    component: MemberInvoiceInfo,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/exercises',
    name: 'member_cpns_exercises',
    component: MemberCpnsExercisesMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/exercises/overview/:code',
    name: 'member_cpns_exercises_overview',
    component: MemberCpnsExercisesOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/exercises/start/:code/:token',
    name: 'member_cpns_exercises_start',
    component: MemberCpnsExercisesStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/exercises/result',
    name: 'member_cpns_exercises_result',
    component: MemberCpnsExercisesResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/cpns/exercises/result/detail/:cpns_exercises_answer_id',
    name: 'member_cpns_exercises_result_detail',
    component: MemberCpnsExercisesResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/package',
    name: 'member_kedinasan_package',
    component: MemberKedinasanPackage,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout',
    name: 'member_kedinasan_tryout',
    component: MemberKedinasanTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout/overview/:code',
    name: 'member_kedinasan_tryout_overview',
    component: MemberKedinasanTryoutOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout/rank/:code',
    name: 'member_kedinasan_tryout_rank',
    component: MemberKedinasanTryoutRank,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout/start/:code',
    name: 'member_kedinasan_tryout_start',
    component: MemberKedinasanTryoutStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout/result',
    name: 'member_kedinasan_tryout_result',
    component: MemberKedinasanTryoutResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/tryout/result/detail/:kedinasan_tryout_answer_id',
    name: 'member_kedinasan_tryout_result_detail',
    component: MemberKedinasanTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/theory',
    name: 'member_kedinasan_theory',
    component: MemberKedinasanTheoryMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/theory/detail/:code',
    name: 'member_kedinasan_theory_detail',
    component: MemberKedinasanTheoryDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/exercises',
    name: 'member_kedinasan_exercises',
    component: MemberKedinasanExercisesMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/exercises/overview/:code',
    name: 'member_kedinasan_exercises_overview',
    component: MemberKedinasanExercisesOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/exercises/start/:code/:token',
    name: 'member_kedinasan_exercises_start',
    component: MemberKedinasanExercisesStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/exercises/result',
    name: 'member_kedinasan_exercises_result',
    component: MemberKedinasanExercisesResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/kedinasan/exercises/result/detail/:kedinasan_exercises_answer_id',
    name: 'member_kedinasan_exercises_result_detail',
    component: MemberKedinasanExercisesResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/pppk_tryout',
    name: 'pppk_tryout',
    component: PppkTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/tryout/add',
    name: 'pppk_tryout_add',
    component: PppkTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/tryout/edit/:id',
    name: 'pppk_tryout_edit',
    component: PppkTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/tryout/question/:id',
    name: 'pppk_tryout_question',
    component: PppkTryoutQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/tryout/import/:id',
    name: 'pppk_tryout_import',
    component: PppkTryoutImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/tryout/preview/:id',
    name: 'pppk_tryout_preview',
    component: PppkTryoutPreview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk_package',
    name: 'pppk_package',
    component: PppkPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/package/add',
    name: 'pppk_package_add',
    component: PppkPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/pppk/package/edit/:id',
    name: 'pppk_package_edit',
    component: PppkPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/member/pppk/package',
    name: 'member_pppk_package',
    component: MemberPppkPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/package/detail/:code',
    name: 'member_pppk_package_detail',
    component: MemberPppkPackageDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/my/package',
    name: 'member_pppk_my_package',
    component: MemberPppkMyPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/tryout/:code',
    name: 'member_pppk_tryout',
    component: MemberPppkTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/tryout/:code/overview/:tryout_code',
    name: 'member_pppk_tryout_overview',
    component: MemberPppkTryoutOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/tryout/:code/start/:tryout_code/:token/:detail_id',
    name: 'member_pppk_tryout_start',
    component: MemberPppkTryoutStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/pppk/tryout/:code/result/:tryout_code/:id_start',
    name: 'member_pppk_tryout_result_detail',
    component: MemberPppkTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/olimpiade_tryout',
    name: 'olimpiade_tryout',
    component: OlimpiadeTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/tryout/add',
    name: 'olimpiade_tryout_add',
    component: OlimpiadeTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/tryout/edit/:id',
    name: 'olimpiade_tryout_edit',
    component: OlimpiadeTryoutForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/tryout/question/:id',
    name: 'olimpiade_tryout_question',
    component: OlimpiadeTryoutQuestion,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/tryout/import/:id',
    name: 'olimpiade_tryout_import',
    component: OlimpiadeTryoutImport,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/tryout/preview/:id',
    name: 'olimpiade_tryout_preview',
    component: OlimpiadeTryoutPreview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade_package',
    name: 'olimpiade_package',
    component: OlimpiadePackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/package/add',
    name: 'olimpiade_package_add',
    component: OlimpiadePackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/package/edit/:id',
    name: 'olimpiade_package_edit',
    component: OlimpiadePackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/olimpiade/package/result/:id',
    name: 'olimpiade_package_result',
    component: OlimpiadePackageResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/member/olimpiade/package',
    name: 'member_olimpiade_package',
    component: MemberOlimpiadePackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/package/detail/:code',
    name: 'member_olimpiade_package_detail',
    component: MemberOlimpiadePackageDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/tryout/:code',
    name: 'member_olimpiade_tryout',
    component: MemberOlimpiadeTryoutMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/tryout/:code/overview/:tryout_code',
    name: 'member_olimpiade_tryout_overview',
    component: MemberOlimpiadeTryoutOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/tryout/:code/start/:tryout_code',
    name: 'member_olimpiade_tryout_start',
    component: MemberOlimpiadeTryoutStart,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/tryout/result/detail/:code/:olimpiade_tryout_answer_id',
    name: 'member_olimpiade_tryout_result_detail',
    component: MemberOlimpiadeTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/olimpiade/tryout/rank/:code/:tryout_code',
    name: 'member_olimpiade_tryout_rank',
    component: MemberOlimpiadeTryoutRank,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/esai_package',
    name: 'esai_package',
    component: EsaiPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/esai/package/add',
    name: 'esai_package_add',
    component: EsaiPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/esai/package/edit/:id',
    name: 'esai_package_edit',
    component: EsaiPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/esai/package/result/:id',
    name: 'esai_package_result',
    component: EsaiPackageResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/esai/package/result/detail/:id/:users_papers_id',
    name: 'esai_package_result_detail',
    component: EsaiPackageResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/lkti_package',
    name: 'lkti_package',
    component: LktiPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/lkti/package/add',
    name: 'lkti_package_add',
    component: LktiPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/lkti/package/edit/:id',
    name: 'lkti_package_edit',
    component: LktiPackageForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/lkti/package/result/:id',
    name: 'lkti_package_result',
    component: LktiPackageResult,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/lkti/package/result/detail/:id/:users_papers_id',
    name: 'lkti_package_result_detail',
    component: LktiPackageResultDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator','operator']
    }
  },
  {
    path: '/member/esai/package',
    name: 'member_esai_package',
    component: MemberEsaiPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/esai/package/detail/:code',
    name: 'member_esai_package_detail',
    component: MemberEsaiPackageDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/esai/package/overview/:code',
    name: 'member_esai_package_overview',
    component: MemberEsaiPackageOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/esai/package/history/:code/:id',
    name: 'member_esai_package_history',
    component: MemberEsaiPackageHistory,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/esai/package/rank/:code/:id',
    name: 'member_esai_package_rank',
    component: MemberEsaiPackageRank,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/esai/package/history/detail/:code/:id/:detail_id',
    name: 'member_esai_package_history_detail',
    component: MemberEsaiPackageHistoryDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package',
    name: 'member_lkti_package',
    component: MemberLktiPackageMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package/detail/:code',
    name: 'member_lkti_package_detail',
    component: MemberLktiPackageDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package/overview/:code',
    name: 'member_lkti_package_overview',
    component: MemberLktiPackageOverview,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package/history/:code/:id',
    name: 'member_lkti_package_history',
    component: MemberLktiPackageHistory,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package/rank/:code/:id',
    name: 'member_lkti_package_rank',
    component: MemberLktiPackageRank,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  },
  {
    path: '/member/lkti/package/history/detail/:code/:id/:detail_id',
    name: 'member_lkti_package_history_detail',
    component: MemberLktiPackageHistoryDetail,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['member']
    }
  }
]

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin','forgot_password', 'confirm_password', 'signup', 'account_verify'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('gsprima_token');

  if (authRequired && token) {
      Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
          headers: {
              'Authorization': token
          }
      }).then(response => {
        if(response.data.logged_in) {
          let roles = response.data.role;
                  
          if(to.meta.roles.includes(roles)) {
              next();
          } else {
              alert("Akses tidak diperbolehkan");
              next({ name: 'dashboard' });
          }
        } else {
          alert("Sesi login telah habis !");
          localStorage.removeItem('id');
          localStorage.removeItem('gsprima_token');
          next({ name: 'signin' });
        }
    }).catch(function (error) {
        console.debug(error);
    });
  }

  else if (authRequired && !token) {
    next({ name: 'signin' });
  }

  else {
    if(to.name == 'signin' || to.name == 'signup') {
        if(token) {
            next({ name: 'dashboard' });
        } else {
            next();
        }
    }
    else {
        if(!token) {
            if(to.name == 'signout') {
                next({ name: 'signin' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
  }

  vueBodyClass.guard(to, next);
});


export default router
