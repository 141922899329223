<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card border" style="border: 1px solid #f1f5f7; !important">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="text-center mt-1">
                                        <img class="image-responsive" src="images/team-building.svg" style="width:40%" />
                                    </div>
                                    <h4 class="font-size-18  mt-5 text-center">Halaman ini sedang dalam proses
                                        maintenance.<br />Silahkan hubungi admin untuk informasi lebih lanjut.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Maintenance',
        metaInfo: {
            title: 'Maintenance',
            titleTemplate: '%s - Gubuk Sains Prima'
        }
    }
</script>