<template>
    <div>
        <div class="bg-overlay light-bg"></div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="card border" style="border: 5px solid #f1f5f7; !important">
                            <div class="card-body p-4 text-center mt-3" v-if="fetchToken">
                                    <div class="d-block rounded-lg p-3 m-t-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center text-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar text-center">
                                                            <div class="sb-lg mb-2" style="height:100px !important"></div>
                                                            <hr/>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-4"></div>
                                                            <div class="sb-lg" style="height:40px !important"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            </div>

                            <div class="card-body p-4" v-if="!fetchToken">
                                <div class="" v-if="allowAccess">
                                    <div class="text-center">
                                        <img src="images/forgot-password.png" style="width:30%"/>
                                    </div>
                                    <h4 class="font-size-18 text-muted mt-3 text-center">Konfirmasi Password</h4>
                                    <p class="mb-2 text-center">Silahkan masukan password baru kamu</p>
                                    <form class="form-horizontal" v-on:submit.prevent="onSubmit">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-2">
                                                    <label class="col-form-label col-form-label-thick">Password Baru</label>
                                                    <div class="input-icon input-icon-left input-icon-left-form-naked">
                                                        <i class="ph-lock-bold"></i>
                                                        <input autocomplete="current-password" type="password"
                                                            class="form-control form-control-naked fcn-w-bb w-100 d-block"
                                                            :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Password Baru...">
                                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password Baru wajib diisi!</div>
                                                    </div>
                                                </div>

                                                <div class="mb-2">
                                                    <label class="col-form-label col-form-label-thick">Ulangi Password Baru</label>
                                                    <div class="input-icon input-icon-left input-icon-left-form-naked">
                                                        <i class="ph-lock-bold"></i>
                                                        <input autocomplete="current-password" type="password"
                                                            class="form-control form-control-naked fcn-w-bb w-100 d-block"
                                                            :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="retype_password" placeholder="Ulangi Password Baru...">
                                                            <div v-if="submitted && !$v.retype_password.required" class="invalid-feedback">Ulangi Password Baru wajib diisi!</div>
                                                    </div>
                                                </div>
                                                <div class="d-grid mt-3">
                                                    <button :disabled="waiting" type="submit" class="btn btn-primary waves-effect waves-light">
                                                        {{ waiting ? 'Harap tunggu ...' : 'Konfirmasi Password' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="" v-if="!allowAccess">
                                    <div class="text-center mt-5">
                                        <img src="images/notification.png" style="width:70%"/>
                                    </div>
                                    <h4 class="font-size-18 text-muted mt-5 text-center">Halaman konfirmasi tidak valid</h4>
                                    <div class="row text-center mt-3">
                                        <div class="col font-weight-bold">
                                            <router-link :to="{name : 'signin'}">Kembali ke halaman login</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'ConfirmPassword',
        metaInfo: {
            title: 'Konfirmasi Password Baru',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                fetchToken      : true,
                allowAccess     : false,
                password        : "",
                retype_password : "",
                token           : this.$route.params.token,
                submitted       : false,
                waiting         : false,
            }
        },
        mounted () {
            document.body.classList.add('bg-pattern');
            localStorage.removeItem('gsprima_token');
            axios.defaults.headers.common['Authorization'] = '';
            this.getToken();
        },
        validations: {
            password: {
                required
            },
            retype_password: {
                required
            },
        },
        methods: {
            getToken: function() {
                let uri         = process.env.VUE_APP_APIHOST+'auth/token/verify';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        token : this.token,
                        type  : 'password'
                    }
                }).then(res => {
                    this.fetchToken     = false;
                    this.allowAccess    = res.data.status;
                });
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'auth/change-password', {
                            password        : this.password,
                            retype_password : this.retype_password,
                            token           : this.token
                        })
                        .then(response => {
                            this.waiting = false;

                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    html: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'signin' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    html: response.data.message,
                                });
                            }
                        })
                }
            }
        }
    }
</script>