<template>
    <div class="start-quiz">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Soal & Pembahasan</h4>
                </div>
            </div>
        </div>

        <div v-if="dataFetch" class="row">
            <div class="col-md-12">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="row" id="block-questions">
            <div class="col-lg-8 nocopy">
                <div class="card border">
                    <div v-if="form.hots" class="ribbon ribbon-top-right"><span>Soal Hots</span></div>
                    <div class="card-body">
                        <h4 class="card-title mb-3">Soal Nomor #{{number_questions}} - {{form.category}}</h4>
                        <hr/>
                        <div v-html="form.questions"></div>

                        <div v-if="form.options_a"
                            class="d-flex mt-4 mb-2"
                            :class="form.correct_answer == 'a' ? 'font-weight-bold text-success': form.your_answer == 'a' ? 'font-weight-bold text-danger':''">
                            <span class="m-r-10">A.</span>
                            <span v-html="form.options_a"></span>
                        </div>

                        <div v-if="form.options_b"
                            class="d-flex mb-2"
                            :class="form.correct_answer == 'b' ? 'font-weight-bold text-success': form.your_answer == 'b' ? 'font-weight-bold text-danger':''">
                            <span class="m-r-10">B.</span>
                            <span v-html="form.options_b"></span>
                        </div>

                        <div v-if="form.options_c"
                            class="d-flex mb-2"
                            :class="form.correct_answer == 'c' ? 'font-weight-bold text-success': form.your_answer == 'c' ? 'font-weight-bold text-danger':''">
                            <span class="m-r-10">C.</span>
                            <span v-html="form.options_c"></span>
                        </div>

                        <div v-if="form.options_d"
                            class="d-flex mb-2"
                            :class="form.correct_answer == 'd' ? 'font-weight-bold text-success': form.your_answer == 'd' ? 'font-weight-bold text-danger':''">
                            <span class="m-r-10">D.</span>
                            <span v-html="form.options_d"></span>
                        </div>

                        <div v-if="form.options_e"
                            class="d-flex mb-2"
                            :class="form.correct_answer == 'e' ? 'font-weight-bold text-success': form.your_answer == 'e' ? 'font-weight-bold text-danger':''">
                            <span class="m-r-10">E.</span>
                            <span v-html="form.options_e"></span>
                        </div>

                        <div v-if="!form.your_answer">
                            <hr/>
                            <span class="d-flex mt-2 mb-4 alert alert-danger text-center" role="alert">
                                <b>Kamu tidak mengisi jawaban pada soal ini</b>
                            </span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Kamu</div>
                            <div v-if="form.your_answer" class="font-weight-600" :class="form.your_answer == form.correct_answer ? 'text-success':'text-danger'">
                                {{form.your_answer.toUpperCase()}}
                            </div>

                            <div v-if="!form.your_answer" class="font-weight-600">
                                <span class="badge bg-danger">Tidak Mengisi</span>
                            </div>
                        </div>

                        <div v-if="form.correct_answer && form.pppk_category_id != '3'" class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Kunci Jawaban</div>
                            <div class="font-weight-600">
                                {{form.correct_answer.toUpperCase()}}
                            </div>
                        </div>

                        <div v-if="form.pppk_category_id != '1'">
                            <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                <div class="mr-3 font-weight-bold">Poin Jawaban</div>
                            </div>

                            <div v-for="(item, index) in form.point_options" :key="index" class="d-flex align-items-center justify-content-between pb-2 mt-2 border-bottom">
                                <div class="mr-3 font-weight-bold">Poin {{index.toUpperCase()}}</div>
                                <div class="font-weight-600" :class="form.your_answer == index ? 'font-weight-bold text-success':''">
                                    {{item}}
                                </div>
                            </div>
                        </div>

                        <div v-if="form.timer">
                            <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Waktu Mengerjakan</div>
                                <div class="font-weight-600">
                                    {{form.timer}}
                                </div>
                            </div>
                        </div>

                        <div v-if="form.discussion">
                            <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                <div class="mr-3 font-weight-bold">Pembahasan</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mt-1 border-bottom">
                                <div class="mr-3 font-weight-300" v-html="form.discussion"></div>
                            </div>
                        </div>

                        <div v-if="form.discussion_video">
                            <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                                <div class="mr-3 font-weight-bold">Video Pembahasan</div>
                            </div>
                            <vue-plyr>
                                <div class="plyr__video-embed">
                                    <iframe
                                    :src="form.discussion_video"
                                    allowfullscreen
                                    allowtransparency
                                    allow="autoplay"
                                    ></iframe>
                                </div>
                            </vue-plyr>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12 d-flex justify-content-center">
                                <button v-if="number_questions > 1" class="text-left btn m-t-10 btn-outline-primary rounded mr-2" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Sebelumnya</button>
                                <button v-if="number_questions < dataDetail.length" class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Selanjutnya <i class="fa fa-arrow-right"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">Nomor Soal</h4>
                        <hr/>
                        <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                            <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataDetail" :key="index"
                                type="button"
                                class="text-center btn rounded ml-1 m-1"
                                :class="item.btn_style"
                                v-on:click="setQuestions(item.id, item.number)"
                                >{{item.number}} 
                            </button>                                    
                        </div>
                    </div>
                </div>

                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title">Keterangan warna pada nomor soal</h4>
                        <hr/>
                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Benar</div>
                            <div class="font-weight-600">
                                <span class="badge bg-primary">&nbsp;&nbsp;&nbsp;</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Salah</div>
                            <div class="font-weight-600">
                                <span class="badge bg-danger">&nbsp;&nbsp;&nbsp;</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                            <div class="font-weight-600">
                                <span class="badge bg-white border">&nbsp;&nbsp;&nbsp;</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #22a843 !important;
        border-color: #000 !important;
    }
</style>

<script>
    export default {
        name: 'MemberPppkTryoutDiscussion',
        metaInfo: {
            title: 'Soal Dan Pembahasan Tryout PPPPK',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                dataFetch               : true,
                dataDetail              : [],
                code                    : this.$route.params.code,
                tryout_code             : this.$route.params.tryout_code,
                pppk_tryout_start_id    : this.$route.params.id_start,
                form : {
                    category      : '',
                    questions     : '',
                    options_a     : '',
                    options_b     : '',
                    options_c     : '',
                    options_d     : '',
                    options_e     : '',
                    your_answer   : '',
                    correct_answer      : '',
                    discussion          : '',
                    discussion_video    : '',
                    pppk_category_id         : '',
                    point_options       : '',
                    timer                       : '',
                    hots                        : '',
                    pppk_tryout_answer          : '',
                    pppk_tryout_question_id     : '',
                },
                number_questions        : 0,
                current_questions       : "",
                current_save_questions  : [],
                current_save_answer     : [],
                change_answer           : [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/pppk/tryout/result/discussion';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code                 : this.code,
                        tryout_code          : this.tryout_code,
                        pppk_tryout_start_id : this.pppk_tryout_start_id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        this.dataDetail = res.data.data;
                        this.setQuestions(this.dataDetail[0].id, 1);
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            setQuestions: function(id, number) {
                var item = this.dataDetail.find(item => item.id === id);
                this.current_questions    = id;
                this.number_questions    = number;

                this.form.category                  = item.category;
                this.form.pppk_tryout_question_id    = item.id;
                this.form.pppk_category_id           = item.pppk_category_id;
                this.form.point_options              = item.point_options;
                this.form.questions                  = item.question;
                this.form.correct_answer             = item.correct_answer;
                this.form.discussion                 = item.discussion;
                this.form.discussion_video           = '';

                setTimeout(() => {
                    this.form.discussion_video            = item.discussion_video;                    
                }, 100);

                this.form.timer                 = item.timer;
                this.form.hots                  = item.hots;
                this.form.options_a             = item.options.a;
                this.form.options_b             = item.options.b;
                this.form.options_c             = item.options.c;
                this.form.options_d             = item.options.d;
                this.form.options_e             = item.options.e;                

                if(this.change_answer[item.id]) {
                    this.change_answer[item.id]  = this.current_save_answer[item.id];
                    this.form.your_answer       = this.current_save_answer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.current_save_questions[item.id]  = true;
                    this.current_save_answer[item.id]     = this.form.your_answer;
                }

                this.scrollToTop();
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataDetail.length;

                if(next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id              = this.dataDetail[number].id;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id              = this.dataDetail[next_number-1].id;
                    this.setQuestions(id, next_number);
                }
            },
            scrollToTop() {
                window.scrollTo({top: 400, behavior: 'smooth'});
            },
            goBack: function() {
                this.$router.push({ name: 'member_pppk_tryout_overview' , params: {
                    code : this.code,
                    tryout_code : this.tryout_code
                }});
            }
        }
    }
</script>