<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Paket PPPK Saya</h4>
                        </div>
                    </div>
                </div>

                <div v-if="fetchSubsriber" class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!fetchSubsriber && dataSubscribed.subscribed == 0">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="card border" style="border: 1px solid #f1f5f7; !important">
                                <div class="card-body p-4">
                                    <div class="">
                                        <div class="text-center mt-1">
                                            <img class="image-responsive" src="images/upgrade.svg" style="width:40%" />
                                        </div>
                                        <h4 class="font-size-18  mt-3 text-center">
                                            Kamu belum memiliki paket <b>PPPK</b> untuk saat ini
                                            <br />
                                            <button type="button" class="btn btn-outline-primary mt-3"
                                                v-on:click="goToUpgrade">
                                                <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i> Ayp beli paket PPPK
                                            </button>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!fetchSubsriber && dataSubscribed.subscribed == 1">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card border">
                                <div class="card-body">
                                    <h4 class="card-title"><i class="mdi mdi-clipboard-search-outline"></i> Filter
                                        pencarian
                                    </h4>
                                    <p class="card-title-desc mb-0">Silahkan gunakan filter dibawah ini untuk melakukan
                                        pencarian data.</p>

                                    <div class="row">
                                        <div class="col-md-12 mt-3">
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Kata kunci</label>
                                                <div class="input-group">
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Cari berdasarkan nama..." v-model="keywords"
                                                        v-on:keyup.enter="getPackage">
                                                    <div class="input-group-append">
                                                        <button v-on:click="getPackage"
                                                            class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send"
                                                            type="button"><i class="fa fa-search"></i> Cari </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="dataFetch" class="row">
                        <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                            <div class="card d-block border rounded-lg p-3 m-t-10">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="mr-3 w-100">
                                        <div class="d-flex align-items-center">
                                            <div class="w-100">
                                                <div class="skeleton-bar text-center">
                                                    <div class="sb-sm mb-2"></div>
                                                    <hr />
                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-thin mb-4"></div>
                                                    <div class="sb-lg"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!dataFetch">
                        <div class="row" v-if="dataList.length > 0">
                            <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                                <div class="card d-block border rounded-lg">
                                    <img v-if="!item.cover" :src="'images/default-pppk.png'" class="card-img-top"
                                        alt="...">
                                    <img v-if="item.cover" :src="item.cover" class="card-img-top" alt="...">
                                    <div class="card-body">
                                        <h5 class="mb-sm-0"><b>{{item.name}}</b></h5>
                                        <h6 class="mb-sm-0 mt-2">{{item.total_tryout}} Paket</h6>
                                        <hr />
                                        <div class="d-grid mt-2">
                                            <button v-on:click="goToPackage(item.code)" type="button"
                                                class="btn btn-primary btn-block"><i
                                                    class="mdi mdi mdi-book-check-outline"></i> Mulai Belajar</button>
                                        </div>

                                        <div class="alert alert-primary text-center mt-2 mb-0" role="alert"
                                            v-if="item.subscribed">
                                            Berlaku hingga<br />
                                            <strong>{{item.expired_at}}</strong>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="emptyState">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card d-block border rounded-lg">
                                        <div class="card-body">
                                            <empty-state></empty-state>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EmptyState from '../../../static/EmptyState.vue';
    export default {
        components: {
            EmptyState
        },
        name: 'MemberPppkMyPackage',
        metaInfo: {
            title: 'Paket PPPK Saya',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                keywords: '',
                fetchSubsriber: false,
                dataFetch: true,
                emptyState: true,
                dataList: [],
                dataSubscribed: []
            }
        },
        created() {
            this.checkSubscribed();
        },
        methods: {
            checkSubscribed: function () {
                this.fetchSubsriber = true;
                let uri = process.env.VUE_APP_APIHOST + 'member/package/check';
                this.$http.get(uri, {
                    params: {
                        type: 'pppk'
                    }
                }).then(res => {
                    this.fetchSubsriber = false;
                    this.dataSubscribed = res.data;

                    if(this.dataSubscribed) {
                        this.getPackage();
                    }
                });
            },
            getPackage: function () {
                this.dataFetch = true;
                let uri = process.env.VUE_APP_APIHOST + 'member/my/package/list';
                this.$http.get(uri, {
                    params: {
                        type: 'pppk',
                        keywords: this.keywords
                    }
                }).then(res => {
                    this.emptyState = false;
                    this.dataFetch = false;
                    this.dataList = res.data.data;

                    if (this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goToUpgrade: function () {
                this.$router.push({
                    name: 'member_pppk_package'
                });
            },
            goToPackage: function(code="") {
                this.$router.push({ name: 'member_pppk_tryout' , params: {
                    code : code
                }});
            }

        }
    }
</script>