<template>
    <div class="theory-content">
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Informasi - {{dataDetail.name}}</h4>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:-10px !important">
                    <div class="col-md-8 col-sm-12">
                        <div v-if="dataDetail.description" class="alert alert-info" v-html="dataDetail.description"></div>

                        <div class="card border">                        
                            <div class="card-body mb-0">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Tanggal Pendaftaran <b>Dibuka</b></div>
                                        <div class="font-weight-600">
                                            {{dataDetail.text_start_date}}
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Tanggal Pendaftaran <b>Ditutup</b></div>
                                        <div class="font-weight-600">
                                            {{dataDetail.text_end_date}}
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Tanggal <b>Pengumuman Hasil Lomba</b></div>
                                        <div class="font-weight-600">
                                            {{dataDetail.text_result_date}}
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Durasi Lomba</div>
                                        <div class="font-weight-600">
                                            &#177; {{dataDetail.duration_days}} Hari
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Status Pendaftaran</div>
                                        <div class="font-weight-600">
                                            <span class="badge bg-dark" v-if="dataDetail.registration_status == 'lock'">Belum dibuka</span>
                                            <span class="badge bg-info" v-if="dataDetail.registration_status == 'open'">Dibuka</span>
                                            <span class="badge bg-danger" v-if="dataDetail.registration_status == 'expire'">Ditutup</span>
                                        </div>
                                    </div>

                                    <div v-if="dataDetail.attachment" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">File Juknis / Silabus</div>
                                        <div class="font-weight-600">
                                            <a :href="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+dataDetail.attachment" target="blank">
                                                Lihat
                                            </a>
                                        </div>
                                    </div>

                                    
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-1">
                                        <div class="mr-3">Benefit yang akan di dapatkan</div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-12 mt-2" v-for="(item, index) in dataDetail.description_other" :key="index">
                                            <i class="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i> {{item}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12" v-if="!dataDetail.subscribed">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="mb-sm-0 text-center"><b>{{dataDetail.name}}</b></h4>
                                <hr/>

                                <div class="text-center">
                                    <h5><b>Biaya Pendaftaran</b></h5>
                                    <h3><b>{{dataDetail.text_price}}</b></h3>
                                </div>

                                <hr/>

                                <div class="d-grid mt-4" v-if="dataDetail.registration_status == 'open'">
                                    <button data-toggle="modal" data-target="#modalBuyPackage" v-on:click="buyPackage" type="button" class="btn btn-primary btn-block"><i class="mdi mdi-account-plus-outline"></i> Daftar Lomba</button>
                                </div>

                                <div class="d-grid" style="margin-top:-10px">
                                    <button v-on:click="goBack" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-3" ><i class="mdi mdi-arrow-left"></i> Kembali</button>
                                </div>

                                <div class="alert alert-warning text-center mt-3 mb-0" role="alert" v-if="!dataDetail.subscribed && dataDetail.registration_status == 'open'">
                                    Dapatkan <strong>extra potongan harga</strong> dengan kode promo / referral.
                                </div>

                                <div class="alert alert-danger text-center mt-3 mb-0" role="alert" v-if="dataDetail.registration_status == 'expire'">
                                    Pendaftaran lomba <strong>sudah ditutup</strong>.
                                </div>

                                <div class="alert alert-warning text-center mt-3 mb-0" role="alert" v-if="dataDetail.registration_status == 'lock'">
                                    Pendaftaran lomba <strong>belum dibuka</strong> untuk saat ini.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12" v-if="dataDetail.subscribed">
                        <div class="card border">
                            <div class="card-body">
                                <h4 class="mb-sm-0 text-center"><b>{{dataDetail.name}}</b></h4>
                                <hr/>
                                <div class="d-grid">
                                    <button type="button" disabled class="btn btn-outline-primary btn-block">
                                        <i class="mdi mdi-briefcase-check-outline"></i> Sudah terdaftar peserta</button>
                                </div>
                                <div class="alert alert-primary text-center mt-3 mb-0" role="alert">
                                    Kamu sudah terdaftar sebagai
                                    <strong>Peserta</strong> di lomba ini.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalBuyPackage" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="mdi mdi-account-plus-outline"></i> Beli</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                            <div>
                                <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Paket</div>
                                <div style="font-size:20px" class="d-block h6 font-weight-600 mb-2">{{form.name}}</div>
                            </div>
                        </div>
                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Harga</div>
                                <div class="font-weight-600">
                                    <s v-if="form.price_out" class="text-danger mr-2">{{form.text_price_out}}</s>
                                    <b>{{form.text_price}}</b>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Potongan Harga</div>
                                <div class="font-weight-600">
                                    <span v-if="!form.discount_voucher">Rp. 0</span>
                                    <span style="color:#43ab43" v-if="form.discount_voucher">{{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kode Voucher / Referral<br/>
                                    <small>Gunakan Kode untuk mendapatkan potongan harga</small>
                                </div>
                                <div class="font-weight-600">
                                    <a v-if="!form.fix_voucher" v-on:click="showVoucher" href="javascript:void(0)">Masukan Kode</a>
                                    <span class="badge badge-info" v-if="form.fix_voucher" href="javascript:void(0)">{{form.fix_voucher}} - {{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="input-group mb-3 m-t-10" v-if="showInputVoucher">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Kode Referral / Voucher ..." v-model="form.voucher" v-on:keyup.enter="checkVoucher">
                                <div class="input-group-append">
                                    <button v-on:click="checkVoucher" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">Gunakan Kode</button>
                                </div>
                            </div>

                            <h5>Total Harga :
                                <span class="float-right">
                                    <s class="mr-2" style="color:red" v-if="form.total_price_out">
                                        {{form.total_price_out}}</s>
                                    <b>{{form.total_price}}</b>
                                </span>
                            </h5>
                            <div class="d-grid mt-3">                            
                                <button :disabled="processFetch" v-on:click="checkout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="mdi mdi-account-plus-outline"></i>
                                    {{ processFetch ? 'Sedang membuat invoice ...' : 'Daftar' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberLktiPackageDetail',
        metaInfo: {
            title: 'Daftar LKTI',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                showTryout      : false,                
                dataFetch       : true,
                dataDetail      : {
                    list_tryout : []
                },
                code            : this.$route.params.code,
                showInputVoucher    : false,
                processFetch        : false,
                form                : {
                    id : '',
                    name : '',
                    text_price_out : '',
                    text_price : '',
                    active_days : '',
                    voucher : '',
                    price : 0,
                    fix_voucher : '',
                    discount_voucher : 0,
                    total_price : 0,
                    total_price_out : 0,
                    type : ''
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri = process.env.VUE_APP_APIHOST + 'member/package/detail';
                this.$http.get(uri, {
                    params: {
                        type    : 'lkti',
                        code    : this.code
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;                
                        this.dataDetail = res.data.data;

                        if(this.dataDetail.list_tryout.length > 0) {
                            this.showTryout = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_lkti_package'});
            },
            showVoucher: function() {
                this.showInputVoucher = true;
            },
            buyPackage: function() {
                this.form.id                = this.dataDetail.id;
                this.form.name              = this.dataDetail.name;
                this.form.text_price_out    = this.dataDetail.text_price_out;
                this.form.text_price        = this.dataDetail.text_price;
                this.form.price             = this.dataDetail.price;
                this.form.total_price       = this.dataDetail.text_price;
                this.form.active_days       = this.dataDetail.active_days;
                this.form.fix_voucher       = '';
                this.form.discount_voucher  = 0;
                this.form.total_price_out   = 0;
                this.form.type              = this.dataDetail.type;
            },
            checkVoucher: function() {
                let formData    = new FormData();
                formData.append('category', this.form.type);
                formData.append('voucher', this.form.voucher);
                formData.append('id', this.form.id);
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/check-voucher', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    let getResponse             = response.data.data;
                                    let total_price             = getResponse.text_total_price;
                                    let total_price_out         = getResponse.text_before_price;

                                    this.form.fix_voucher       = this.form.voucher;
                                    this.form.discount_voucher  = getResponse.text_discount;
                                    this.form.total_price       = total_price;
                                    this.form.total_price_out   = total_price_out;
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    return;
                });
            },
            rupiahFormat: function(bilangan) {                        
                var	reverse = bilangan.toString().split('').reverse().join(''),
                ribuan 	= reverse.match(/\d{1,3}/g);
                ribuan	= ribuan.join('.').split('').reverse().join('');
                return ribuan;
            },
            checkout: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Pembelian',
                    text: 'Apakah kamu yakin akan melanjutkan proses pembelian ?',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if(result.value) {
                        this.processFetch = true;
                        let formData    = new FormData();
        
                        formData.append('category', this.form.type);
                        formData.append('voucher', this.form.voucher);
                        formData.append('id', this.form.id);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/checkout', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            document.getElementById("btnCloseModal").click();
                                            this.$router.push({
                                                name: 'member_invoice_detail',
                                                params: {
                                                    invoice : response.data.data.invoice
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                    
                                    let total_price             = response.data.data.text_total_price;
                                    this.form.total_price       = total_price;
                                    this.form.total_price_out   = 0;
                                    this.form.discount_voucher  = 0;
                                }

                                this.processFetch = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.processFetch = false;
                            return;
                        });
                    }
                });
            }
        }
    }
</script>