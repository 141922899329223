<template>
    <div class="tryout-content">
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Persiapan Mengerjakan Soal Tryout</h4>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:-10px !important">
                    <div class="col-12">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            <strong>Perhatian !</strong>
                            <ol class="my-0 mt-2">
                                <li> Selalu Pastikan <strong> koneksi internet kamu stabil</strong> ketika sedang mengerjakan soal.</li>
                                <li> Pastikan <strong>tidak ada aktivitas login ke akun kamu (pada perangkat lain)</strong> saat sedang mengerjakan soal. </li>
                                <li> Gunakan <strong>browser versi terbaru</strong> agar website dapat diakses dengan lancar tanpa masalah. </li>
                            </ol>
                        </div>

                        <div class="card border">
                            <div class="card-body">
                                <h4 class="card-title">Informasi Tryout</h4>
                                <p class="card-title-desc">Berikut ini adalah informasi tryout yang akan kamu kerjakan : </p>
                                <table class="table table-centered mb-0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clipboard-file-outline"></i> Nama Tryout</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.title}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clipboard-list-outline"></i> Jumlah Soal</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.total_questions}} Soal</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-clock-check-outline"></i> Durasi Waktu</td>
                                            <td class="col-sm-8 col-md-10">
                                                <b>{{dataDetail.timer}} Menit</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="vertical-align:top !important" class="col-sm-4 col-md-2"><i class="mdi mdi-book-edit-outline"></i> Kategori Soal</td>
                                            <td class="col-sm-8 col-md-10">
                                                <!--ul>
                                                    <li v-for="(itemDetail, indexDetail) in dataDetail.detail" :key="indexDetail" :class="indexDetail > 0 ? 'mt-2':''">
                                                        <b>{{itemDetail.category}}</b>
                                                        <br/>
                                                        <i class="mdi mdi-arrow-right"></i> Jumlah Soal : <b>{{itemDetail.total_questions}} Soal</b>
                                                        <br/>
                                                        <i class="mdi mdi-arrow-right"></i> Waktu mengerjakan : <b>{{itemDetail.timer}} Menit</b>
                                                        <br/>
                                                        <i class="mdi mdi-arrow-right"></i> Passing Grade : <b>{{itemDetail.passing_grade}}</b>
                                                    </li>
                                                </ul-->

                                                <div class="table-responsive">
                                                    <table class="table table-bordered" style="width:100%">
                                                        <thead>
                                                            <th class="text-center">Kategori</th>
                                                            <th class="text-center">Jumlah Soal</th>
                                                            <th class="text-center">Durasi</th>
                                                            <th class="text-center">Passing Grade</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(itemDetail, indexDetail) in dataDetail.detail" :key="indexDetail">
                                                                <td>{{itemDetail.category}}</td>
                                                                <td class="text-center">{{itemDetail.total_questions}} Soal</td>
                                                                <td class="text-center">{{itemDetail.timer}} Menit</td>
                                                                <td class="text-center">{{itemDetail.passing_grade}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr/>
                                <div class="button-items mt-3">
                                    <button v-on:click="confirmStartTryout" type="button" class="btn btn-primary waves-effect waves-light"><i class="mdi mdi-book-edit-outline"></i> Mulai Mengerjakan</button>
                                    <button v-on:click="goBack" type="button" class="btn btn-outline-light waves-effect">Kembali</button>
                                </div>
                            </div>
                        </div>

                        <div class="card border" v-if="dataList.length > 0">
                            <div class="card-body">
                                <h4 class="card-title">Hasil Pengerjaan Tryout</h4>
                                <p class="card-title-desc">Berikut ini adalah hasil tryout yang telah kamu kerjakan sebelumnya :</p>

                                <div class="table-responsive mt-3">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">No</th>
                                                <th style="width:20%">Keterangan</th>
                                                <th style="width:22%">Tanggal Mengerjakan</th>
                                                <th class="text-center" style="width:12%">Total Skor</th>                                                    
                                                <th class="text-center" style="width:15%">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td class="text-center">
                                                    {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                </td>
                                                <td>
                                                    Tryout ke {{ totalData-((index) + (pagination.currentPage - 1) * pagination.limit) }}
                                                </td>
                                                <td class="text-short">{{item.working_date}}</td>
                                                <td class="text-center">{{item.total_scored}}</td>
                                                <td class="text-center">
                                                    <div class="button-items">
                                                        <button v-on:click="goToResult(item.id)" type="button" class="btn btn-primary btn-sm rounded">
                                                            <i class="mdi mdi-format-list-text"></i>
                                                            Lihat Detail
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <nav v-if="dataList.length >= pagination.limit" aria-label="navigation" class="mt-3">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getResult()"></v-pagination>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MemberPppkTryoutDetail',
        metaInfo: {
            title: 'Persiapan Mengerjakan Tryout',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataFetch       : true,
                dataDetail      : [],
                code            : this.$route.params.code,
                tryout_code     : this.$route.params.tryout_code,
                token           : (Math.random() + 1).toString(36).substring(2),
                dataFetchList   : false,
                dataList        : [],
                keywords        : "",
                totalData       : 0,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/pppk/tryout/overview';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code        : this.code,
                        tryout_code : this.tryout_code
                    }
                }).then(res => {
                    if(res.data.premium) {
                        if(res.data.status) {
                            this.dataDetail = res.data.data;
                            if(this.dataDetail.token) {
                                this.token = this.dataDetail.token;
                                this.startTryout();                                
                            } else {
                                this.dataFetch  = false;
                            }
                            this.getResult();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goBack();
                                }
                            });
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Menu ini hanya bisa diakses oleh pengguna premium',
                            text: 'Ayo upgrade akun kamu untuk mendapatkan benefit yang lebih baik',
                            confirmButtonText: 'Upgrade Akun'
                        }).then((result) => {
                            if (result.value) {
                                this.goUpgrade();
                            }
                        });
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name: 'member_pppk_tryout'});
            },
            goUpgrade: function() {
                this.$router.push({ name: 'member_pppk_package'});
            },
            confirmStartTryout: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    html: 'Apakah kamu yakin akan mulai mengerjakan soal?',
                    confirmButtonText: 'Mulai',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.startTryout();
                    }
                });
            },
            startTryout: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/pppk/tryout/start', {
                    code        : this.code,
                    tryout_code : this.tryout_code,
                    token       : this.token
                })
                .then(response => {
                    if (response.data.status) {
                        const result = response.data.data;                        
                        this.$router.push({
                            name: 'member_pppk_tryout_start',
                            params: {
                                code        : this.code,
                                tryout_code : this.tryout_code,
                                token       : this.token,
                                detail_id   : result.id
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            },
            getResult: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/pppk/tryout/result';

                this.dataFetchList  = true;                
                this.$http.get(uri,{
                    params : {
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit,
                        tryout_code : this.tryout_code
                    }
                }).then(res => {
                    this.dataFetchList          = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.totalData              = res.data.total_data
                });
            },
            goToResult: function(id_start="") {
                this.$router.push({
                    name: 'member_pppk_tryout_result_detail',
                    params: {
                        code        : this.code,
                        tryout_code : this.tryout_code,
                        id_start    : id_start
                    }
                });
            }
        }
    }
</script>