<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Konfirmasi Tryout Gratis Kedinasan</h4>
                                </div>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>
                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama Peserta</th>
                                                    <th>Nama Tryout</th>
                                                    <th class="text-center" style="width:5%">Persyaratan 1</th>
                                                    <th class="text-center" style="width:5%">Persyaratan 2</th>
                                                    <th class="text-center" style="width:5%">Persyaratan 3</th>
                                                    <th class="text-center" style="width:15%">Waktu Upload</th>
                                                    <th class="text-center" style="width:10%">Status</th>
                                                    <th class="text-center" style="width:10%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.tryout_name}}</td>
                                                    <td class="text-center">
                                                        <a v-if="item.attachment_one" :href="item.attachment_one" target="blank">Lihat</a>
                                                        <span v-if="!item.attachment_one">(Tidak Ada)</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <a v-if="item.attachment_two" :href="item.attachment_two" target="blank">Lihat</a>
                                                        <span v-if="!item.attachment_two">(Tidak Ada)</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <a v-if="item.attachment_three" :href="item.attachment_three" target="blank">Lihat</a>
                                                        <span v-if="!item.attachment_three">(Tidak Ada)</span>
                                                    </td>
                                                    <td class="text-center">{{item.text_uploaded}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'waiting'" class="badge bg-secondary">Menunggu Konfirmasi</span>
                                                        <span v-if="item.status == 'accepted'" class="badge bg-primary">Disetujui</span>
                                                        <span v-if="item.status == 'rejected'" class="badge bg-danger">Ditolak</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <div class="button-items" v-if="item.status == 'waiting' && !fetchConfirm">
                                                            <button v-on:click="confirmData(item.id, 'accepted', item.email, item.name, item.tryout_name)" type="button" class="btn btn-primary btn-sm rounded"><i class="mdi mdi-check-bold"></i></button>
                                                            <button v-on:click="confirmData(item.id, 'rejected', item.email, item.name, item.tryout_name)" type="button" class="btn btn-danger btn-sm rounded"><i class="mdi mdi-close-thick"></i></button>
                                                        </div>
                                                        <span v-if="item.status != 'waiting'">-</span>
                                                        <div v-if="fetchConfirm">Loading...</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'KedinasanTryoutConfirmFree',
        metaInfo: {
            title: 'Konfirmasi Tryout Gratis Kedinasan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                fetchConfirm : false
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/tryout/free/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type     : 'kedinasan',
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            confirmData: function (id="", status="", email="", fullname="", tryout_name="") {
                let title = "";
                let btnTxt = "";
                if(status == 'accepted') {
                    title = "Terima Persyaratan Peserta";
                    btnTxt  = "Terima";
                }
                
                if(status == 'rejected') {
                    title = "Tolak Persyaratan Peserta";
                    btnTxt  = "Tolak";
                }

                this.$swal({
                    icon: 'info',
                    title: title,
                    text: 'Klik tombol dibawah untuk melakukan konfirmasi',
                    confirmButtonText: btnTxt,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.fetchConfirm = true;
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/tryout/free/confirm', {
                        id: id,
                        status : status,
                        email : email,
                        fullname : fullname,
                        tryout_name : tryout_name
                    })
                    .then(response => {
                        this.fetchConfirm = false;
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>