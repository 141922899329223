<template>
    <div>
        <div class="card border">
            <div class="card-body">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Hasil Lomba Peserta</h4>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Cari Hasil Lomba Peserta berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>
                <div v-if="dataFetch">
                    <p>Sedang mengambil data...</p>
                </div>

                <div v-if="!dataFetch">
                    <div v-if="dataList.length > 0" class="table-responsive mt-3 mb-0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width:5%" class="text-center">No</th>
                                    <th>Nama Lengkap</th>
                                    <th style="width:10%">Orisinalitas</th>
                                    <th style="width:10%">Relevance</th>
                                    <th style="width:10%">Creativity</th>
                                    <th style="width:10%">Novelty</th>
                                    <th style="width:10%">Solution</th>
                                    <th style="width:10%">Total Skor</th>
                                    <th style="width:13%" class="text-center">Status Nilai</th>
                                    <th class="text-center" style="width:15%">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataList" :key="index">
                                    <td class="text-center">
                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                    </td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.original}}</td>
                                    <td>{{item.relevance}}</td>
                                    <td>{{item.creativity}}</td>
                                    <td>{{item.novelty}}</td>
                                    <td>{{item.solution}}</td>
                                    <td>{{item.total_scored}}</td>
                                    <td class="text-center">
                                        <span v-if="item.lock_scored == '0'" class="badge bg-secondary">
                                            Belum di publish
                                        </span>
                                        <span v-if="item.lock_scored == '1'" class="badge bg-primary">Publish</span>
                                    </td>
                                    <td class="text-center" v-if="item.status">
                                        <div class="button-items" v-if="item.users_papers_id">
                                            <router-link :to="{name : 'lkti_package_result_detail', params: {id:$route.params.id, users_papers_id:item.users_papers_id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-edit"></i> Ubah Nilai</router-link>
                                            <button v-on:click="changeStatus(item.id, item.lock_scored)" type="button" class="btn btn-sm rounded" :class="item.lock_scored == 0 ? 'btn-outline-primary':'btn-danger'"><i class="fas fa-check"></i>
                                                 {{item.lock_scored == 0 ? 'Publish Nilai':'unpublish'}}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3 mb-0">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>

                    <div v-if="emptyState">
                        <empty-state></empty-state>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'UsersMemberResult',
        metaInfo: {
            title: 'Hasil Lomba Peserta',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/package/users-papers-result';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id       : this.$route.params.id,
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            changeStatus: function(id, lock_scored) {
                let message = "Apakah kamu yakin akan membatalkan publish nilai ?";
                let value   = 0;
                if(lock_scored == '0') {
                    message = "Apakah kamu yakin akan publish nilai ?";
                    value   = 1;
                }

                this.$swal({
                    icon: 'info',
                    title: 'Ubah Status',
                    text: message,
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/package/users-papers-change-status', {
                        id: id,
                        value : value,
                        package_id : this.$route.params.id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>