<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">

                <div v-if="dataFetch" class="row card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm m-b-2"></div>
                                            <hr/>
                                            <div class="sb-sm sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch" class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}}</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item active">Pengaturan Akun</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Username <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan Username..." v-model="form.username" :class="{ 'is-invalid': submitted && $v.form.username.$error }">
                                            <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">Username Wajib diisi!</div>
                                            <small><i>Username tidak boleh sama dengan pengguna lain.</i></small>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Nama Lengkap <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }">
                                            <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Nama Lengkap Wajib diisi!</div>    
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Nomor Handphone <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
                                            <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Nomor Handphone Wajib diisi!</div>    
                                            <div v-if="submitted && !$v.form.phone.numeric" class="invalid-feedback"> Nomor Handphone harus berupa angka</div>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Jenis Kelamin <span class="text-danger">*</span></label>
                                            <select class="form-select" v-model="form.gender" :class="{ 'is-invalid': submitted && $v.form.gender.$error }">
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="male">Pria</option>
                                                <option value="female">Wanita</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.gender.required" class="invalid-feedback">Jenis Kelamin Wajib diisi!</div>    
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Tempat Lahir</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.place_birth">
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Tanggal Lahir</label>
                                            <datepicker v-model="form.date_birth" :input-class="{'form-control':true}" :language="id" placeholder="Pilih Tanggal"></datepicker>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Provinsi</label>
                                            <v-select
                                                placeholder="Pilih Provinsi"
                                                label="name"
                                                :options="listProvince"
                                                v-model="form.province"
                                                @input="getCity"
                                                >
                                            </v-select>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Kota</label>
                                            <v-select
                                                placeholder="Pilih Kota"
                                                label="name"
                                                :options="listCity"
                                                v-model="form.city"
                                            >
                                            </v-select>
                                        </div>
                                        <div class="form-group col-md-12 mt-3">
                                            <label >Alamat Rumah</label>
                                            <textarea rows="4" class="form-control" v-model="form.address" placeholder="Masukan data..."></textarea>
                                        </div>
                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label>Agama</label>
                                            <select class="form-select" v-model="form.religion">
                                                <option value="">Pilih Agama</option>
                                                <option value="islam">Islam</option>
                                                <option value="buddha">Buddha</option>
                                                <option value="hindu">Hindu</option>
                                                <option value="katolik">Katolik</option>
                                                <option value="konghucu">Konghucu</option>
                                                <option value="protestan">Protestan</option>
                                                <option value="lainnya">Lainnya</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label >Email</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.email">
                                        </div>
                                        <div class="form-group col-md-6 mt-3 d-none">
                                            <label>Foto</label>
                                            <input type="file" class="form-control" name="photo" id="photo" @change="previewFiles" accept="image/png, image/gif, image/jpeg">
                                        </div>
                                        <div class="form-group col-md-6 mt-3 d-none" v-if="preview_image">
                                            <label>Preview Photo</label>
                                            <div class="thumbnail mb-4">
                                                <div class="thumb">
                                                    <a :href="preview_image" data-lightbox="1" target="blank">
                                                        <img :src="preview_image" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";
    import Datepicker from 'vuejs-datepicker';
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    
    export default {
        name: 'account_profile',
        metaInfo: {
            title: 'Profile',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        components : {
            Datepicker,
            'v-select' : vSelect,
        },
        created() {
            this.getProvince();
            this.getData();
        },
        data() {
            return {
                id: id,
                label           : 'Profile',
                form : {
                    id              : "",
                    username        : "",
                    phone           : "",
                    fullname          : "",
                    place_birth : "",
                    date_birth  : moment().format('YYYY-MM-DD'),
                    gender      : "",
                    religion    : "",
                    email       : "",
                    address     : "",
                    photo  : "",
                    previousphoto  : "",
                    province : "",
                    city : ""
                },
                listProvince    : [],
                listCity        : [],
                preview_image   : '',
                dataFetch       : true,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                username: {
                    required
                },
                fullname: {
                    required
                },
                gender: {
                    required
                },
                phone: {
                    required,
                    numeric
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('username', this.form.username);
                    formData.append('fullname', this.form.fullname);
                    formData.append('phone', this.form.phone);
                    formData.append('place_birth', this.form.place_birth);
                    formData.append('date_birth', this.convertDate(this.form.date_birth));
                    formData.append('gender', this.form.gender);
                    formData.append('religion', this.form.religion);
                    formData.append('email', this.form.username);
                    formData.append('address', this.form.address);
                    formData.append('photo', this.form.photo);
                    formData.append('province', JSON.stringify(this.form.province));
                    formData.append('city', JSON.stringify(this.form.city));

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                localStorage.setItem('id', response.data.data.id);                                
                                localStorage.setItem('gsprima_token', response.data.data.token);                                
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        location.reload();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'user/profile';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;

                        this.form.id                = getResponse.id;
                        this.form.username          = getResponse.username;
                        this.form.identity_type     = getResponse.identity_type;
                        this.form.phone             = getResponse.phone;
                        this.form.fullname          = getResponse.fullname;
                        this.form.place_birth       = getResponse.place_birth;
                        this.form.province          = getResponse.province;
                        this.form.city              = getResponse.city;
    
                        if(getResponse.gender != null) {
                           this.form.gender     = getResponse.gender;
                        }

                        if(getResponse.religion != null) {
                           this.form.religion   = getResponse.religion;
                        }

                        if(getResponse.date_birth != null) {
                            this.form.date_birth    = getResponse.date_birth;
                        }

                        this.form.email             = getResponse.email;
                        this.form.address           = getResponse.address;
                        this.form.photo             = getResponse.photo;
                        this.preview_image          = getResponse.photo;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                            }
                        });
                    }
                    this.dataFetch  = false;
                    this.getProvince();
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.photo = file;
                }
            },
            getProvince: function () {
                let uri = 'https://passchendaele.oeltimacreation.com/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.province) {
                    if(this.form.province.id) {
                        let uri = 'https://passchendaele.oeltimacreation.com/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.province.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            }
        }
    }
</script>