<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Statistik & Grafik hasil latihan</h4>
                </div>
            </div>
        </div>

        <div v-if="!this.$parent.$parent.premium" class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card border" style="border: 1px solid #f1f5f7; !important">
                    <div class="card-body p-4">
                        <div class="">
                            <div class="text-center mt-1">
                                <img class="image-responsive" src="images/upgrade.svg" style="width:40%"/>
                            </div>
                            <h4 class="font-size-18  mt-3 text-center">
                                Statistik hasil latihan hanya bisa di akses <br/>
                                <b>oleh pengguna premium</b>
                                <br />
                                <button type="button" class="btn btn-outline-primary mt-3" v-on:click="upgrade('cpns')">
                                    <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i> Ayo upgrade akun kamu
                                </button>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.$parent.$parent.premium" class="row mb-4" style="margin-top:-10px">            
            <!-- Statistik bukan TKP -->
            <div v-if="this.$parent.$parent.dataDetail.category_id != '3'" class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Statistik Nilai
                        </h4>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                            <div class="font-weight-600">
                                {{this.statistic_non_tkp.correct}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                            <div class="font-weight-600">
                                {{this.statistic_non_tkp.wrong}}
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mt-3">
                            <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                            <div class="font-weight-600">
                                {{this.statistic_non_tkp.empty}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="this.$parent.$parent.dataDetail.category_id != '3'" class="col-md-6 col-sm-12">
                <div class="card border">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Grafik Nilai
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptionsNonTKP" :series="series_non_tkp"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Statistik TKP -->
            <div v-if="this.$parent.$parent.dataDetail.category_id == '3'" class="col-md-6 col-sm-12">
                <div class="card border h-100">
                    <div class="card-body">
                        <h4 class="card-title text-center mb-4">
                            Statistik Nilai
                        </h4>

                        <div v-for="(item, index) in statistic_tkp.list_data" :key="index" class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom">
                            <div class="mr-3 font-weight-bold">{{item.name}}</div>
                            <div class="font-weight-600">
                                {{item.value}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div v-if="this.$parent.$parent.dataDetail.category_id == '3'" class="col-md-6 col-sm-12">
                <div class="card border h-100">
                    <div class="card-body">
                        <h4 class="card-title text-center">
                            Grafik Nilai
                        </h4>

                        <div>
                            <apexchart type="pie" :options="chartOptionsTKP" :series="series_tkp"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'MemberCpnsExercisesStatistic',
        metaInfo: {
            title: 'Statistik Dan Hasil Latihan CPNS',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                chartOptionsNonTKP: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#22a843', '#f3113a', '#74788d'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                series_non_tkp      : [],
                series_tkp          : [],
                statistic_non_tkp   : {
                    correct     : "-",
                    wrong       : "-",
                    empty       : "-",
                    total_data  : "-"
                },
                statistic_tkp       : {
                    labels : [],
                    list_data : [],
                    series : [],
                    total_questions : []
                },
                chartOptionsTKP     : []
            }
        },
        created() {
            this.getStatistic();
        },
        methods: {
            getStatistic: function() {
                if(this.$parent.$parent.premium == '1') {
                    if(this.$parent.$parent.dataDetail.category_id != '3') {
                        this.statistic_non_tkp  = this.$parent.$parent.dataDetail.statistic_answer;
                        this.series_non_tkp     = [this.statistic_non_tkp.correct, this.statistic_non_tkp.wrong, this.statistic_non_tkp.empty]
                    } else {
                        this.statistic_tkp  = this.$parent.$parent.dataDetail.statistic_answer;
                        this.series_tkp      = this.statistic_tkp.series;

                        this.chartOptionsTKP =  {
                                chart: {
                                    type: 'pie',
                                },
                                colors: ['#22a843', '#0b78e9', '#efa50a', '#A27B5C', '#DCD7C9', '#74788d'],
                                labels: this.statistic_tkp.labels,
                                responsive: [{
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: 200
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }]
                        }
                    }
                }
            },
            upgrade: function(type="") {
                if(type == 'cpns') {
                    this.$router.push({ name: 'member_cpns_package'});
                }
            }
        }
    }
</script>