var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exercises-content"},[(_vm.dataFetch)?_c('div',{staticClass:"page-content"},[_vm._m(0)]):_vm._e(),(!_vm.dataFetch)?_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_vm._m(1),_c('div',{staticClass:"row",staticStyle:{"margin-top":"-10px !important"}},[_c('div',{staticClass:"col-12"},[_vm._m(2),_c('div',{staticClass:"card border"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Informasi Latihan")]),_c('p',{staticClass:"card-title-desc"},[_vm._v("Berikut ini adalah informasi latihan yang akan kamu kerjakan : ")]),_c('table',{staticClass:"table table-centered mb-0"},[_c('tbody',[_c('tr',[_vm._m(3),_c('td',{staticClass:"col-sm-8 col-md-10"},[_c('b',[_vm._v(_vm._s(_vm.dataDetail.title))])])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"col-sm-8 col-md-10"},[_c('b',[_vm._v(_vm._s(_vm.dataDetail.total_questions)+" Soal")])])]),_c('tr',[_vm._m(5),_c('td',{staticClass:"col-sm-8 col-md-10"},[_c('b',[_vm._v(_vm._s(_vm.dataDetail.timer)+" Menit")])])])])]),_c('hr'),_c('div',{staticClass:"button-items mt-2"},[_c('button',{staticClass:"btn btn-primary waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.confirmStartExercises}},[_c('i',{staticClass:"mdi mdi-book-edit-outline"}),_vm._v(" Mulai Mengerjakan")]),_c('button',{staticClass:"btn btn-outline-light waves-effect",attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v("Kembali")])])])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card border"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"mr-3 w-100"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"skeleton-bar"},[_c('div',{staticClass:"sb-md sb-thin mb-2"}),_c('div',{staticClass:"sb-md sb-thin mb-2"}),_c('div',{staticClass:"sb-md sb-thin mb-4"}),_c('div',{staticClass:"sb-md sb-thin mb-2"}),_c('div',{staticClass:"sb-lg sb-thin mb-2"}),_c('div',{staticClass:"sb-lg sb-thin mb-4"}),_c('div',{staticClass:"sb-md sb-thin mb-2"}),_c('div',{staticClass:"sb-lg sb-thin mb-2"}),_c('div',{staticClass:"sb-lg sb-thin mb-4"})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-sm-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-sm-0"},[_vm._v("Persiapan Mengerjakan Soal Latihan")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning alert-dismissible fade show",attrs:{"role":"alert"}},[_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"alert","aria-label":"Close"}}),_c('strong',[_vm._v("Perhatian !")]),_c('ol',{staticClass:"my-0 mt-2"},[_c('li',[_vm._v(" Selalu Pastikan "),_c('strong',[_vm._v(" koneksi internet kamu stabil")]),_vm._v(" ketika sedang mengerjakan soal.")]),_c('li',[_vm._v(" Pastikan "),_c('strong',[_vm._v("tidak ada aktivitas login ke akun kamu (pada perangkat lain)")]),_vm._v(" saat sedang mengerjakan soal. ")]),_c('li',[_vm._v(" Gunakan "),_c('strong',[_vm._v("browser versi terbaru")]),_vm._v(" agar website dapat diakses dengan lancar tanpa masalah. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-sm-4 col-md-2",staticStyle:{"vertical-align":"top !important"}},[_c('i',{staticClass:"mdi mdi-clipboard-file-outline"}),_vm._v(" Nama Latihan")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-sm-4 col-md-2",staticStyle:{"vertical-align":"top !important"}},[_c('i',{staticClass:"mdi mdi-clipboard-list-outline"}),_vm._v(" Jumlah Soal")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-sm-4 col-md-2",staticStyle:{"vertical-align":"top !important"}},[_c('i',{staticClass:"mdi mdi-clock-check-outline"}),_vm._v(" Durasi Waktu")])
}]

export { render, staticRenderFns }