<template>
    <div>
        <div v-if="dataFetch" class="page-content">
            <div class="container-fluid">
                <div class="card border">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-content">
            <div class="container-fluid">
                <div v-if="!emptyState" class="row">
                    <div class="col-md-8 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">No Invoice : {{dataDetail.invoice}}</h4>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="table-light">
                                                <th>Nama Item</th>
                                                <th class="text-right" style="width: 15%;">Harga</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dataDetail.list_item" :key="index">
                                                <td>
                                                    <h6><a href="javascript:void(0)" v-on:click="goToPage(item.type, item.code)">{{item.title}}</a></h6>
                                                    <p class="m-0">Program : {{item.type}}</p>
                                                </td>
                                                <td class="text-right">
                                                    <b>{{item.text_price}}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Informasi Tagihan</h4>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                    <div class="mr-3 font-weight-600">Sub total</div>
                                    <div class="font-weight-bold text-right">
                                        {{dataDetail.text_total_price}}
                                    </div>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mt-3">
                                    <div class="mr-3 font-weight-600">
                                        Potongan Harga
                                    </div>
                                    <div class="font-weight-bold text-right" :class="dataDetail.discount ? 'text-primary':''">
                                        {{dataDetail.text_discount}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mt-3">
                                    <div class="mr-3 font-weight-600">Total keseluruhan</div>
                                    <div class="font-weight-bold text-right">
                                        <s v-if="dataDetail.discount" class="text-danger">{{dataDetail.text_total_price}}<br/></s>
                                        {{dataDetail.text_total_overall_price}}
                                    </div>                                    
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom" v-if="dataDetail.status == 'pending'">
                                    <div class="mr-3 font-weight-600">Batas Pembayaran</div>
                                    <div class="font-weight-bold text-right">
                                        {{dataDetail.text_expired_at}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mt-3 border-bottom" v-if="dataDetail.status == 'pending'">
                                    <div class="mr-3 font-weight-600">Sisa Waktu</div>
                                    <div class="font-weight-bold text-right">
                                    <vue-countdown-timer
                                        @start_callback="startCallBack"
                                        @end_callback="endCallBack"
                                        :start-time="start_time"
                                        :end-time="end_time"
                                        :interval="1000">

                                        <div class="text-center" slot="countdown" slot-scope="scope">
                                            <span>{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>  
                                        </div>
                                    </vue-countdown-timer>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mt-3">
                                    <div class="mr-3 font-weight-600">Status</div>
                                    <div class="font-weight-bold text-right">
                                    <span v-if="dataDetail.status == 'cancel'" class="badge bg-danger">Dibatalkan</span>
                                    <span v-if="dataDetail.status == 'expire'" class="badge bg-danger">Kadaluarsa</span>
                                    <span v-if="dataDetail.status == 'settlement' || dataDetail.status == 'capture'" class="badge bg-success">Lunas</span>
                                    <span v-if="dataDetail.status == 'pending'" class="badge bg-light">Menunggu konfirmasi</span>
                                    </div>                                    
                                </div>

                                <div v-if="dataDetail.status == 'pending'" class="d-grid mt-3" >
                                    <button v-on:click="openSnap(dataDetail.token)" type="button" class="text-left btn btn-outline-primary rounded btn-block mt-1" ><i class="mdi mdi-credit-card-check-outline"></i> 
                                        Lanjutkan Pembayaran
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="emptyState" class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Detail Invoice</h4>
                                </div>

                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberInvoiceDetail',
        metaInfo: {
            title: 'Detail Invoice',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : true,
                dataDetail  : [],
                start_time  : '9999-12-12 00:00:00',
                end_time    : '9999-12-12 12:00:00'
            }
        },
        created() {
            this.getData();
        },
        mounted() {
            let externalScript = document.createElement('script')
            if( process.env.VUE_APP_APIHOST == 'https://api.gsprima.com/') {
                externalScript.setAttribute('src', 'https://app.midtrans.com/snap/snap.js')
                externalScript.setAttribute('data-client-key', 'Mid-client-FqKAgX6sIlYZkUXO')
            } else {
                externalScript.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js')
                externalScript.setAttribute('data-client-key', 'SB-Mid-client-_a4Zwg3J40OZH-A9')
            }
            externalScript.setAttribute('async', true)
            document.head.appendChild(externalScript)
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/invoice/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        invoice : this.$route.params.invoice,
                    }
                }).then(res => {
                    this.dataFetch  = false;
                    if(res.data.status) {
                        this.dataDetail = res.data.data;
                        this.start_time = res.data.data.current_time;
                        this.end_time   = res.data.data.end_time;
                    } else {
                        this.emptyState = true;
                    }
                });
            },
            startCallBack: function () {
                console.log("pembayaran dimulai");
            },
            endCallBack: function () {
                console.log("pembayaran berakhir");
            },
            openSnap: function(token) {
                window.snap.pay(token, {
                    onSuccess: function () {
                        location.reload();
                    },
                    // Optional
                    onPending: function () {
                        location.reload();
                    },
                    // Optional
                    onError: function () {
                        location.reload();
                    }
                });
            },
            goToPage: function(type="", code="") {
                if(this.dataDetail.status == 'settlement' || this.dataDetail.status == 'capture') {
                    if(type == 'cpns') {
                        this.$router.push({ name: 'member_cpns_tryout_overview' , params: {
                            code : code
                        }});
                    }

                    if(type == 'kedinasan') {
                        this.$router.push({ name: 'member_kedinasan_tryout_overview' , params: {
                            code : code
                        }});
                    }
                }
            }            
        }
    }
</script>