<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card border">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data Materi Kedinasan</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'kedinasan_theory'}">Data Materi Kedinasan</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Kategori</label>
                                            <select class="form-select" v-model="form.category_id" :class="{ 'is-invalid': submitted && $v.form.category_id.$error }">
                                                <option value="">Pilih Kategori</option>
                                                <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.category_id.required" class="invalid-feedback">Kategori Wajib diisi!</div>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Tipe Materi</label>
                                            <select class="form-select" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                                <option value="">Pilih Tipe Materi</option>
                                                <option value="text">Text</option>
                                                <option value="youtube">Video</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe Materi Wajib diisi!</div>
                                        </div>
                                        <div class="form-group col-md-12 mt-3">
                                            <label>Nama</label>
                                            <input type="text" class="form-control"
                                                placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                            <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Nama Wajib diisi!</div>    
                                        </div>
                                        <div class="form-group col-md-12 mt-3">
                                            <label>Deskripsi</label>
                                            <textarea
                                                rows="4"
                                                class="form-control"
                                                placeholder="Masukan data..." v-model="form.description" :class="{ 'is-invalid': submitted && $v.form.description.$error }">
                                            </textarea>
                                            <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">Deskripsi Wajib diisi!</div>    
                                        </div>

                                        <div class="form-group col-md-12 mt-3" v-if="form.type == 'text'">
                                            <label for="exampleInputEmail1">Isi Materi</label>
                                            <ckeditor v-model="form.content" :config="editorConfig"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3" v-if="form.type == 'youtube'">
                                            <label for="exampleInputEmail1">Url Youtube</label>
                                            <input type="text" class="form-control" placeholder="Masukan data..." v-model="form.content">
                                            <small><i>Contoh : https://www.youtube.com/embed/WysHtT_fh1k</i></small>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" id="exampleFormControlSelect1" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>


                                        <div class="form-group col-md-6 mt-3">
                                            <label>Attachment</label>
                                            <input type="file" class="form-control" name="attachment" id="attachment" @change="previewFiles" accept="application/pdf">
                                            <small v-if="form.previousAttachment" >File sebelumnya : <a :href="form.previousAttachment" target="blank">Lihat</a> </small>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                                        <router-link :to="{name : 'kedinasan_theory'}" class="btn btn-outline-primary">Kembali</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'kedinasan_theory',
        metaInfo: {
            title: 'Form Materi Kedinasan',
            titleTemplate: '%s - Gubuk Sains Prima'
        },
        created() {
            this.getCategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                form : {
                    id          : "",
                    category_id : "",
                    title       : "",
                    description : "",
                    content     : "",
                    type        : "",
                    status      : "draft",
                    attachment  : "",
                    previousAttachment  : "",
                },
                submitted       : false,
                waiting         : false,
                dataCategory    : [],
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                }
            }
        },
        validations: {
            form: {
                category_id: {
                    required
                },
                type: {
                    required
                },
                title: {
                    required
                },
                description: {
                    required
                },
            }
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'general/list/category';

                this.$http.get(uri).then(res => {
                    this.dataCategory = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('category_id', this.form.category_id);
                    formData.append('title', this.form.title);
                    formData.append('description', this.form.description);
                    formData.append('content', this.form.content);
                    formData.append('type', this.form.type);
                    formData.append('status', this.form.status);
                    formData.append('attachment', this.form.attachment);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/kedinasan/theory/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'kedinasan_theory' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/kedinasan/theory/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id                    = getResponse.id;
                            this.form.category_id           = getResponse.category_id;
                            this.form.type                  = getResponse.type;
                            this.form.title                 = getResponse.title;
                            this.form.description           = getResponse.description;
                            this.form.content               = getResponse.content;
                            this.form.status                = getResponse.status;
                            this.form.attachment            = getResponse.attachment;
                            this.form.previousAttachment    = getResponse.attachment;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'kedinasan_theory' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            }
        }
    }
</script>